import { Col, Row } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { Progress, Button, notification } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { getCityName } from '../../../shared/location/locationHelper';
import moment from 'moment';
import { projectServiceModule } from '../../../services/modules/projectServiceModule';
import styles from './styles.module.scss';
import { CustomModal } from '../../custom';
import store from 'store2';
import NumberFormat from 'react-number-format';

class ProjectContainer extends React.Component {
  constructor(props) {
    super(props);
    this.updateProject = this.updateProject.bind(this);
    this.changeRequest = this.changeRequest.bind(this);
    this.showTables = this.showTables.bind(this);

    this.state = {
      projectId: null,
      cityName: ''
    };
  }

  componentDidMount() {
    let cityName = getCityName(this.props.data.location);

    this.setState({ cityName: cityName });
  }

  showModal = (e) => {
    const projectId = e.target.attributes.projectid.value;
    this.setState({
      visible: true,
      projectId
    });
  };

  showCustomModal = (e) => {
    const projectId = e.target.attributes.earlyFinishId.value;
    this.setState({
      isModalVisible: true,
      projectId
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      visible: false
    });
  };

  draft = (wizardStates, id, hasCompany, isEditableRejected) => {
    if (!!isEditableRejected) {
      projectServiceModule
        .patchProjectStateAsDraft(id)
        .then(() => {
          this.navigateToWizard(id, wizardStates, hasCompany);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.navigateToWizard(id, wizardStates, hasCompany);
    }
  };

  navigateToWizard(id, wizardStates, hasCompany) {
    localStorage.setItem('wizardStates', wizardStates);
    store.set('projectId', id, true);
    if (hasCompany) {
      this.props.history.push({
        pathname: '/project-wizard/company'
      });
    } else {
      this.props.history.push({
        pathname: '/project-wizard/person'
      });
    }
  }

  updateProject = (id) => {
    this.props.history.push({
      pathname: '/dashboard/project/updates/',
      state: { projectId: id }
    });
  };

  showTables = (id) => {
    this.props.history.push({
      pathname: '/dashboard/project/tables/',
      state: { projectId: id }
    });
  };

  changeRequest = (id) => {
    this.props.history.push({
      pathname: '/dashboard/project/changerequest/',
      state: { projectId: id }
    });
  };

  finishEarly = async () => {
    let promise = projectServiceModule.postDemandFinishProject(this.state.projectId);
    if (promise && Promise.resolve(promise)) {
      promise
        .then((response) => {
          notification.success({
            message: this.props.lang.demandFinishSuccess
          });
          this.setState({ isModalVisible: false });
        })
        .catch((error) => {
          console.error(error);
        });
    }
    return promise;
  };

  deleteProject = async () => {
    let promise = projectServiceModule.deleteProject(this.state.projectId);
    if (promise && Promise.resolve(promise)) {
      promise
        .then(() => {
          this.handleCancel();
          window.location.reload();
          notification.success({
            message: this.props.lang.projectDeleted
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
    return promise;
  };

  render = () => {
    const { data, lang } = this.props;
    const currentDate = moment().utc();
    const startDate = moment.utc(data.startDate);
    const stopDate = moment.utc(data.stopFundingDate);
    const finishDate = moment.utc(data.finishDate);
    const lastApplicationEvaluationDateTime = moment.utc(data.lastApplicationEvaluationDateTime);

    return (
      <React.Fragment>
        <Row>
          <Col sm={12} md={3}>
            <div className={classnames(styles.projectLogo, 'm-0 p-0')}>
              {data.isDraft || (data.isRejected && !data.isEditable) ? (
                <img src={data.projectThumbnail} className={styles.logo} alt="Project Thumb" />
              ) : (
                <div className={styles.logoContainer}>
                  <img src={data.projectThumbnail} className={styles.logo} alt="Project Thumb" />
                </div>
              )}
              {data.isDraft || (data.isRejected && !data.isEditable) ? (
                ''
              ) : (
                <Link to={'/project/' + data.id}>
                  <Button className={styles.inspect}>{lang.inspectProject}</Button>
                </Link>
              )}
            </div>
          </Col>
          <Col sm={12} md={9}>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={6} sm={6} className={classnames(styles.header)}>
                    {data.isRejected && !data.isEditable ? (
                      data.title
                    ) : (
                      <Link to={'/project/' + data.id} className={styles.customHeader}>
                        {data.title}
                      </Link>
                    )}
                    <div style={{ marginBottom: 13 }}>
                      <FontAwesomeIcon icon={faMapMarkerAlt} />{' '}
                      <span>
                        {this.state.cityName}, {lang.country}
                      </span>
                    </div>
                  </Col>
                  <Col md={6} sm={6} className={classnames(styles.center, styles.buttonWrapper)}>
                    {!!data.isOngoing ||
                    !!data.isSuccess ||
                    !!data.isFail ||
                    !!data.isFinishProcessing ? (
                      <div>
                        <div className={styles.buttons}>
                          {!!data.isOngoing && (
                            <Button
                              className={styles.tableButton}
                              onClick={(e) => this.changeRequest(data.id)}
                            >
                              {lang.btnChangeRequest}
                            </Button>
                          )}
                          {data.canDemandEarlyFinish && (
                            <Button
                              className={styles.tableButton}
                              onClick={this.showCustomModal}
                              earlyFinishId={data.id}
                            >
                              {lang.btnDemandFinishEarly}
                            </Button>
                          )}
                          <Button
                            className={styles.tableButton}
                            onClick={(e) => this.showTables(data.id)}
                          >
                            {lang.tables}
                          </Button>
                          <Button
                            className={styles.update}
                            onClick={(e) => this.updateProject(data.id)}
                          >
                            {lang.btnUpdate}
                          </Button>
                        </div>
                        {data.isSuccess ? (
                          <p className={styles.success}>{lang.successfulProject}</p>
                        ) : data.isFail ? (
                          <p className={styles.error}>{lang.failedProject}</p>
                        ) : data.isFinishProcessing ? (
                          <p className={styles.processing}>{lang.processingProject}</p>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : !!data.isDraft || (!!data.isRejected && !!data.isEditable) ? (
                      <div>
                        <div className={styles.buttons}>
                          <Button
                            className={styles.tableButton}
                            onClick={this.draft.bind(
                              this,
                              data.wizardStates,
                              data.id,
                              data.hasCompany,
                              !!data.isRejected && !!data.isEditable && data.isDraft
                            )}
                          >
                            {lang.btnEdit}
                          </Button>
                          <Button
                            className={styles.update}
                            onClick={this.showModal}
                            projectid={data.id}
                          >
                            {lang.btnDelete}
                          </Button>
                        </div>
                        {!!data.isRejected && !!data.isEditable && (
                          <p className={styles.error}>{lang.rejected}</p>
                        )}
                      </div>
                    ) : data.isRejected && !data.isEditable ? (
                      <div>
                        <div className={styles.buttons}>
                          <Button
                            className={styles.update}
                            onClick={this.showModal}
                            projectid={data.id}
                          >
                            {lang.btnDelete}
                          </Button>
                        </div>
                        <p className={styles.error}>{lang.rejected}</p>
                      </div>
                    ) : data.isInApproval ||
                      data.isApproved ||
                      (!!data.isRejected && !data.isEditable) ? (
                      data.isApproved ? (
                        <p className={styles.success}>{lang.approved}</p>
                      ) : !!data.isRejected && !data.isEditable ? (
                        <p className={styles.error}>{lang.notApproved}</p>
                      ) : data.isInApproval ? (
                        <p className={styles.processing}>{lang.processing}</p>
                      ) : data.isInApproval ? (
                        <p className={styles.processing}>{lang.processing}</p>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
              </Col>

              <Col sm={12} className={classnames(styles.center, '')}>
                <Row>
                  <div className={classnames('col-xs-12 col-sm-6 col-md-3', styles.progress)}>
                    <Progress
                      percent={Number(data.fundedPercent) + Number(data.approvedFundingPercent)}
                      type="circle"
                      width={80}
                      status="normal"
                      format={(percent) =>
                        Number(data.fundedPercent) + Number(data.approvedFundingPercent) + '%'
                      }
                    />
                  </div>
                  <div className={classnames('col-xs-12 col-sm-6 col-md-3', styles.fund)}>
                    {data.isOngoing ||
                    data.isSuccessful ||
                    data.isFailed ||
                    data.status === 'Finished' ? (
                      <React.Fragment>
                        <p>
                          <NumberFormat
                            value={Number(data.collectedFunding) + Number(data.approvedFunding)}
                            displayType="text"
                            thousandSeparator="."
                            prefix="₺"
                            decimalSeparator=","
                            decimalScale={2}
                          />
                        </p>
                        <p className={styles.title}>{lang.collectedFunding}</p>
                      </React.Fragment>
                    ) : data.isInApproval || data.isApproved || data.isRejected || data.isDraft ? (
                      !!data.targetFunding && (
                        <React.Fragment>
                          <p>
                            <NumberFormat
                              value={data.targetFunding}
                              displayType="text"
                              thousandSeparator="."
                              prefix="₺"
                              decimalSeparator=","
                              decimalScale={2}
                            />
                          </p>
                          <p className={styles.title}>{lang.targetFunding}</p>
                        </React.Fragment>
                      )
                    ) : (
                      ''
                    )}
                  </div>
                  <div className={classnames('col-xs-12 col-sm-6 col-md-3', styles.fund)}>
                    {data.isOngoing ? (
                      !!data.finishDate && (
                        <div>
                          <p>{finishDate.diff(currentDate, 'days')}</p>
                          <p className={styles.title}>{lang.remainingDay}</p>
                        </div>
                      )
                    ) : data.isInApproval || data.isDraft || data.isApproved || data.isRejected ? (
                      !!data.finishDate && (
                        <div>
                          {startDate.diff(currentDate, 'days') > 0 ? (
                            <p>{startDate.diff(currentDate, 'days')}</p>
                          ) : (
                            <p>0</p>
                          )}
                          <p className={styles.title}>{lang.afterDay}</p>
                        </div>
                      )
                    ) : (
                      <div>
                        <p>
                          <NumberFormat
                            value={data.targetFunding}
                            displayType="text"
                            thousandSeparator="."
                            prefix="₺"
                            decimalSeparator=","
                            decimalScale={2}
                          />
                        </p>
                        <p className={styles.title}>{lang.targetFunding}</p>
                      </div>
                    )}
                  </div>
                  {
                    <div className={classnames('col-xs-12 col-sm-6 col-md-3', styles.fund)}>
                      {data.isOngoing
                        ? !!data.targetFunding && (
                            <div className={styles.justifyEnd}>
                              <p>
                                <NumberFormat
                                  value={data.targetFunding}
                                  displayType="text"
                                  thousandSeparator="."
                                  prefix="₺"
                                  decimalSeparator=","
                                  decimalScale={2}
                                />
                              </p>
                              <p className={styles.title}>{lang.targetFunding}</p>
                            </div>
                          )
                        : !!(data.isRejected && !data.isEditable
                            ? data.lastApplicationEvaluationDateTime
                            : data.startDate) && (
                            <div className={styles.justifyEnd}>
                              <p>
                                {(data.isRejected && !data.isEditable
                                  ? lastApplicationEvaluationDateTime
                                  : startDate
                                )
                                  .local()
                                  .format(
                                    `DD.MM.YYYY${
                                      !!this.props.parameters?.showTimeInfoOnProjectDates
                                        ? ' / HH:mm:ss'
                                        : ''
                                    }`
                                  )}
                              </p>
                              {data.isRejected && !data.isEditable ? (
                                <p className={styles.title}>{lang.requestDate}</p>
                              ) : (
                                <p className={styles.title}>{lang.fundingDate}</p>
                              )}
                            </div>
                          )}
                    </div>
                  }
                </Row>
              </Col>
            </Row>
          </Col>
          {data.isRejected && (
            <div className={classnames('col-xs-12 col-sm-12 col-md-12')}>
              <p className={styles.rejectTitle}>{`${lang.fundingReject}:`}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: data.rejectMessage
                }}
              ></p>
            </div>
          )}
          {data.isCancelled && (
            <div className={classnames('col-xs-12 col-sm-12 col-md-12')}>
              <p className={styles.rejectTitle}>{`${lang.cancellationReason}:`}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: data.cancelMessage
                }}
              ></p>
            </div>
          )}
        </Row>
        <CustomModal
          visible={this.state.isModalVisible}
          onOk={this.finishEarly}
          onCancel={this.handleCancel}
          title={lang.modalAreYouSure}
          okText={lang.modalOkButton}
          cancelText={lang.modalCancelButton}
        >
          <p>
            <strong>{lang.modalEarlyFinishTitle}</strong>
          </p>
          <p>{lang.modalEarlyFinishText}</p>
        </CustomModal>
        <CustomModal
          visible={this.state.visible}
          onOk={this.deleteProject}
          onCancel={this.handleCancel}
          title={lang.modalAreYouSure}
          okText={lang.modalOkButton}
          cancelText={lang.modalCancelButton}
        >
          <p>
            <strong>{lang.modalDeleteTitle}</strong>
          </p>
          <p>{lang.modalDeleteText}</p>
        </CustomModal>
      </React.Fragment>
    );
  };
}
const mapStateToProps = (state) => {
  return {
    lang: state.language.dashboard.userProjects,
    parameters: state.parameters?.clientParameters?.response
  };
};

export default withRouter(connect(mapStateToProps)(ProjectContainer));
