import { getLanguage } from '../shared/language/language';

let data = getLanguage();

export const switchLanguage = (language) => {
  return {
    type: 'SWITCH_LANGUAGE',
    language
  };
};
