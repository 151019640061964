import React, { useEffect, useState } from 'react';
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr';
import store from 'store2';
import RequestService from '../../../services/requestService';
import { connect } from 'react-redux';

const SignalRClient = (props) => {
  Object.defineProperty(window.WebSocket, 'OPEN', { value: 1 });

  const conn = new HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_API_ENDPOINT}signalR/hubs/${props.hub}`, {
      accessTokenFactory: () => store.get('authentication')?.token,
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets
    })
    .configureLogging(LogLevel.Trace)
    .build();

  conn.on('Receive', (data) => {
    try {
      props.onReceive(data);
    } catch (error) {
      console.error('Callback error: ', error);
    }
  });

  conn.onclose((error) => {
    if (!!error) console.error(error);
  });

  // to prevent tab to being put to sleep
  let lockResolver;
  if (navigator && navigator.locks && navigator.locks.request) {
    const promise = new Promise((resolver) => {
      lockResolver = resolver;
    });

    navigator.locks.request(props.hub, { mode: 'shared' }, () => {
      return promise;
    });
  }

  startConnection(conn);

  useEffect(() => {
    return () => {
      if (!!conn) conn.stop();
    };
  }, []);

  return <div></div>;
};

const startConnection = (connection) => {
  if (!!connection) {
    connection.stop().then(() =>
      connection.start().catch((error) => {
        if (error.message.indexOf('Core_Authentication_TokenExpired') >= 0) {
          // do refresh, then start again
          new RequestService().refresh().then(() => startConnection(connection));
        }
      })
    );
  }
};

const mapStateToProps = (state) => {
  return {
    authenticationInfo: state.authentication
  };
};

export default connect(mapStateToProps)(SignalRClient);
