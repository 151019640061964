import {
  makeGetRequest,
  makePostRequest,
  makePutRequest,
  makeDeleteRequest,
  makePatchRequest
} from '../request';

class ProjectServiceModule {
  getAllProjects = (queryString) => {
    return makeGetRequest('project/all' + queryString);
  };

  getProjectInfo = (projectId) => {
    return makeGetRequest('project/' + projectId + '/info');
  };

  getProjectDetail = (projectId) => {
    return makeGetRequest('project/' + projectId + '/detail');
  };

  getProjectFundingInfo = (projectId) => {
    return makeGetRequest('project/' + projectId + '/fundinginfo');
  };

  getProjectCompany = (projectId, showNotifications = false) => {
    return makeGetRequest('project/' + projectId + '/company', showNotifications);
  };

  getProjectDraftCompany = (projectId, showNotifications = false) => {
    return makeGetRequest('project/draft/' + projectId + '/company', showNotifications);
  };

  getProjectNonCompany = (projectId, showNotifications = false) => {
    return makeGetRequest('project/' + projectId + '/noncompany', showNotifications);
  };

  updateProjectNonCompany = (projectId, payload, showNotifications = true) => {
    return makePutRequest(`project/${projectId}/noncompany`, payload, showNotifications);
  };

  postProjectNonCompany = (projectId, payload, showNotifications = true) => {
    return makePostRequest(`project/${projectId}/noncompany`, payload, showNotifications);
  };

  getProjectDraftNonCompany = (projectId, showNotifications = false) => {
    return makeGetRequest('project/draft/' + projectId + '/noncompany', showNotifications);
  };

  getProjectTeamMembers = (projectId, queryString, showNotifications = false) => {
    return makeGetRequest(`project/${projectId}/teammembers${queryString}`, showNotifications);
  };

  getProjectDraftTeamMembers = (projectId, queryString, showNotifications = false) => {
    return makeGetRequest(
      `project/draft/${projectId}/teammembers${queryString}`,
      showNotifications
    );
  };

  updateTeamMembers = (projectId, payload, showNotifications = true) => {
    return makePutRequest(`project/${projectId}/teammembers`, payload, showNotifications);
  };

  postTeamMembers = (projectId, payload, showNotifications = true) => {
    return makePostRequest(`project/${projectId}/teammembers`, payload, showNotifications);
  };

  getProjectParticipants = (projectId, queryString, showNotifications = false) => {
    return makeGetRequest(`project/${projectId}/participants${queryString}`, showNotifications);
  };

  getProjectDraftParticipants = (projectId, queryString, showNotifications = false) => {
    return makeGetRequest(
      `project/draft/${projectId}/participants${queryString}`,
      showNotifications
    );
  };

  updateParticipants = (projectId, payload, showNotifications = true) => {
    return makePutRequest(`project/${projectId}/participants`, payload, showNotifications);
  };

  postParticipants = (projectId, payload, showNotifications = true) => {
    return makePostRequest(`project/${projectId}/participants`, payload, showNotifications);
  };

  getProjectDocuments = (projectId) => {
    return makeGetRequest('project/' + projectId + '/document');
  };

  getProjectFaqs = (projectId) => {
    return makeGetRequest('project/' + projectId + '/faq');
  };

  getProjectUpdates = (projectId, queryString) => {
    return makeGetRequest(`project/${projectId}/updates${queryString}`);
  };

  createProjectUpdate = (projectId, payload) => {
    return makePostRequest('project/' + projectId + '/update', payload);
  };

  getProjectParentComments = (projectId, queryString) => {
    return makeGetRequest(`project/${projectId}/parentcomments${queryString}`);
  };

  postProjectComment = (projectId, payload) => {
    return makePostRequest('project/' + projectId + '/comment', payload);
  };

  getProjectChildComments = (projectId, parentId, queryString) => {
    return makeGetRequest(`project/${projectId}/childcomments/${parentId}${queryString}`);
  };

  getProjectDraftDetail = (projectId) => {
    return makeGetRequest('project/draft/' + projectId);
  };

  createProjectDetail = (payload) => {
    return makePostRequest('project', payload);
  };

  updateProjectDetail = (projectId, payload) => {
    return makePutRequest('project/' + projectId, payload);
  };

  createProjectCompany = (projectId, payload) => {
    return makePostRequest('project/' + projectId + '/company', payload);
  };

  updateProjectCompany = (projectId, payload) => {
    return makePutRequest('project/' + projectId + '/company', payload);
  };

  getProjectDraftFundingInfo = (projectId) => {
    return makeGetRequest('project/draft/' + projectId + '/fundinginfo');
  };

  getProjectDailySpread = (projectId) => {
    return makeGetRequest(`project/${projectId}/dailySpread`);
  };

  getProjectCompletionSummary = (projectId) => {
    return makeGetRequest(`project/${projectId}/completionSummary`);
  };

  updateProjectFundingInfo = (projectId, payload) => {
    return makePutRequest('project/' + projectId + '/fundinginfo', payload);
  };

  getProjectDraftDocuments = (projectId) => {
    return makeGetRequest('project/draft/' + projectId + '/document');
  };

  createProjectDocuments = (projectId, payload) => {
    return makePostRequest('project/' + projectId + '/document', payload);
  };

  updateProjectDocuments = (projectId, payload) => {
    return makePutRequest('project/' + projectId + '/document', payload);
  };

  getProjectDraftFaqs = (projectId) => {
    return makeGetRequest('project/draft/' + projectId + '/faq');
  };

  createProjectFaq = (projectId, payload) => {
    return makePostRequest('project/' + projectId + '/faq', payload);
  };

  updateProjectFaq = (projectId, payload) => {
    return makePutRequest('project/' + projectId + '/faq', payload);
  };

  getAcceptedTerms = (projectId) => {
    return makeGetRequest('project/draft/' + projectId + '/termsandconditions');
  };

  acceptTerms = (projectId, payload) => {
    return makePutRequest('project/' + projectId + '/termsandconditions', payload);
  };

  createProject = (projectId) => {
    return makePostRequest('project/' + projectId + '/completeandevaluate');
  };

  getPieData = (projectId) => {
    return makeGetRequest('project/' + projectId + '/investmentpiedata');
  };

  getInvestmentDetails = (projectId, queryString) => {
    return makeGetRequest('project/' + projectId + '/investmentdetails' + queryString);
  };

  getInvestorDetails = (projectId, queryString) => {
    return makeGetRequest('project/' + projectId + '/investorDetails' + queryString);
  };

  deleteProject = (projectId) => makeDeleteRequest('project/' + projectId);

  postDemandFinishProject = (projectId) => {
    return makePostRequest(`project/${projectId}/demandprojectcompletion`);
  };

  getProjectInfoDocument = (projectId) => {
    return makeGetRequest(`project/${projectId}/infoDocument`);
  };

  getProjectStaticDocument = (projectId, documentType, showNotifications = false) => {
    return makeGetRequest(`project/${projectId}/staticdocument/${documentType}`, showNotifications);
  };

  postProjectStaticDocument = (projectId, payload) => {
    return makePostRequest(`project/${projectId}/staticdocument`, payload);
  };

  postChangeRequest = (projectId, payload) => {
    return makePostRequest(`project/${projectId}/demandprojectchange`, payload);
  };

  patchProjectStateAsDraft = (projectId) => {
    return makePatchRequest(`project/${projectId}/setstateasdraft`);
  };

  getProjectCategories = (queryString) => {
    return makeGetRequest(`project/categories${queryString}`);
  };

  getProjectImages = (projectId, queryString) => {
    return makeGetRequest(`project/${projectId}/images${queryString}`);
  };

  getProjectDraftImages = (projectId, queryString) => {
    return makeGetRequest(`project/draft/${projectId}/images${queryString}`);
  };

  createProjectImages = (projectId, payload) => {
    return makePostRequest('project/' + projectId + '/images', payload);
  };

  updateProjectImages = (projectId, payload) => {
    return makePutRequest('project/' + projectId + '/images', payload);
  };
}

export const projectServiceModule = new ProjectServiceModule();
