import ReactGA from 'react-ga';
const options = {
  debug: process.env.REACT_APP_IS_DEBUG_MODE.toLowerCase() === 'true' // enable logs
};

export const initGA = () => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, options);
};

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};
