import { Button, Divider, Icon, Menu } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import React from 'react';

const createNavLink = ({ key, to, label, icon, active, onClick }) => {
  return (
    <Menu.Item key={key}>
      {active === key && active != '' ? (
        <Divider type="vertical" className="dashboard-indicator" />
      ) : (
        ''
      )}
      {!!icon && (!!icon.type ? <Icon {...icon} /> : <FontAwesomeIcon {...icon} />)}

      <Link onClick={onClick} to={to}>
        {label}
      </Link>
    </Menu.Item>
  );
};

const createSubNavLink = ({ label, to }) => (
  <Menu.Item key={label} className="dashboard-footer-item">
    <Link className="dashboard-footer" to={to}>
      {label}
    </Link>
  </Menu.Item>
);

export { createNavLink, createSubNavLink };
