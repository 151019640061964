export const UPDATE_ACTIVETAB = 'UPDATE_ACTIVETAB';
export const RESET_ACTIVETAB = 'RESET_ACTIVETAB';

export const UpdateActiveTab = (activeTab) => {
  return {
    type: UPDATE_ACTIVETAB,
    payload: { activeTab }
  };
};

export const ResetActiveTab = () => {
  return {
    type: RESET_ACTIVETAB
  };
};
