import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Button } from 'antd';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { EmailForm, PhoneNumberForm } from './forms';
import MkkVerificationForm from './forms/mkkVerificationForm';
import BirthDateForm from './forms/birthDateForm';
import AddressForm from './forms/addressForm';
import Info from './forms/info';

const ProfileInfo = (props) => {
  const [isEditableMode, SetIsEditableMode] = useState(false);
  return (
    <Row className={classnames(styles.container, 'pt-5 pb-5 pl-5 pr-md-5 mb-4')}>
      <Col xs={12} className="p-0">
        <h1>{props.profileInfo.title} </h1>
        {!isEditableMode ? (
          <Info />
        ) : (
          <Row>
            <Col className={styles.xxl} xs={12} xl={7}>
              <EmailForm />
              <PhoneNumberForm />
              {!!props.regularUser?.identityVerified && <MkkVerificationForm />}
              {!!props.regularUser?.identityVerified && !props.regularUser?.birthDateVerified && (
                <BirthDateForm />
              )}
            </Col>

            {(props.regularUser?.identityType === 'YKN' ||
              !!props.parameters?.clientParameters?.response
                ?.userAddressInfoIsRequiredWhenNotForeignIdentity) && (
              <Col className={styles.xxl} xs={12} xl={5}>
                <AddressForm />
              </Col>
            )}
          </Row>
        )}
      </Col>
      <Col xs={12} className="d-flex justify-content-start align-items-center">
        <Button onClick={() => SetIsEditableMode(!isEditableMode)} className={styles.updateButton}>
          {!isEditableMode ? props.profileInfo.btnUpdateProfile : props.profileInfo.btnTurnBack}
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.language.dashboard.profile.profileInfo,
    regularUser: state.regularUser,
    parameters: state.parameters
  };
};

export default connect(mapStateToProps)(ProfileInfo);

