import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { ConfigProvider, Table, Empty } from 'antd';
import {
  GetProjectTablesInvestorDetails,
  ResetProjectTablesInvestorDetails
} from '../../../redux/ProjectDetail/actions';
import { buildQueryString, defaultSpfParameters } from '../../../helpers/http/request';
import styles from './styles.module.scss';
import NumberFormat from 'react-number-format';

const InvestorTable = (props) => {
  useEffect(() => {
    getInvestorDetails();
  }, []);

  const getInvestorDetails = (pagination) => {
    const payload = {
      projectId: props.projectId,
      queryString: buildQueryString({
        ...defaultSpfParameters,
        page: pagination?.current,
        pageSize: pagination?.pageSize
      })
    };
    props.GetProjectTablesInvestorDetails(payload);
  };

  useEffect(() => {
    return () => {
      props.ResetProjectTablesInvestorDetails();
    };
  }, []);

  const onPageChange = (pagination) => {
    getInvestorDetails(pagination);
  };

  const investorTableColumns = [
    {
      title: props.language.investorName,
      dataIndex: 'fullName',
      key: 'fullName'
    },
    {
      title: props.language.status,
      dataIndex: 'isQualified',
      key: 'isQualified',
      render: (isQualified) => {
        return <span>{isQualified ? props.language.licence : props.language.nonLicence}</span>;
      }
    },
    {
      title: props.language.shareCount,
      dataIndex: 'numberOfShares',
      key: 'numberOfShares',
      render: (numberOfShares) => {
        return (
          <NumberFormat
            value={numberOfShares}
            displayType="text"
            thousandSeparator="."
            prefix=""
            decimalSeparator=","
            decimalScale={2}
          />
        );
      }
    },
    {
      title: props.language.ownedPercentage,
      dataIndex: 'percentageOfShares',
      key: 'percentageOfShares',
      render: (percentageOfShares) => {
        return (
          <NumberFormat
            value={percentageOfShares}
            displayType="text"
            thousandSeparator="."
            prefix={'%'}
            decimalSeparator=","
            decimalScale={props.decimalScale.toString().length}
          />
        );
      }
    },
    {
      title: props.language.fundingAmount,
      dataIndex: 'totalFunding',
      key: 'totalFunding',
      render: (totalFunding) => {
        return (
          <NumberFormat
            value={totalFunding}
            displayType="text"
            thousandSeparator="."
            prefix="₺"
            decimalSeparator=","
            decimalScale={2}
          />
        );
      }
    }
  ];

  return (
    <section>
      <Row>
        <Col sm={12}>
          <h3 className={styles.text}>{props.language.investors}</h3>
        </Col>
      </Row>
      {!!props.projectDetail?.tablesInvestorDetails?.response?.parameters &&
        !!props.projectDetail?.tablesInvestorDetails?.response?.data && (
          <Row>
            <Col sm={12}>
              <ConfigProvider
                renderEmpty={() => <Empty description={<p>{props.language.emptyText}</p>} />}
              >
                <Table
                  scroll={{ x: 580 }}
                  pagination={props.projectDetail?.tablesInvestorDetails?.response?.parameters}
                  columns={investorTableColumns}
                  dataSource={props.projectDetail?.tablesInvestorDetails?.response?.data}
                  locale={props.language.emptyText}
                  onChange={onPageChange}
                  rowKey={(record) => record.id + record.userName}
                />
              </ConfigProvider>
            </Col>
          </Row>
        )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.dashboard.tables,
    projectDetail: state.projectDetail
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetProjectTablesInvestorDetails: (payload) =>
      dispatch(GetProjectTablesInvestorDetails(payload)),
    ResetProjectTablesInvestorDetails: (payload) =>
      dispatch(ResetProjectTablesInvestorDetails(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestorTable);

