import { Col, Form, Row, Timeline } from 'antd';

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import styles from './styles.module.scss';
import FileDownload from '../../custom/FileDownload/index';

class ProjectUpdate extends React.Component {
  componentDidMount() {}

  render() {
    return this.props.projectUpdates !== null &&
      this.props.projectUpdates !== undefined &&
      Object.keys(this.props.projectUpdates).length > 0
      ? Object.values(this.props.projectUpdates).map((projectUpdate) => (
          <Row className={styles.updateRow}>
            <Col className={styles.updateCol} xs={6} sm={6} md={4}>
              {moment.utc(projectUpdate.createDateTime).local().format('DD/MM/YYYY')}
              <p className={styles.managementUpdate}>
                {!!projectUpdate.isManagementEntry && this.props.lang.managementUpdates}
              </p>
            </Col>
            <Col xs={18} sm={18} md={20}>
              <Timeline>
                <Timeline.Item>
                  <p className={styles.header}>
                    {projectUpdate.title}
                    {!!projectUpdate.forInvestorsOnly &&
                      ' (' + this.props.lang.forInvestorsOnlyDescriptionText + ')'}
                  </p>
                  <p>{projectUpdate.text}</p>
                  {projectUpdate.documents && projectUpdate.documents.length > 0 && (
                    <div>
                      <p className={styles.header}>{this.props.lang.documents}</p>
                      {projectUpdate.documents.map((item) => {
                        return (
                          <p>
                            <FileDownload type="link" url={item.url} text={item.title} />
                          </p>
                        );
                      })}
                    </div>
                  )}
                </Timeline.Item>
                <Timeline.Item style={{ display: 'none' }} />
              </Timeline>
            </Col>
          </Row>
        ))
      : null;
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.language.dashboard.updates
  };
};

export default connect(mapStateToProps)(ProjectUpdate);
