import Service from './baseService';
import store from 'store2';

class RequestService {
  constructor() {
    this.refreshPromise = null;
    this.redirectToLoginPromise = null;
    this.authInfo = store.get('authentication') ?? null;
  }

  createRefreshPromise = () => {
    if (!this.refreshPromise) {
      const payload = {
        token: this.authInfo?.token,
        refreshToken: this.authInfo?.refreshToken
      };
      this.refreshPromise = new Service().post('authenticate/refresh', payload).catch((error) => {
        return Promise.reject({ needsLogin: true });
      });
    }

    return this.refreshPromise;
  };

  createLoginPromise = (payload) => {
    let service = new Service();
    return service.post('authenticate', payload).then((response) => {
      this.authInfo = this.prepareAuthInfo(response);
      return this.authInfo;
    });
  };

  createPromise = async (requestParameters) => {
    let promise = new Promise(() => {});
    let service = new Service(this.authInfo);
    switch (requestParameters?.type) {
      case 'LOGIN':
        promise = this.createLoginPromise(requestParameters?.payload);
        break;
      case 'POST':
        promise = service.post(requestParameters?.url, requestParameters?.payload);
        break;
      case 'GET':
        promise = service.get(requestParameters?.url);
        break;
      case 'GETBLOB':
        promise = service.getBlob(requestParameters?.url);
        break;
      case 'PUT':
        promise = service.put(requestParameters?.url, requestParameters?.payload);
        break;
      case 'PATCH':
        promise = service.patch(requestParameters?.url, requestParameters?.payload);
        break;
      case 'DELETE':
        promise = service.delete(requestParameters?.url);
        break;
      case 'UPLOAD':
        promise = service.upload(
          requestParameters?.url,
          requestParameters?.payload,
          requestParameters?.onUploadProgress
        );
        break;
      case 'LOG':
        promise = service.log(requestParameters?.url, requestParameters?.payload);
        break;
      default:
        break;
    }
    return promise;
  };

  redirectToLogin = async () => {
    if (!this.redirectToLoginPromise) {
      this.redirectToLoginPromise = new Promise((resolve, reject) => {
        try {
          store.remove('authentication');

          if (document.location !== '/login') {
            window.location.assign('/login');
          } else {
            window.location.reload();
          }
          resolve(Promise.resolve());
        } catch (error) {
          reject(Promise.reject(error));
        }
      });
    }
    return this.redirectToLoginPromise;
  };

  refresh = async () => {
    let isSuccess = true;
    let refreshResponse;
    try {
      refreshResponse = await this.createRefreshPromise();

      this.authInfo = { ...this.authInfo, ...refreshResponse };
      store.set('authentication', this.authInfo);
    } catch (error) {
      store.remove('authentication');
      if (document.location !== '/login') {
        window.location.assign('/login');
      } else {
        window.location.reload();
      }
      refreshResponse = error;
    }

    let promise = new Promise((resolve, reject) => {
      if (!!isSuccess) {
        resolve(refreshResponse);
      } else {
        reject(refreshResponse);
      }
    });
    this.refreshPromise = null;

    return promise;
  };

  prepareAuthInfo = (data) => {
    let token = data.tokenData;
    delete data.tokenData;
    const authInfo = { ...data, ...token };

    return authInfo;
  };
}

export default RequestService;
