import React from 'react';
import { connect } from 'react-redux';
import validator from 'validator';
import { Form, Input } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { OneClickButton } from '../../../custom';
import { notify } from '../../../../shared/notify';
import { userServiceModule } from '../../../../services/modules/userServiceModule';
import styles from '../styles.module.scss';

function EMailForm(props) {
  const validateEMail = (rule, value, callback) => {
    if (!value) {
      callback(props.common.requiredField);
      return;
    }

    if (value.length > 128) {
      callback(props.common.errorMessageMax128);
      return;
    }

    if (!!value && validator.isEmail(value)) {
      callback();
      return;
    }

    callback(props.common.errorMessageEMail);
  };

  const changeMail = async () => {
    let promise;
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (props.regularUser?.eMailAddress === values.eMailAddress) {
          notify('Error', props.profileInfo.emailSameEmailErrorNotfiy);
          return promise;
        }
        const payload = {
          eMailAddress: values.eMailAddress
        };

        promise = userServiceModule.changeEmail(payload).then((response) => {
          notify('Success', props.profileInfo.emailChangeNotify);
        });
      }
    });
    return promise;
  };

  return (
    <Form id="EMailForm" layout={'inline'} colon={false}>
      <Row>
        <Col>
          <Input.Group compact>
            <Form.Item label={props.profileInfo.eMailAddress} hasFeedback={false}>
              {props.form.getFieldDecorator('eMailAddress', {
                validateTrigger: ['onBlur'],
                rules: [
                  {
                    validator: validateEMail
                  }
                ],
                initialValue: props.regularUser?.eMailAddress
              })(<Input className={styles.formInput} />)}
            </Form.Item>

            <Form.Item>
              <OneClickButton onClick={changeMail} className={styles.button} form="EMailForm">
                {props.profileInfo.btnChange}
              </OneClickButton>
            </Form.Item>
          </Input.Group>
        </Col>
      </Row>
    </Form>
  );
}

const mapStateToProps = (state) => {
  return {
    profileInfo: state.language.dashboard.profile.profileInfo,
    regularUser: state.regularUser,
    common: state.language.common
  };
};

export default connect(mapStateToProps)(Form.create({ name: 'EMailForm' })(EMailForm));
