import { LOG, LOG_SUCCESS, LOG_ERROR } from '../actions';

export const Log = (payload) => {
  return {
    type: LOG,
    payload
  };
};

export const LogSuccess = (payload) => {
  return {
    type: LOG_SUCCESS,
    payload
  };
};

export const LogError = (payload) => {
  return {
    type: LOG_ERROR,
    payload
  };
};
