import english from './english.json';
import turkish from './turkish.json';

export const getLanguage = (type = 'TR') => {
  switch (type) {
    case 'EN':
      return english;
    case 'TR':
      return turkish;
    default:
      return turkish;
  }
};
