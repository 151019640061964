import {
  GET_GEO_ADDRESS_COUNTRIES,
  GET_GEO_ADDRESS_COUNTRIES_SUCCESS,
  GET_GEO_ADDRESS_COUNTRIES_ERROR,
  RESET_GEO_ADDRESS_COUNTRIES,
  GET_GEO_ADDRESS_COUNTRY_STATES,
  GET_GEO_ADDRESS_COUNTRY_STATES_SUCCESS,
  GET_GEO_ADDRESS_COUNTRY_STATES_ERROR,
  RESET_GEO_ADDRESS_COUNTRY_STATES,
  GET_GEO_ADDRESS_COUNTRY_STATE_CITIES,
  GET_GEO_ADDRESS_COUNTRY_STATE_CITIES_SUCCESS,
  GET_GEO_ADDRESS_COUNTRY_STATE_CITIES_ERROR,
  RESET_GEO_ADDRESS_COUNTRY_STATE_CITIES
} from '../actions';

export const GetGeoAddressCountries = (payload) => {
  return {
    type: GET_GEO_ADDRESS_COUNTRIES,
    payload
  };
};

export const GetGeoAddressCountriesSuccess = (payload) => {
  return {
    type: GET_GEO_ADDRESS_COUNTRIES_SUCCESS,
    payload
  };
};

export const GetGeoAddressCountriesError = (payload) => {
  return {
    type: GET_GEO_ADDRESS_COUNTRIES_ERROR,
    payload
  };
};

export const ResetGeoAddressCountries = (payload) => {
  return {
    type: RESET_GEO_ADDRESS_COUNTRIES,
    payload
  };
};

export const GetGeoAddressCountryStates = (payload) => {
  return {
    type: GET_GEO_ADDRESS_COUNTRY_STATES,
    payload
  };
};

export const GetGeoAddressCountryStatesSuccess = (payload) => {
  return {
    type: GET_GEO_ADDRESS_COUNTRY_STATES_SUCCESS,
    payload
  };
};

export const GetGeoAddressCountryStatesError = (payload) => {
  return {
    type: GET_GEO_ADDRESS_COUNTRY_STATES_ERROR,
    payload
  };
};

export const ResetGeoAddressCountryStates = (payload) => {
  return {
    type: RESET_GEO_ADDRESS_COUNTRY_STATES,
    payload
  };
};

export const GetGeoAddressCountryStateCities = (payload) => {
  return {
    type: GET_GEO_ADDRESS_COUNTRY_STATE_CITIES,
    payload
  };
};

export const GetGeoAddressCountryStateCitiesSuccess = (payload) => {
  return {
    type: GET_GEO_ADDRESS_COUNTRY_STATE_CITIES_SUCCESS,
    payload
  };
};

export const GetGeoAddressCountryStateCitiesError = (payload) => {
  return {
    type: GET_GEO_ADDRESS_COUNTRY_STATE_CITIES_ERROR,
    payload
  };
};

export const ResetGeoAddressCountryStateCities = (payload) => {
  return {
    type: RESET_GEO_ADDRESS_COUNTRY_STATE_CITIES,
    payload
  };
};
