import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { Form, Button, Select, Input, Tooltip, Icon } from 'antd';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input';
import NumericInput from '../../custom/Input/NumericInput';
import styles from './styles.module.scss';
import { Uploader } from '../../custom';
import { userServiceModule } from '../../../services/modules/userServiceModule';
import Important from './important';
import { GetRegularUser } from '../../../redux/RegularUser/actions';
import validator from 'validator';
import { secondsToMilliseconds } from 'date-fns';
import { OneClickButton } from '../../custom/index';

const CompanyInfo = (props) => {
  const [evaluationErrorMessage, SetEvaluationErrorMessage] = useState(null);

  const [taxSignboardFileList, SetTaxSignboardFileList] = useState([]);
  const [tradeCertificateofRegistryFileList, SetTradeCertificateofRegistryFileList] = useState([]);
  const [officialGazetteSampleFileList, SetOfficialGazetteSampleFileList] = useState([]);
  const [activityCertificateFileList, SetActivityCertificateFileList] = useState([]);
  const [circularofSignatureFileList, SetCircularofSignatureFileList] = useState([]);

  const [authoritativeTitle, SetAuthoritativeTitle] = useState(null);
  const [companyTitle, SetCompanyTitle] = useState(null);
  const [taxNumber, SetTaxNumber] = useState(null);
  const [taxOffice, SetTaxOffice] = useState(null);
  const [registrationNumber, SetRegistrationNumber] = useState(null);
  const [corporatePhoneNumber, SetCorporatePhoneNumber] = useState(null);
  const [corporatEMailAddress, SetCorporatEMailAddress] = useState(null);

  useEffect(() => {
    if (props.regularUser?.corporateIdentityStatus === 'Rejected')
      userServiceModule
        .getCorporateIdentity()
        .then((response) => {
          SetEvaluationErrorMessage(response.rejectionMessage);

          SetAuthoritativeTitle(response.authoritativeTitle);
          SetCompanyTitle(response.title);
          SetTaxNumber(response.taxNumber);
          SetTaxOffice(response.taxOffice);
          SetRegistrationNumber(response.registrationNumber);
          SetCorporatePhoneNumber(response.corporatePhoneNumber);
          SetCorporatEMailAddress(response.corporateEMailAddress);

          const taxSignboardDocument = response.documents.find(
            (document) => document.documentType === 'TaxSignboard'
          );
          const tradeCertificateOfRegistryDocument = response.documents.find(
            (document) => document.documentType === 'TradeCertificateofRegistry'
          );
          const officialGazetteSampleDocument = response.documents.find(
            (document) => document.documentType === 'OfficialGazetteSample'
          );
          const activityCertificateDocument = response.documents.find(
            (document) => document.documentType === 'ActivityCertificate'
          );
          const circularOfSignatureDocument = response.documents.find(
            (document) => document.documentType === 'CircularofSignature'
          );

          if (!!taxSignboardDocument)
            SetTaxSignboardFileList([
              {
                key: taxSignboardDocument.documentStorageKey,
                name: taxSignboardDocument.documentStorageKey,
                url: taxSignboardDocument.documentUrl,
                uid: '-1',
                status: 'done'
              }
            ]);

          if (!!tradeCertificateOfRegistryDocument)
            SetTradeCertificateofRegistryFileList([
              {
                key: tradeCertificateOfRegistryDocument.documentStorageKey,
                name: tradeCertificateOfRegistryDocument.documentStorageKey,
                url: tradeCertificateOfRegistryDocument.documentUrl,
                uid: '-1',
                status: 'done'
              }
            ]);

          if (!!officialGazetteSampleDocument)
            SetOfficialGazetteSampleFileList([
              {
                key: officialGazetteSampleDocument.documentStorageKey,
                name: officialGazetteSampleDocument.documentStorageKey,
                url: officialGazetteSampleDocument.documentUrl,
                uid: '-1',
                status: 'done'
              }
            ]);

          if (!!activityCertificateDocument)
            SetActivityCertificateFileList([
              {
                key: activityCertificateDocument.documentStorageKey,
                name: activityCertificateDocument.documentStorageKey,
                url: activityCertificateDocument.documentUrl,
                uid: '-1',
                status: 'done'
              }
            ]);

          if (!!circularOfSignatureDocument)
            SetCircularofSignatureFileList([
              {
                key: circularOfSignatureDocument.documentStorageKey,
                name: circularOfSignatureDocument.documentStorageKey,
                url: circularOfSignatureDocument.documentUrl,
                uid: '-1',
                status: 'done'
              }
            ]);
        })
        .catch((error) => {
          console.error(error);
        });
  }, [props.regularUser?.corporateIdentityStatus]);

  const onFileUploadSuccess = (type, uploadedFile) => {
    let documentObj = {
      key: uploadedFile.storageKey,
      name: uploadedFile.name,
      url: uploadedFile.url,
      uid: '-1',
      status: 'done'
    };

    switch (type) {
      case 'TaxSignboard':
        SetTaxSignboardFileList([documentObj]);
        break;
      case 'TradeCertificateofRegistry':
        SetTradeCertificateofRegistryFileList([documentObj]);
        break;
      case 'OfficialGazetteSample':
        SetOfficialGazetteSampleFileList([documentObj]);
        break;
      case 'ActivityCertificate':
        SetActivityCertificateFileList([documentObj]);
        break;
      case 'CircularofSignature':
        SetCircularofSignatureFileList([documentObj]);
        break;
    }
  };

  const onFileRemove = (type) => {
    switch (type) {
      case 'TaxSignboard':
        SetTaxSignboardFileList([]);
        break;
      case 'TradeCertificateofRegistry':
        SetTradeCertificateofRegistryFileList([]);
        break;
      case 'OfficialGazetteSample':
        SetOfficialGazetteSampleFileList([]);
        break;
      case 'ActivityCertificate':
        SetActivityCertificateFileList([]);
        break;
      case 'CircularofSignature':
        SetCircularofSignatureFileList([]);
        break;
    }
  };

  const validateEMail = (rule, value, callback) => {
    if (!value) {
      callback(props.commonLanguage.requiredField);
      return;
    }

    if (value.length > 128) {
      callback(props.commonLanguage.errorMessageMax128);
      return;
    }

    if (!!value && validator.isEmail(value)) {
      callback();
      return;
    }

    callback(props.commonLanguage.errorMessageEMail);
  };

  const validatePhoneNumber = (rule, value, callback) => {
    if (!value) {
      callback(props.commonLanguage.requiredField);
      return;
    }

    if (value.length > 255) {
      callback(props.commonLanguage.errorMessageMax255);
      return;
    }

    if (!!value && isValidPhoneNumber(value)) {
      callback();
      return;
    }

    callback(props.commonLanguage.requiredField);
  };

  const validateTaxSignboard = (rule, value, callback) => {
    if (!!taxSignboardFileList && taxSignboardFileList.length === 0) {
      callback(props.commonLanguage.requiredField);
    }

    callback();
    return;
  };

  const validateTradeCertificateofRegistry = (rule, value, callback) => {
    if (!!tradeCertificateofRegistryFileList && tradeCertificateofRegistryFileList.length === 0) {
      callback(props.commonLanguage.requiredField);
    }

    callback();
    return;
  };

  const validateOfficialGazetteSample = (rule, value, callback) => {
    if (!!officialGazetteSampleFileList && officialGazetteSampleFileList.length === 0) {
      callback(props.commonLanguage.requiredField);
    }

    callback();
    return;
  };

  const validateActivityCertificate = (rule, value, callback) => {
    if (!!activityCertificateFileList && activityCertificateFileList.length === 0) {
      callback(props.commonLanguage.requiredField);
    }

    callback();
    return;
  };

  const validateCircularofSignature = (rule, value, callback) => {
    if (!!circularofSignatureFileList && circularofSignatureFileList.length === 0) {
      callback(props.commonLanguage.requiredField);
    }

    callback();
    return;
  };

  const handleSubmit = () => {
    let promise;
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const trimmedCompanyTitle = values.companyTitle.trim();
        const companyTitle = trimmedCompanyTitle.replace(/\s+/g, ' ');

        const trimmedRepresentativeTitle = values.representativeTitle.trim();
        const representativeTitle = trimmedRepresentativeTitle.replace(/\s+/g, ' ');

        const trimmedTaxOffice = values.taxOffice.trim();
        const taxOffice = trimmedTaxOffice.replace(/\s+/g, ' ');

        let payload = {
          title: companyTitle,
          taxNumber: values.taxNumber,
          taxOffice: taxOffice,
          registrationNumber: values.tradeRegistryNumber,
          corporatePhoneNumber: values.corporatePhoneNumber,
          corporateEMailAddress: values.corporateEMailAddress,
          authoritativeTitle: representativeTitle,
          corporateDocuments: [
            {
              documentType: 'CircularofSignature',
              documentStorageKey: circularofSignatureFileList[0].key
            },
            {
              documentType: 'ActivityCertificate',
              documentStorageKey: activityCertificateFileList[0].key
            },
            {
              documentType: 'OfficialGazetteSample',
              documentStorageKey: officialGazetteSampleFileList[0].key
            },
            {
              documentType: 'TradeCertificateofRegistry',
              documentStorageKey: tradeCertificateofRegistryFileList[0].key
            },
            {
              documentType: 'TaxSignboard',
              documentStorageKey: taxSignboardFileList[0].key
            }
          ]
        };

        promise = userServiceModule.postCorporateInfo(payload).then((response) => {
          props.GetRegularUser();
        });
      }
    });

    return promise;
  };

  let subscriptionType = Object.values(props.data.subscriptionSelectOptions).filter(
    (subscriptionTypes) => subscriptionTypes.key === 'Corporate'
  )[0];
  const { getFieldDecorator } = props.form;

  return (
    <div>
      <Form id="companyInfoForm" onSubmit={handleSubmit}>
        <Row>
          <Col sm={12} md={6} className={classnames(styles.border, 'pr-md-0')}>
            <div className="pr-md-5">
              <h3>{props.data.subscriptionType}</h3>
              <Row className="mb-5">
                <Col>
                  <Select disabled value={subscriptionType.key}>
                    <Select.Option key={subscriptionType.key} value={subscriptionType.key}>
                      {subscriptionType.value}
                    </Select.Option>
                  </Select>
                </Col>
              </Row>
              <h3>{props.data.representativeVerification}</h3>
              <Row>
                <Col sm={6}>
                  <Form.Item label={props.data.name} className="mb-0">
                    <Input key="name" disabled value={props.regularUser?.name} />
                  </Form.Item>
                </Col>
                <Col sm={6}>
                  <Form.Item label={props.data.surname} className="mb-0">
                    <Input key="surname" disabled value={props.regularUser?.surname} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Item
                    label={
                      !!props.regularUser?.targetIdentityType &&
                      props.regularUser?.targetIdentityType === 'YKN'
                        ? props.data.foreignIdentityNumber
                        : props.data.identityNumber
                    }
                    className="mb-0"
                  >
                    <NumericInput
                      key="idNumber"
                      disabled
                      value={props.regularUser?.identityNumber}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Item label={props.data.representativeTitle} className="mb-0">
                    {getFieldDecorator('representativeTitle', {
                      validateTrigger: ['onChange'],
                      rules: [
                        {
                          required: true,
                          message: props.commonLanguage.requiredField
                        },
                        {
                          max: 255,
                          message: props.commonLanguage.errorMessageMax255
                        }
                      ],
                      initialValue: authoritativeTitle
                    })(<Input key="representativeTitle" />)}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm={12} md={6} className="pl-md-0">
            <div className="pl-md-5 mt-sm-5 mt-md-0">
              <Important subscriptionType="Corporate" />
            </div>
          </Col>
        </Row>
        {!!evaluationErrorMessage && (
          <Row>
            <Col>
              <div className="mt-3">
                <span className={classnames(styles.identityVerificationError)}>
                  <p>{props.data.corporateIdentityEvaluationErrorTitle}</p>

                  <p
                    dangerouslySetInnerHTML={{
                      __html: evaluationErrorMessage
                    }}
                  ></p>
                </span>
              </div>
            </Col>
          </Row>
        )}

        <Row className="mt-5">
          <Col sm={12} md={6} className="pr-md-0">
            <div className={classnames(styles.expContainer, 'pr-md-5 pb-5')}>
              <h3>{props.data.companyInfo}</h3>
              <Form.Item
                label={
                  <span>
                    <Tooltip title="Şirket Unvanı, Vergi Levhasında olduğu gibi büyük/küçük harfe dikkat edilerek ve kısaltma kullanılmadan girilmelidir. Aksi halde üyelik başvurunuz onaylanmayacaktır.">
                      {`${props.data.companyTitle} `}
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
                className="mb-0"
              >
                {getFieldDecorator('companyTitle', {
                  validateTrigger: ['onChange'],
                  rules: [
                    {
                      required: true,
                      message: props.commonLanguage.requiredField
                    },
                    {
                      max: 500,
                      message: props.commonLanguage.errorMessageMax500
                    }
                  ],
                  initialValue: companyTitle
                })(<Input key="companyTitle" />)}
              </Form.Item>
              <Form.Item label={props.data.taxNumber} className="mb-0">
                {getFieldDecorator('taxNumber', {
                  validateTrigger: ['onChange'],
                  rules: [
                    {
                      required: true,
                      message: props.commonLanguage.requiredField
                    },
                    {
                      max: 255,
                      message: props.commonLanguage.errorMessageMax255
                    },
                    {
                      pattern: RegExp('^[0-9]{10,10}$'),
                      message: props.data.vknNumberValidation
                    }
                  ],
                  initialValue: taxNumber
                })(<Input key="taxNumber" />)}
              </Form.Item>
              <Form.Item label={props.data.taxOffice} className="mb-0">
                {getFieldDecorator('taxOffice', {
                  validateTrigger: ['onChange'],
                  rules: [
                    {
                      required: true,
                      message: props.commonLanguage.requiredField
                    },
                    {
                      max: 255,
                      message: props.commonLanguage.errorMessageMax255
                    }
                  ],
                  initialValue: taxOffice
                })(<Input key="taxOffice" />)}
              </Form.Item>
              <Form.Item label={props.data.tradeRegistryNumber} className="mb-0">
                {getFieldDecorator('tradeRegistryNumber', {
                  validateTrigger: ['onChange'],
                  rules: [
                    {
                      required: true,
                      message: props.commonLanguage.requiredField
                    },
                    {
                      max: 32,
                      message: props.commonLanguage.errorMessageMax32
                    },
                    {
                      pattern: RegExp('^[0-9]*$'),
                      message: props.data.tradeRegistryNumberValidation
                    }
                  ],
                  initialValue: registrationNumber
                })(<Input key="tradeRegistryNumber" />)}
              </Form.Item>
              <Form.Item label={props.data.corporatePhoneNumber} className="mb-0">
                {getFieldDecorator('corporatePhoneNumber', {
                  rules: [
                    {
                      required: true,
                      validator: validatePhoneNumber
                    }
                  ],
                  initialValue: corporatePhoneNumber
                })(
                  <PhoneInput
                    className="ant-input"
                    country="TR"
                    withCountryCallingCode
                    international
                    key="corporatePhoneNumber"
                  />
                )}
              </Form.Item>
              <Form.Item label={props.data.corporateEmailAddress} className="mb-0">
                {getFieldDecorator('corporateEMailAddress', {
                  validateTrigger: ['onChange'],
                  rules: [
                    {
                      required: true,
                      validator: validateEMail
                    }
                  ],
                  initialValue: corporatEMailAddress
                })(<Input key="corporateEMailAddress" />)}
              </Form.Item>
            </div>
          </Col>
          <Col sm={12} md={6} className="pl-md-0">
            <div className={classnames(styles.expContainer, 'pl-md-5')}>
              <Form.Item label={props.data.taxSignboard} className="mb-0">
                {getFieldDecorator('TaxSignboard', {
                  validateTrigger: ['onSuccess', 'onRemove', 'onChange'],
                  rules: [
                    {
                      validator: validateTaxSignboard
                    }
                  ],
                  initialValue: taxSignboardFileList
                })(
                  <Uploader
                    key="TaxSignboard"
                    // accept=".doc,.docx,.pdf"
                    accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                    buttonClassName="default"
                    fileList={taxSignboardFileList}
                    onSuccess={(uploadedFile) => onFileUploadSuccess('TaxSignboard', uploadedFile)}
                    onRemove={(uploadedFile) => onFileRemove('TaxSignboard', uploadedFile)}
                    text={props.commonLanguage.btnSelectUpload}
                  />
                )}
              </Form.Item>{' '}
              <Form.Item label={props.data.tradeCertificateofRegistry} className="mb-0">
                {getFieldDecorator('TradeCertificateofRegistry', {
                  validateTrigger: ['onSuccess', 'onRemove', 'onChange'],
                  rules: [
                    {
                      validator: validateTradeCertificateofRegistry
                    }
                  ],
                  initialValue: tradeCertificateofRegistryFileList
                })(
                  <Uploader
                    key="TradeCertificateofRegistry"
                    // accept=".doc,.docx,.pdf"
                    accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                    buttonClassName="default"
                    fileList={tradeCertificateofRegistryFileList}
                    onSuccess={(uploadedFile) =>
                      onFileUploadSuccess('TradeCertificateofRegistry', uploadedFile)
                    }
                    onRemove={(uploadedFile) =>
                      onFileRemove('TradeCertificateofRegistry', uploadedFile)
                    }
                    text={props.commonLanguage.btnSelectUpload}
                  />
                )}
              </Form.Item>
              <Form.Item label={props.data.officialGazetteSample} className="mb-0">
                {getFieldDecorator('OfficialGazetteSample', {
                  validateTrigger: ['onSuccess', 'onRemove', 'onChange'],
                  rules: [
                    {
                      validator: validateOfficialGazetteSample
                    }
                  ],
                  initialValue: officialGazetteSampleFileList
                })(
                  <Uploader
                    key="OfficialGazetteSample"
                    // accept=".doc,.docx,.pdf"
                    accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                    buttonClassName="default"
                    fileList={officialGazetteSampleFileList}
                    onSuccess={(uploadedFile) =>
                      onFileUploadSuccess('OfficialGazetteSample', uploadedFile)
                    }
                    onRemove={(uploadedFile) => onFileRemove('OfficialGazetteSample', uploadedFile)}
                    text={props.commonLanguage.btnSelectUpload}
                  />
                )}
              </Form.Item>
              <Form.Item label={props.data.activityCertificate} className="mb-0">
                {getFieldDecorator('ActivityCertificate', {
                  validateTrigger: ['onSuccess', 'onRemove', 'onChange'],
                  rules: [
                    {
                      validator: validateActivityCertificate
                    }
                  ],
                  initialValue: activityCertificateFileList
                })(
                  <Uploader
                    key="ActivityCertificate"
                    // accept=".doc,.docx,.pdf"
                    accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                    buttonClassName="default"
                    fileList={activityCertificateFileList}
                    onSuccess={(uploadedFile) =>
                      onFileUploadSuccess('ActivityCertificate', uploadedFile)
                    }
                    onRemove={(uploadedFile) => onFileRemove('ActivityCertificate', uploadedFile)}
                    text={props.commonLanguage.btnSelectUpload}
                  />
                )}
              </Form.Item>
              <Form.Item label={props.data.circularofSignature} className="mb-0">
                {getFieldDecorator('CircularofSignature', {
                  validateTrigger: ['onSuccess', 'onRemove', 'onChange'],
                  rules: [
                    {
                      validator: validateCircularofSignature
                    }
                  ],
                  initialValue: circularofSignatureFileList
                })(
                  <Uploader
                    key="CircularofSignature"
                    // accept=".doc,.docx,.pdf"
                    accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                    buttonClassName="default"
                    fileList={circularofSignatureFileList}
                    onSuccess={(uploadedFile) =>
                      onFileUploadSuccess('CircularofSignature', uploadedFile)
                    }
                    onRemove={(uploadedFile) => onFileRemove('CircularofSignature', uploadedFile)}
                    text={props.commonLanguage.btnSelectUpload}
                  />
                )}
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className={classnames(styles.buttonContainer)}>
            <OneClickButton onClick={handleSubmit} type="primary" form="companyInfoForm">
              {props.data.btnSave}
            </OneClickButton>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.language.dashboard.profile.kyc,
    commonLanguage: state.language.common,
    regularUser: state.regularUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetRegularUser: () => dispatch(GetRegularUser())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(CompanyInfo));
