import { all } from 'redux-saga/effects';
import authenticationSagas from './Authentication/saga';
import regularUserSagas from './RegularUser/saga';
import bulletinSagas from './Bulletin/saga';
import notificationSagas from './Notification/saga';
import projectDetailSagas from './ProjectDetail/saga';
import dashboardSagas from './Dashboard/saga';
import projectWizardSagas from './ProjectWizard/saga';
import projectsSagas from './Projects/saga';
import mkkSicilNoSagas from './MkkSicilNo/saga';
import geoAddressSagas from './GeoAddress/saga';
import userDemographics from './UserDemographics/saga';
import parametersSaga from './Parameters/saga';
import logSaga from './Log/saga';

export default function* rootSaga(getState) {
  yield all([
    authenticationSagas(),
    regularUserSagas(),
    bulletinSagas(),
    notificationSagas(),
    projectDetailSagas(),
    dashboardSagas(),
    projectWizardSagas(),
    projectsSagas(),
    mkkSicilNoSagas(),
    geoAddressSagas(),
    userDemographics(),
    parametersSaga(),
    logSaga()
  ]);
}
