import ReactPixel from 'react-facebook-pixel';
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: process.env.REACT_APP_IS_DEBUG_MODE.toLowerCase() === 'true' // enable logs
};

export const initPixel = () => {
  ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, null, options);
};

export const logPageView = () => {
  ReactPixel.pageView();
};

export const trackContent = (data) => {
  ReactPixel.track('ViewContent', data);
};
