import {
  PUT_MKK_SCL_NUMBER,
  PUT_MKK_SCL_NUMBER_SUCCESS,
  PUT_MKK_SCL_NUMBER_ERROR
} from '../actions';

export const PutMkkSclNumber = (payload) => {
  return {
    type: PUT_MKK_SCL_NUMBER,
    payload
  };
};

export const PutMkkSclNumberSuccess = (payload) => {
  return {
    type: PUT_MKK_SCL_NUMBER_SUCCESS,
    payload
  };
};

export const PutMkkSclNumberError = (payload) => {
  return {
    type: PUT_MKK_SCL_NUMBER_ERROR,
    payload
  };
};
