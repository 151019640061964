import { LOG } from '../actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { commonServiceModule } from '../../services/modules/commonServiceModule';
import { LogSuccess, LogError } from './actions';
import { loadError, loadResponse } from '../../helpers/http/response';

export default function* rootSaga() {
  yield all([fork(watchLog)]);
}

function* watchLog() {
  yield takeEvery(LOG, log);
}

function* log(action) {
  try {
    const response = yield call(logAsync, action);
    const payload = loadResponse(action.payload, response);
    yield put(LogSuccess(payload));
  } catch (error) {
    const errorPayload = loadError(action.payload, error);
    yield put(LogError(errorPayload));
  }
}

const logAsync = async (action) => {
  return commonServiceModule.log(action.payload);
};
