/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { Button, Menu, Drawer, Affix } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { createNavLink, createSubNavLink } from '../../../helpers/dashboardNavigation';
import { faBell, faHandHoldingHeart, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import styles from './styles.module.scss';
import { userServiceModule } from '../../../services/modules/userServiceModule';

class DashboardSideBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: '',
      visible: false,
      sidebarStatus: false
    };

    this.navItems = [
      {
        key: 'profile',
        to: '/dashboard/profile',
        label: 'profileInfo',
        icon: { icon: faUserAlt, className: 'dashboard-icon' },
        onClick: () => {
          this.hideDrawer();
        }
      },
      {
        key: 'messages',
        to: '/dashboard/messages',
        label: 'messages',
        icon: { icon: faBell, className: 'dashboard-icon' },
        onClick: () => {
          this.hideDrawer();
        }
      },
      {
        key: 'portfolio',
        to: '/dashboard/portfolio',
        label: 'portfolio',
        icon: { icon: faHandHoldingHeart, className: 'dashboard-icon' },
        onClick: () => {
          this.hideDrawer();
        }
      },
      {
        key: 'projects',
        to: '/dashboard/projects',
        label: 'projects',
        icon: { type: 'project', theme: 'filled', className: 'dashboard-icon' },
        onClick: () => {
          this.hideDrawer();
        }
      },
      {
        key: 'favorites',
        to: '/dashboard/favorites',
        label: 'favorites',
        icon: { type: 'star', theme: 'filled', className: 'dashboard-icon' },
        onClick: () => {
          this.hideDrawer();
        }
      },
      {
        key: 'logout',
        to: '/',
        label: 'logout',
        icon: { type: 'logout', className: 'dashboard-icon' },
        onClick: () => {
          this.hideDrawer();
          userServiceModule.logoutUser();
        }
      }
    ];

    this.subNavItems = [
      {
        key: 'about',
        to: '/about',
        label: 'about'
      },
      {
        key: 'services',
        to: '/services',
        label: 'services'
      },
      {
        key: 'faq',
        to: '/faq',
        label: 'faq'
      },
      {
        key: 'contact',
        to: '/contact',
        label: 'contact'
      },
      {
        key: 'announcements',
        to: '/announcements',
        label: 'announcements'
      },
      {
        key: 'operations',
        to: '/operations',
        label: 'operations'
      },
      {
        key: 'informationSociety',
        to: '/information-society',
        label: 'informationSociety'
      }
    ];
  }

  componentDidMount() {
    this.setState(() => ({
      active: this.props.active
    }));
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      this.setState(() => ({
        active: this.props.active
      }));
    }
  }

  reverseVisibility = () => {
    this.setState((state) => ({
      visible: !state.visible
    }));
  };

  getSidebarMenu = (classname) => {
    return (
      <Menu mode="inline" className={classname} selectedKeys={[this.state.active]}>
        {this.navItems.map((n) =>
          createNavLink({
            ...n,
            active: this.state.active,
            label: this.props.data[n.label],
            onClick: n.onClick
          })
        )}

        <Menu.Divider className={styles.firstDivider} />

        {this.subNavItems.map((n) =>
          createSubNavLink({
            ...n,
            label: this.props.data[n.label]
          })
        )}
      </Menu>
    );
  };

  showDrawer = () => {
    this.setState({ visible: true });
  };

  hideDrawer = () => {
    this.setState({ visible: false });
  };

  render() {
    return (
      <React.Fragment>
        <Button
          icon="menu"
          className={classnames(styles.toggleSidebar, {
            [styles.visible]: !!this.state.visible
          })}
          onClick={this.showDrawer}
        />
        <Col className={classnames(styles.navigation)}>
          <Row className={styles.container}>
            <Col>
              {this.getSidebarMenu(styles.menu)}
              <Drawer
                visible={this.state.visible}
                placement="left"
                onClose={this.hideDrawer}
                closable={false}
                width={230}
              >
                <Col className={classnames(styles.navigation)}>
                  <Row className={styles.container}>{this.getSidebarMenu(styles.drawerMenu)}</Row>
                </Col>
              </Drawer>
            </Col>
          </Row>
        </Col>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.language.dashboard.sidebar
  };
};

export default connect(mapStateToProps)(DashboardSideBar);

