import axios from 'axios';
import store from 'store2';
import { getFileNameFromContentDisposition } from '../helpers/responseHeaderHelper';

let cancellationTokenSource;
let redirecting = false;

renewGlobalCancellationToken();

export function renewGlobalCancellationToken() {
  cancellationTokenSource = axios.CancelToken.source();
}

export function killGlobalCancellationToken() {
  cancellationTokenSource.cancel();
}

class Service {
  constructor(authToken = null) {
    this.authInfo = authToken;
    let service = axios.create({
      baseURL: process.env.REACT_APP_API_ENDPOINT,
      responseType: 'json',
      cancelToken: cancellationTokenSource.token,
      timeout: 300000
    });

    service.interceptors.request.use(this.handleAuthorizationHeader);
    service.interceptors.request.use(this.handleCustomHeaders);
    service.interceptors.request.use(this.handleCancellation);
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;

    let logService = axios.create({
      baseURL: process.env.REACT_APP_LOG_API_ENDPOINT,
      responseType: 'json',
      cancelToken: cancellationTokenSource.token,
      timeout: 300000
    });

    logService.interceptors.response.use(this.handleSuccess, this.handleError);
    this.logService = logService;
  }

  handleAuthorizationHeader = (config) => {
    if (!!this.authInfo?.token) {
      config.headers.Authorization = `Bearer ${this.authInfo?.token}`;
    }
    return config;
  };

  handleCustomHeaders = (config) => {
    config.headers = {
      'X-ApiVersion': '1'
    };
    return config;
  };

  handleCancellation = (config) => {
    if (!!redirecting) {
      cancellationTokenSource.cancel();
    }
    return config;
  };

  handleSuccess(response) {
    return response;
  }

  handleError = (error) => {
    if (error?.code === 'ECONNABORTED') {
      return Promise.reject({ timeoutError: true, extendedErrorCode: 'ECONNABORTED' });
    }

    const extendedErrorCode = error?.response?.data?.extendedErrorCode;
    const errorStatus = error?.response?.status;
    if (!!errorStatus) {
      switch (errorStatus) {
        case 401:
          if (!!extendedErrorCode) {
            switch (extendedErrorCode) {
              case 'Core_Authentication_TokenExpired':
                return Promise.reject({ needsRefreshing: true });
              case 'Core_Authentication_CorruptedSignKey':
              case 'Domain_Authentication':
              case 'Domain_SharedKernel_User_AlreadyLoggedOut':
              case 'Domain_SharedKernel_User_NotFound':
              case 'Domain_SharedKernel_User_UserQueryFailed':
                return Promise.reject({ needsLogin: true });
              default:
                break;
            }
          } else {
            return Promise.reject({ needsLogin: true });
          }
          break;
        case 400:
        case 403:
          if (!!extendedErrorCode) {
            switch (extendedErrorCode) {
              case 'Api_RestCallsPrevented':
                if (!redirecting) {
                  this.redirectTo('/rest-call-close');
                } else {
                  break;
                }
              case 'Core_Authentication_CorruptedSignKey':
              case 'Domain_Authentication':
              case 'Domain_SharedKernel_User_AlreadyLoggedOut':
              case 'Domain_SharedKernel_User_NotFound':
              case 'Domain_SharedKernel_User_UserQueryFailed':
                return Promise.reject({ needsLogin: true });
            }
          }
          break;
        default:
          break;
      }
    }

    const errorData = error?.response?.data ?? 'Undefined Error';
    return Promise.reject(errorData);
  };

  redirectTo = (path) => {
    redirecting = true;
    store.remove('authentication');

    if (document.location !== path) {
      window.location.assign(path);
    } else {
      window.location.reload();
    }
    redirecting = false;
  };

  get(path) {
    return this.service.get(path).then((response) => response.data);
  }

  getBlob(path) {
    return this.service
      .request({
        method: 'GET',
        url: path,
        responseType: 'blob'
      })
      .then((response) => {
        {
          const fileName = getFileNameFromContentDisposition(
            response.headers['content-disposition']
          );

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // IE variant
            window.navigator.msSaveOrOpenBlob(
              new Blob([response.data], { type: `${response.headers['content-Type']}` }),
              fileName
            );
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: `${response.headers['content-Type']}` })
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            link.click();

            setTimeout(function () {
              window.URL.revokeObjectURL(url);
            }, 0);
          }

          return response.data;
        }
      });
  }

  patch(path, payload) {
    return this.service
      .request({
        method: 'PATCH',
        url: path,
        responseType: 'json',
        data: payload
      })
      .then((response) => response.data);
  }

  put(path, payload) {
    return this.service
      .request({
        method: 'PUT',
        url: path,
        responseType: 'json',
        data: payload
      })
      .then((response) => response.data);
  }

  post(path, payload) {
    return this.service
      .request({
        method: 'POST',
        url: path,
        responseType: 'json',
        data: payload
      })
      .then((response) => response.data);
  }

  delete(path) {
    return this.service
      .request({
        method: 'DELETE',
        url: path,
        responseType: 'json'
      })
      .then((response) => response.data);
  }

  upload(path, payload, onUploadProgress) {
    return this.service
      .request({
        method: 'POST',
        url: path,
        responseType: 'json',
        data: payload,
        onUploadProgress
      })
      .then((response) => response.data);
  }

  log(path, payload) {
    return this.logService
      .request({
        method: 'POST',
        url: path,
        responseType: 'json',
        data: payload
      })
      .then((response) => response.data);
  }
}

export default Service;
