import store from 'store2';
import {
  AUTHENTICATE_USER,
  AUTHENTICATE_USER_SUCCESS,
  AUTHENTICATE_USER_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_ERROR
} from '../actions';

const authenticationInitialState = null;

const initialState = {
  authenticationInfo: authenticationInitialState
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE_USER:
      return state;
    case AUTHENTICATE_USER_SUCCESS:
      store.set('authentication', action.payload.response);
      return { ...state, authenticationInfo: action.payload };
    case AUTHENTICATE_USER_ERROR:
      return { ...state, authenticationInfo: action.payload };
    case LOGOUT_USER:
      return state;
    case LOGOUT_USER_SUCCESS:
      return { ...state, authenticationInfo: authenticationInitialState };
    case LOGOUT_USER_ERROR:
      return {
        ...state,
        authenticationInfo: { ...state.authenticationInfo, error: action.payload.error }
      };
    default:
      return state;
  }
};
