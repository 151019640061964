import {
  GET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE,
  GET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE_SUCCESS,
  GET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE_ERROR,
  RESET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE,
  GET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT,
  GET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT_SUCCESS,
  GET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT_ERROR,
  RESET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT,
  GET_USER_PROJECTS,
  GET_USER_PROJECTS_SUCCESS,
  GET_USER_PROJECTS_ERROR,
  GET_USER_FAVORITE_PROJECTS,
  GET_USER_FAVORITE_PROJECTS_SUCCESS,
  GET_USER_FAVORITE_PROJECTS_ERROR,
  USER_FAVORITE_PROJECT,
  USER_FAVORITE_PROJECT_SUCCESS,
  USER_FAVORITE_PROJECT_ERROR,
  RESET_USER_FAVORITE_PROJECT
} from '../actions';

export const GetIndividualQualificationStatementDocumentTemplate = (payload) => {
  return {
    type: GET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE,
    payload
  };
};

export const GetIndividualQualificationStatementDocumentTemplateSuccess = (payload) => {
  return {
    type: GET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE_SUCCESS,
    payload
  };
};

export const GetIndividualQualificationStatementDocumentTemplateError = (payload) => {
  return {
    type: GET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE_ERROR,
    payload
  };
};

export const ResetIndividualQualificationStatementDocumentTemplate = () => {
  return {
    type: RESET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE
  };
};

export const GetRegularUserInvestmentLimit = (payload) => {
  return {
    type: GET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT,
    payload
  };
};

export const GetRegularUserInvestmentLimitSuccess = (payload) => {
  return {
    type: GET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT_SUCCESS,
    payload
  };
};

export const GetRegularUserInvestmentLimitError = (payload) => {
  return {
    type: GET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT_ERROR,
    payload
  };
};

export const ResetRegularUserInvestmentLimit = () => {
  return {
    type: RESET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT
  };
};

export const GetUserProjects = (payload) => {
  return {
    type: GET_USER_PROJECTS,
    payload
  };
};

export const GetUserProjectsSuccess = (payload) => {
  return {
    type: GET_USER_PROJECTS_SUCCESS,
    payload
  };
};

export const GetUserProjectsError = (payload) => {
  return {
    type: GET_USER_PROJECTS_ERROR,
    payload
  };
};

export const GetUserFavoriteProjects = (payload) => {
  return {
    type: GET_USER_FAVORITE_PROJECTS,
    payload
  };
};

export const GetUserFavoriteProjectsSuccess = (payload) => {
  return {
    type: GET_USER_FAVORITE_PROJECTS_SUCCESS,
    payload
  };
};

export const GetUserFavoriteProjectsError = (payload) => {
  return {
    type: GET_USER_FAVORITE_PROJECTS_ERROR,
    payload
  };
};

export const UserFavoriteProject = (payload) => {
  return {
    type: USER_FAVORITE_PROJECT,
    payload
  };
};

export const UserFavoriteProjectSuccess = (payload) => {
  return {
    type: USER_FAVORITE_PROJECT_SUCCESS,
    payload
  };
};

export const UserFavoriteProjectError = (payload) => {
  return {
    type: USER_FAVORITE_PROJECT_ERROR,
    payload
  };
};

export const ResetUserFavoriteProject = () => {
  return {
    type: RESET_USER_FAVORITE_PROJECT
  };
};
