import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Input, Form, DatePicker } from 'antd';
import moment from 'moment';
import styles from './styles.module.scss';

const VerifiedIndividual = (props) => {
  const [size, SetSize] = useState([12, 6, 3]);

  useEffect(() => {
    if (!props.regularUser.birthDateVerified) {
      SetSize([12, 12, 4]);
    } else {
      SetSize([12, 6, 3]);
    }
  }, [props.regularUser?.birthDateVerified]);

  return (
    <div>
      <Form>
        <h3>{props.data.individualIdentityVerifiedLabel}</h3>
        <Row>
          <Col xs={size[0]} sm={size[1]} md={size[2]}>
            <Form.Item label={props.data.name} className="mb-0">
              <Input key="name" value={props.regularUser?.name} disabled />
            </Form.Item>
          </Col>
          <Col xs={size[0]} sm={size[1]} md={size[2]}>
            <Form.Item label={props.data.surname} className="mb-0">
              <Input key="surname" value={props.regularUser?.surname} disabled />
            </Form.Item>
          </Col>
          <Col xs={size[0]} sm={size[1]} md={size[2]}>
            <Form.Item
              label={
                props.regularUser?.identityType === 'TCKN'
                  ? props.data.idNumberLabel
                  : props.data.foreignIdentityNumber
              }
              className="mb-0"
            >
              <Input key="idNumber" value={props.regularUser?.identityNumber} disabled />
            </Form.Item>
          </Col>
          {!!props.regularUser?.birthDateVerified && (
            <Col xs={size[0]} sm={size[1]} md={size[2]}>
              <Form.Item label={props.data.birthDate} className="mb-0">
                <DatePicker
                  disabled={true}
                  className={styles.birthDate}
                  format="DD/MM/YYYY"
                  value={moment(props.regularUser?.birthDate, 'YYYY-MM-DD')}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.language.dashboard.profile.kyc,
    regularUser: state.regularUser
  };
};

export default connect(mapStateToProps)(VerifiedIndividual);
