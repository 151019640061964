import React, { useEffect, useState } from 'react';
import { commonServiceModule } from '../../../services/modules/commonServiceModule';
import { Button, Icon } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { BroadcastChannel } from 'broadcast-channel';
import { notify } from '../../../shared/notify';
import { connect } from 'react-redux';

let filePreviewWindow = null;

const FileDownload = (props) => {
  const [isApiProvided, SetIsApiProvided] = useState(false);
  const [broadcastChannelId] = useState(uuidv4());
  const [initialized, SetInitialized] = useState(false);
  const [blobData, SetBlobData] = useState(null);
  const [fileDownloadBroadcastChannel, SetFileDownloadBroadcastChannel] = useState(null);

  useEffect(() => {
    SetFileDownloadBroadcastChannel(new BroadcastChannel(`file${broadcastChannelId}`));
    let apiEndpoint = !!process.env.REACT_APP_API_ENDPOINT.startsWith('//')
      ? `https:${process.env.REACT_APP_API_ENDPOINT}`
      : process.env.REACT_APP_API_ENDPOINT;

    if (!!checkUrlValidity(props.url) && !!checkUrlValidity(apiEndpoint)) {
      const isApiProvided = new URL(props.url).host === new URL(apiEndpoint).host;
      SetIsApiProvided(isApiProvided);
    }
  }, []);

  useEffect(() => {
    if (!!fileDownloadBroadcastChannel) {
      fileDownloadBroadcastChannel.onmessage = onfileDownloadBroadcastMessageReceived;
    }
  }, [fileDownloadBroadcastChannel]);

  useEffect(() => {
    if (!!initialized && !!blobData) {
      const blobMessage = {
        sender: 'parent',
        receiver: 'child',
        data: {
          blob: blobData,
          code: 'blob',
          id: broadcastChannelId
        }
      };
      SetBlobData(null);
      SetInitialized(false);
      fileDownloadBroadcastChannel.postMessage(blobMessage);
    }
  }, [initialized, blobData]);

  const onfileDownloadBroadcastMessageReceived = (message) => {
    if (!!message && message.receiver === 'parent') {
      processPaymentBroadcastReceivedMessage(message);
    }
  };

  const processPaymentBroadcastReceivedMessage = (message) => {
    if (message.sender === 'child') {
      if (message.data.code === 'initialized') {
        SetInitialized(true);
        return;
      }
      if (message.data.code === 'killyourself') {
        window.close();
        return;
      }
    }
  };

  const checkUrlValidity = (url) => {
    let isValid = true;
    try {
      new URL(url);
    } catch {
      isValid = false;
    }

    return isValid;
  };

  const postInitializedMessage = () => {
    const initMessage = {
      sender: 'parent',
      receiver: 'child',
      data: {
        code: 'initialized'
      }
    };

    fileDownloadBroadcastChannel.postMessage(initMessage);
  };

  const downloadFile = (e) => {
    e.preventDefault();

    filePreviewWindow = window.open(
      `${window.location.origin}/files?file=${broadcastChannelId}`,
      '_blank'
    );

    commonServiceModule
      .downloadFile(props.url)
      .then((response) => {
        if (!!response.willItOpen) {
          SetBlobData(response.data);
          postInitializedMessage(response);
        } else {
          filePreviewWindow.close();
        }
      })
      .catch(() => {
        filePreviewWindow.close();
        notify('Error', props.data.documentsDownloadError);
      });
  };

  const CustomLink = () => {
    return !!isApiProvided ? (
      <a {...props} rel="noopener noreferrer" href="javascript:void(0);" onClick={downloadFile}>
        {props.text}
      </a>
    ) : (
      <a {...props} rel="noopener noreferrer" href={props.url} target="_blank" download>
        {props.text}
      </a>
    );
  };

  const CustomButton = () => {
    return (
      <Button {...props}>
        <Icon type="download" /> <CustomLink />
      </Button>
    );
  };

  return props.type === 'link' ? <CustomLink /> : <CustomButton />;
};

const mapStateToProps = (state) => {
  return {
    language: state.language.projectDetail.documents
  };
};

export default connect(mapStateToProps)(FileDownload);

