import {
  GET_REGULAR_USER,
  GET_REGULAR_USER_SUCCESS,
  GET_REGULAR_USER_ERROR,
  RESET_REGULAR_USER
} from '../actions';

export const GetRegularUser = (payload) => {
  return {
    type: GET_REGULAR_USER,
    payload: payload
  };
};

export const GetRegularUserSuccess = (payload) => {
  return {
    type: GET_REGULAR_USER_SUCCESS,
    payload: payload
  };
};

export const GetRegularUserError = (payload) => {
  return {
    type: GET_REGULAR_USER_ERROR,
    payload: payload
  };
};

export const ResetRegularUser = () => {
  return {
    type: RESET_REGULAR_USER
  };
};
