import {
  UPDATE_PROJECT_WIZARD_FUNDING_INFO_SUCCESS,
  UPDATE_PROJECT_WIZARD_FUNDING_INFO_FAIL,
  RESET_PROJECT_WIZARD_FUNDING_INFO,
  UPSERT_PROJECT_WIZARD_DOCUMENTS_PROJECT_SUCCESS,
  UPSERT_PROJECT_WIZARD_DOCUMENTS_PROJECT_FAIL,
  CREATE_PROJECT_WIZARD_STATIC_DOCUMENT_SUCCESS,
  CREATE_PROJECT_WIZARD_STATIC_DOCUMENT_FAIL,
  GET_PROJECT_WIZARD_ANNOUNCEMENT_URL_SUCCESS,
  GET_PROJECT_WIZARD_ANNOUNCEMENT_URL_FAIL,
  SET_PROJECT_WIZARD_REFERENCE_CODE,
  RESET_PROJECT_WIZARD_REFERENCE_CODE,
  SET_IS_API_CALL_COMPLETED,
  RESET_IS_API_CALL_COMPLETED,
  GET_PROJECT_WIZARD_IMAGES_SUCCESS,
  GET_PROJECT_WIZARD_IMAGES_ERROR,
  RESET_PROJECT_WIZARD_IMAGES,
  UPSERT_PROJECT_WIZARD_IMAGES_SUCCESS,
  UPSERT_PROJECT_WIZARD_IMAGES_ERROR,
  RESET_PROJECT_WIZARD_UPSERTED_IMAGES
} from '../actions';

const fundingInfoInitialState = null;
const projectDocumentsInitialState = null;
const staticDocumentInitialState = null;
const announcementUrlInitialState = null;
const referenceIdForPreviewInitialState = null;
const isApiCallCompletedInitialState = null;
const projectImagesInitialState = null;
const projectUpsertedImagesInitialState = null;

const initialState = {
  fundingInfo: fundingInfoInitialState,
  projectDocuments: projectDocumentsInitialState,
  staticDocument: staticDocumentInitialState,
  announcementUrl: announcementUrlInitialState,
  referenceIdForPreview: referenceIdForPreviewInitialState,
  isApiCallCompleted: isApiCallCompletedInitialState,
  projectImages: projectImagesInitialState,
  upsertedImage: projectUpsertedImagesInitialState
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROJECT_WIZARD_FUNDING_INFO_SUCCESS:
    case UPDATE_PROJECT_WIZARD_FUNDING_INFO_FAIL:
      return { ...state, fundingInfo: action.payload };
    case RESET_PROJECT_WIZARD_FUNDING_INFO:
      return { ...state, fundingInfo: fundingInfoInitialState };

    case UPSERT_PROJECT_WIZARD_DOCUMENTS_PROJECT_SUCCESS:
    case UPSERT_PROJECT_WIZARD_DOCUMENTS_PROJECT_FAIL:
      return { ...state, documents: action.payload };

    case CREATE_PROJECT_WIZARD_STATIC_DOCUMENT_SUCCESS:
    case CREATE_PROJECT_WIZARD_STATIC_DOCUMENT_FAIL:
      return { ...state, staticDocument: action.payload };

    case GET_PROJECT_WIZARD_ANNOUNCEMENT_URL_SUCCESS:
    case GET_PROJECT_WIZARD_ANNOUNCEMENT_URL_FAIL:
      return { ...state, announcementUrl: action.payload };

    case GET_PROJECT_WIZARD_IMAGES_SUCCESS:
    case GET_PROJECT_WIZARD_IMAGES_ERROR:
      return { ...state, projectImages: action.payload };
    case RESET_PROJECT_WIZARD_IMAGES:
      return { ...state, projectImages: projectImagesInitialState };

    case UPSERT_PROJECT_WIZARD_IMAGES_SUCCESS:
    case UPSERT_PROJECT_WIZARD_IMAGES_ERROR:
      return { ...state, upsertedImage: action.payload };
    case RESET_PROJECT_WIZARD_UPSERTED_IMAGES:
      return { ...state, upsertedImage: projectUpsertedImagesInitialState };

    case SET_PROJECT_WIZARD_REFERENCE_CODE:
      return { ...state, referenceIdForPreview: action.payload };
    case RESET_PROJECT_WIZARD_REFERENCE_CODE:
      return { ...state, referenceIdForPreview: referenceIdForPreviewInitialState };
    case SET_IS_API_CALL_COMPLETED:
      return { ...state, isApiCallCompleted: action.payload };
    case RESET_IS_API_CALL_COMPLETED:
      return { ...state, isApiCallCompleted: isApiCallCompletedInitialState };

    default:
      return state;
  }
};

