import { Col, Container, Row } from 'react-bootstrap';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import styles from './styles.module.scss';

class CookieConsent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: !(
        !!localStorage.getItem('cookiePolicyAccepted') &&
        localStorage.getItem('cookiePolicyAccepted') == 'true'
      )
    };
  }

  render() {
    const { language } = this.props;
    const delimiter = '{LINK}';
    const replaced = (
      <Link key={'link'} to="/cookie-policy">
        {language.anchorTitle}
      </Link>
    );
    const message = language.message.split(new RegExp(`(${delimiter})`, 'i'));

    message.map((value, index) => {
      if (value === delimiter) {
        message[index] = replaced;
      }
    });

    return (
      <React.Fragment>
        {!!this.state.visible && (
          <div className={styles.container}>
            <div className={styles.wrapper}>
              <div className={styles.content}>
                <Container>
                  <Row className={styles.flex}>
                    <Col className="col-xs-12 col-sm-10 col-md-10 col-lg-10 ml-0 pl-0">
                      <p>{!!message && message}</p>
                    </Col>
                    <Col
                      className={classnames(
                        'd-flex justify-content-center align-items-center mr-0 pr-0',
                        styles.buttonContainer
                      )}
                    >
                      <Button
                        onClick={() => {
                          localStorage.setItem('cookiePolicyAccepted', 'true');
                          this.setState({ visible: false });
                        }}
                        className={styles.button}
                      >
                        {language.acceptButtonText}
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language.cookieConsent
  };
};

export default connect(mapStateToProps)(CookieConsent);
