import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import { loadError, loadResponse, loadSpfResponse } from '../../helpers/http/response';
import { projectServiceModule } from '../../services/modules/projectServiceModule';
import {
  GET_PROJECT_TABLES_INVESTMENT_DETAILS,
  GET_PROJECT_TABLES_INVESTOR_DETAILS,
  GET_PROJECT_FUNDING_INFO,
  GET_PROJECT_DAILY_SPREAD,
  GET_PROJECT_COMPLETION_SUMMARY,
  GET_PROJECT_INFO,
  GET_PROJECT_DETAILS,
  GET_PROJECT_COMPANY,
  GET_PROJECT_DOCUMENTS,
  GET_PROJECT_IMAGES,
  GET_PROJECT_FAQS,
  GET_PROJECT_UPDATES,
  GET_PROJECT_PARENT_COMMENTS,
  GET_PROJECT_CHILD_COMMENTS,
  GET_PROJECT_TEAM_MEMBERS,
  GET_PROJECT_PARTICIPANTS
} from '../actions';
import {
  GetProjectTablesInvestmentDetailsSuccess,
  GetProjectTablesInvestmentDetailsError,
  GetProjectTablesInvestorDetailsSuccess,
  GetProjectTablesInvestorDetailsError,
  GetProjectFundingInfoSuccess,
  GetProjectFundingInfoError,
  GetProjectDailySpreadSuccess,
  GetProjectDailySpreadError,
  GetProjectCompletionSummarySuccess,
  GetProjectCompletionSummaryError,
  GetProjectInfoSuccess,
  GetProjectInfoError,
  GetProjectCompany as GetProjectCompanyAction,
  GetProjectDetailsSuccess,
  GetProjectDetailsError,
  GetProjectCompanySuccess,
  GetProjectCompanyError,
  GetProjectDocumentsSuccess,
  GetProjectDocumentsError,
  GetProjectImagesSuccess,
  GetProjectImagesError,
  GetProjectFaqsSuccess,
  GetProjectFaqsError,
  GetProjectUpdatesSuccess,
  GetProjectUpdatesError,
  GetProjectParentCommentsSuccess,
  GetProjectParentCommentsError,
  GetProjectChildCommentsSuccess,
  GetProjectChildCommentsError,
  GetProjectTeamMembersSuccess,
  GetProjectTeamMembersError,
  GetProjectParticipantsSuccess,
  GetProjectParticipantsError
} from './actions';

export default function* rootSaga() {
  yield all([
    fork(watchGetProjectTablesInvestorDetails),
    fork(watchGetProjectTablesInvestmentDetails),
    fork(watchGetFundingInfo),
    fork(watchGetProjectDailySpread),
    fork(watchGetProjectCompletionSummary),
    fork(watchGetProjectInfo),
    fork(watchGetProjectDetails),
    fork(watchGetProjectCompany),
    fork(watchGetProjectDocuments),
    fork(watchGetProjectImages),
    fork(watchGetProjectFaqs),
    fork(watchGetProjectUpdates),
    fork(watchGetProjectParentComments),
    fork(watchGetProjectChildComments),
    fork(watchGetProjectTeamMembers),
    fork(watchGetProjectParticipants)
  ]);
}

function* watchGetProjectTablesInvestmentDetails() {
  yield takeLeading(GET_PROJECT_TABLES_INVESTMENT_DETAILS, GetProjectTablesInvestmentDetails);
}

function* watchGetProjectTablesInvestorDetails() {
  yield takeLeading(GET_PROJECT_TABLES_INVESTOR_DETAILS, GetProjectTablesInvestorDetails);
}

function* watchGetFundingInfo() {
  yield takeLeading(GET_PROJECT_FUNDING_INFO, GetFundingInfo);
}

function* watchGetProjectDailySpread() {
  yield takeLeading(GET_PROJECT_DAILY_SPREAD, GetProjectDailySpread);
}

function* watchGetProjectCompletionSummary() {
  yield takeLeading(GET_PROJECT_COMPLETION_SUMMARY, GetProjectCompletionSummary);
}

function* watchGetProjectInfo() {
  yield takeLeading(GET_PROJECT_INFO, GetProjectInfo);
}

function* watchGetProjectDetails() {
  yield takeLeading(GET_PROJECT_DETAILS, GetProjectDetails);
}

function* watchGetProjectCompany() {
  yield takeLeading(GET_PROJECT_COMPANY, GetProjectCompany);
}

function* watchGetProjectDocuments() {
  yield takeLeading(GET_PROJECT_DOCUMENTS, GetProjectDocuments);
}

function* watchGetProjectImages() {
  yield takeLeading(GET_PROJECT_IMAGES, GetProjectImages);
}

function* watchGetProjectFaqs() {
  yield takeLeading(GET_PROJECT_FAQS, GetProjectFaqs);
}

function* watchGetProjectUpdates() {
  yield takeLeading(GET_PROJECT_UPDATES, GetProjectUpdates);
}

function* watchGetProjectParentComments() {
  yield takeLeading(GET_PROJECT_PARENT_COMMENTS, GetProjectParentComments);
}

function* watchGetProjectChildComments() {
  yield takeLeading(GET_PROJECT_CHILD_COMMENTS, GetProjectChildComments);
}

function* watchGetProjectTeamMembers() {
  yield takeLeading(GET_PROJECT_TEAM_MEMBERS, GetProjectTeamMembers);
}

function* watchGetProjectParticipants() {
  yield takeLeading(GET_PROJECT_PARTICIPANTS, GetProjectParticipants);
}

function* GetProjectTablesInvestmentDetails(action) {
  try {
    const response = yield call(GetProjectTablesInvestmentDetailsAsync, action);
    const payload = loadSpfResponse(action.payload, response);
    yield put(GetProjectTablesInvestmentDetailsSuccess(payload));
  } catch (error) {
    const errorPayload = {
      payload: action.payload,
      error
    };
    yield put(GetProjectTablesInvestmentDetailsError(errorPayload));
  }
}

function* GetProjectTablesInvestorDetails(action) {
  try {
    const response = yield call(GetProjectTablesInvestorDetailsAsync, action);
    const payload = loadSpfResponse(action.payload, response);
    yield put(GetProjectTablesInvestorDetailsSuccess(payload));
  } catch (error) {
    const errorPayload = {
      payload: action.payload,
      error
    };
    yield put(GetProjectTablesInvestorDetailsError(errorPayload));
  }
}

function* GetFundingInfo(action) {
  try {
    const response = yield call(GetFundingInfoAsync, action);
    yield put(GetProjectFundingInfoSuccess(response));
  } catch (error) {
    const errorPayload = {
      payload: action.payload,
      error
    };
    yield put(GetProjectFundingInfoError(errorPayload));
  }
}

function* GetProjectDailySpread(action) {
  try {
    const response = yield call(GetProjectDailySpreadAsync, action);
    const responseData = loadResponse(action.payload, response);
    yield put(GetProjectDailySpreadSuccess(responseData));
  } catch (error) {
    const errorData = {
      payload: action.payload,
      error
    };
    yield put(GetProjectDailySpreadError(errorData));
  }
}

function* GetProjectCompletionSummary(action) {
  try {
    const response = yield call(GetProjectCompletionSummaryAsync, action);
    const responseData = loadResponse(action.payload, response);
    yield put(GetProjectCompletionSummarySuccess(responseData));
  } catch (error) {
    const errorData = {
      payload: action.payload,
      error
    };
    yield put(GetProjectCompletionSummaryError(errorData));
  }
}

function* GetProjectInfo(action) {
  try {
    const response = yield call(GetProjectInfoAsync, action);
    yield put(GetProjectInfoSuccess(response));
    const companyPayload = {
      projectId: action.payload.projectId,
      isPreview: action.payload.isPreview,
      hasCompany: response.hasCompany
    };
    yield put(GetProjectCompanyAction(companyPayload));
  } catch (error) {
    const errorPayload = {
      payload: action.payload,
      error
    };
    yield put(GetProjectInfoError(errorPayload));
  }
}

function* GetProjectDetails(action) {
  try {
    const response = yield call(GetProjectDetailsAsync, action);
    yield put(GetProjectDetailsSuccess(response));
  } catch (error) {
    const errorPayload = {
      payload: action.payload,
      error
    };
    yield put(GetProjectDetailsError(errorPayload));
  }
}

function* GetProjectCompany(action) {
  try {
    const response = yield call(GetProjectCompanyAsync, action);
    yield put(GetProjectCompanySuccess(response));
  } catch (error) {
    const errorPayload = {
      payload: action.payload,
      error
    };
    yield put(GetProjectCompanyError(errorPayload));
  }
}

function* GetProjectDocuments(action) {
  try {
    const response = yield call(GetProjectDocumentsAsync, action);
    yield put(GetProjectDocumentsSuccess(response));
  } catch (error) {
    const errorPayload = {
      payload: action.payload,
      error
    };
    yield put(GetProjectDocumentsError(errorPayload));
  }
}

function* GetProjectImages(action) {
  try {
    const response = yield call(GetProjectImagesAsync, action);
    const responsePayload = loadSpfResponse(action.payload, response);
    yield put(GetProjectImagesSuccess(responsePayload));
  } catch (error) {
    const errorPayload = loadError(action.payload, error);
    yield put(GetProjectImagesError(errorPayload));
  }
}

function* GetProjectFaqs(action) {
  try {
    const response = yield call(GetProjectFaqsAsync, action);
    yield put(GetProjectFaqsSuccess(response));
  } catch (error) {
    const errorPayload = {
      payload: action.payload,
      error
    };
    yield put(GetProjectFaqsError(errorPayload));
  }
}

function* GetProjectUpdates(action) {
  try {
    const response = yield call(GetProjectUpdatesAsync, action);
    const payload = loadSpfResponse(action.payload, response);
    yield put(GetProjectUpdatesSuccess(payload));
  } catch (error) {
    const errorPayload = {
      payload: action.payload,
      error
    };
    yield put(GetProjectUpdatesError(errorPayload));
  }
}

function* GetProjectParentComments(action) {
  try {
    const response = yield call(GetProjectParentCommentsAsync, action);
    const payload = loadSpfResponse(action.payload, response);
    yield put(GetProjectParentCommentsSuccess(payload));
  } catch (error) {
    const errorPayload = {
      payload: action.payload,
      error
    };
    yield put(GetProjectParentCommentsError(errorPayload));
  }
}

function* GetProjectChildComments(action) {
  try {
    const response = yield call(GetProjectChildCommentsAsync, action);
    const payload = loadSpfResponse(action.payload, response);
    yield put(GetProjectChildCommentsSuccess(payload));
  } catch (error) {
    const errorPayload = {
      payload: action.payload,
      error
    };
    yield put(GetProjectChildCommentsError(errorPayload));
  }
}

function* GetProjectTeamMembers(action) {
  try {
    const response = yield call(GetProjectTeamMembersAsync, action);
    const payload = loadSpfResponse(action.payload, response);
    yield put(GetProjectTeamMembersSuccess(payload));
  } catch (error) {
    const errorPayload = {
      payload: action.payload,
      error
    };
    yield put(GetProjectTeamMembersError(errorPayload));
  }
}

function* GetProjectParticipants(action) {
  try {
    const response = yield call(GetProjectParticipantsAsync, action);
    const payload = loadSpfResponse(action.payload, response);
    yield put(GetProjectParticipantsSuccess(payload));
  } catch (error) {
    const errorPayload = {
      payload: action.payload,
      error
    };
    yield put(GetProjectParticipantsError(errorPayload));
  }
}

const GetProjectTablesInvestmentDetailsAsync = (action) => {
  return projectServiceModule.getInvestmentDetails(
    action.payload.projectId,
    action.payload.queryString
  );
};

const GetProjectTablesInvestorDetailsAsync = (action) => {
  return projectServiceModule.getInvestorDetails(
    action.payload.projectId,
    action.payload.queryString
  );
};

const GetFundingInfoAsync = (action) => {
  let promise;
  if (!!action.payload.isPreview) {
    promise = projectServiceModule.getProjectDraftFundingInfo(action.payload.projectId);
  } else {
    promise = projectServiceModule.getProjectFundingInfo(action.payload.projectId);
  }

  return promise;
};

const GetProjectDailySpreadAsync = (action) => {
  return projectServiceModule.getProjectDailySpread(action.payload.projectId);
};

const GetProjectCompletionSummaryAsync = (action) => {
  return projectServiceModule.getProjectCompletionSummary(action.payload.projectId);
};

const GetProjectInfoAsync = (action) => {
  return projectServiceModule.getProjectInfo(action.payload.projectId);
};

const GetProjectDetailsAsync = (action) => {
  let promise;
  if (!!action.payload.isPreview) {
    promise = projectServiceModule.getProjectDraftDetail(action.payload.projectId);
  } else {
    promise = projectServiceModule.getProjectDetail(action.payload.projectId);
  }

  return promise;
};

const GetProjectCompanyAsync = (action) => {
  let promise;
  if (!!action.payload.hasCompany) {
    if (!!action.payload.isPreview) {
      promise = projectServiceModule.getProjectDraftCompany(action.payload.projectId);
    } else {
      promise = projectServiceModule.getProjectCompany(action.payload.projectId);
    }
  } else {
    if (!!action.payload.isPreview) {
      promise = projectServiceModule.getProjectDraftNonCompany(action.payload.projectId);
    } else {
      promise = projectServiceModule.getProjectNonCompany(action.payload.projectId);
    }
  }
  return promise;
};

const GetProjectDocumentsAsync = (action) => {
  return projectServiceModule.getProjectDocuments(action.payload.projectId);
};

const GetProjectImagesAsync = (action) => {
  let promise;
  if (!!action.payload.isPreview) {
    promise = projectServiceModule.getProjectDraftImages(
      action.payload.projectId,
      action.payload.queryString
    );
  } else {
    promise = projectServiceModule.getProjectImages(
      action.payload.projectId,
      action.payload.queryString
    );
  }

  return promise;
};

const GetProjectFaqsAsync = (action) => {
  let promise;
  if (!!action.payload.isPreview) {
    promise = projectServiceModule.getProjectDraftFaqs(action.payload.projectId);
  } else {
    promise = projectServiceModule.getProjectFaqs(action.payload.projectId);
  }

  return promise;
};

const GetProjectUpdatesAsync = (action) => {
  return projectServiceModule.getProjectUpdates(
    action.payload.projectId,
    action.payload.queryString
  );
};

const GetProjectParentCommentsAsync = (action) => {
  return projectServiceModule.getProjectParentComments(
    action.payload.projectId,
    action.payload.queryString
  );
};

const GetProjectChildCommentsAsync = (action) => {
  return projectServiceModule.getProjectChildComments(
    action.payload.projectId,
    action.payload.parentCommentId,
    action.payload.queryString
  );
};

const GetProjectTeamMembersAsync = (action) => {
  let promise;
  if (!!action.payload.isPreview) {
    promise = projectServiceModule.getProjectDraftTeamMembers(
      action.payload.projectId,
      action.payload.queryString
    );
  } else {
    promise = projectServiceModule.getProjectTeamMembers(
      action.payload.projectId,
      action.payload.queryString
    );
  }

  return promise;
};

const GetProjectParticipantsAsync = (action) => {
  let promise;
  if (!!action.payload.isPreview) {
    promise = projectServiceModule.getProjectDraftParticipants(
      action.payload.projectId,
      action.payload.queryString
    );
  } else {
    promise = projectServiceModule.getProjectParticipants(
      action.payload.projectId,
      action.payload.queryString
    );
  }

  return promise;
};
