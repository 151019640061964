import store from 'store2';
import {
  SET_ACTIVE_PROJECT_DETAIL_TAB_KEY,
  RESET_ACTIVE_PROJECT_DETAIL_TAB_KEY,
  GET_PROJECT_TABLES_INVESTMENT_DETAILS_SUCCESS,
  GET_PROJECT_TABLES_INVESTMENT_DETAILS_ERROR,
  RESET_PROJECT_TABLES_INVESTMENT_DETAILS,
  GET_PROJECT_TABLES_INVESTOR_DETAILS_SUCCESS,
  GET_PROJECT_TABLES_INVESTOR_DETAILS_ERROR,
  RESET_PROJECT_TABLES_INVESTOR_DETAILS,
  GET_PROJECT_FUNDING_INFO_SUCCESS,
  GET_PROJECT_FUNDING_INFO_ERROR,
  RESET_PROJECT_FUNDING_INFO,
  GET_PROJECT_DAILY_SPREAD_SUCCESS,
  GET_PROJECT_DAILY_SPREAD_ERROR,
  RESET_PROJECT_DAILY_SPREAD,
  GET_PROJECT_COMPLETION_SUMMARY_SUCCESS,
  GET_PROJECT_COMPLETION_SUMMARY_ERROR,
  RESET_PROJECT_COMPLETION_SUMMARY,
  GET_PROJECT_INFO_SUCCESS,
  GET_PROJECT_INFO_ERROR,
  RESET_PROJECT_INFO,
  GET_PROJECT_DETAILS_SUCCESS,
  GET_PROJECT_DETAILS_ERROR,
  RESET_PROJECT_DETAILS,
  GET_PROJECT_COMPANY_SUCCESS,
  GET_PROJECT_COMPANY_ERROR,
  RESET_PROJECT_COMPANY,
  GET_PROJECT_DOCUMENTS_SUCCESS,
  GET_PROJECT_DOCUMENTS_ERROR,
  RESET_PROJECT_DOCUMENTS,
  GET_PROJECT_IMAGES_SUCCESS,
  GET_PROJECT_IMAGES_ERROR,
  RESET_PROJECT_IMAGES,
  GET_PROJECT_FAQS_SUCCESS,
  GET_PROJECT_FAQS_ERROR,
  RESET_PROJECT_FAQS,
  GET_PROJECT_UPDATES_SUCCESS,
  GET_PROJECT_UPDATES_ERROR,
  RESET_PROJECT_UPDATES,
  GET_PROJECT_PARENT_COMMENTS_SUCCESS,
  GET_PROJECT_PARENT_COMMENTS_ERROR,
  RESET_PROJECT_PARENT_COMMENTS,
  GET_PROJECT_CHILD_COMMENTS_SUCCESS,
  GET_PROJECT_CHILD_COMMENTS_ERROR,
  RESET_PROJECT_CHILD_COMMENTS,
  GET_PROJECT_TEAM_MEMBERS_SUCCESS,
  GET_PROJECT_TEAM_MEMBERS_ERROR,
  RESET_PROJECT_TEAM_MEMBERS,
  GET_PROJECT_PARTICIPANTS_SUCCESS,
  GET_PROJECT_PARTICIPANTS_ERROR,
  RESET_PROJECT_PARTICIPANTS,
  SET_IS_FAVORED,
  SET_IS_WIZARD_MESSAGE_RECEIVED,
  SET_IS_PREVIEW
} from '../actions';

const activeProjectDetailTabKeyInitialState = '1';
const tablesInvestmentDetailsInitialState = null;
const tablesInvestorDetailsInitialState = null;
const fundingInfoInitialState = null;
const dailySpreadInitialState = null;
const completionSummaryInitialState = null;
const infoInitialState = null;
const projectDetailsInitialState = null;
const projectCompanyInitialState = null;
const documentsInitialState = null;
const projectImagesInitialState = null;
const faqsInitialState = null;
const updatesInitialState = null;
const parentCommentsInitialState = null;
const teamMembersInitialState = null;
const participantsInitialState = null;
const isFavoredInitialState = null;
const isWizardMessageReceivedInitialState = null;
const isPreviewInitialState = null;

const initialState = {
  activeProjectDetailTabKey: activeProjectDetailTabKeyInitialState,
  tablesInvestmentDetails: tablesInvestmentDetailsInitialState,
  tablesInvestorDetails: tablesInvestorDetailsInitialState,
  fundingInfo: fundingInfoInitialState,
  dailySpread: dailySpreadInitialState,
  completionSummary: completionSummaryInitialState,
  info: infoInitialState,
  projectDetails: projectDetailsInitialState,
  projectCompany: projectCompanyInitialState,
  documents: documentsInitialState,
  images: projectImagesInitialState,
  faqs: faqsInitialState,
  updates: updatesInitialState,
  parentComments: parentCommentsInitialState,
  teamMembers: teamMembersInitialState,
  participants: participantsInitialState,
  isFavored: isFavoredInitialState,
  isWizardMessageReceived: isWizardMessageReceivedInitialState,
  isPreview: isPreviewInitialState
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_PROJECT_DETAIL_TAB_KEY:
      //This prop is only used when new window is opened from wizard.
      //Therefore on project detail landing this is set to store and localstorage is removed.
      store.remove('activeProjectDetailTabKey');
      return { ...state, activeProjectDetailTabKey: action.payload };
    case RESET_ACTIVE_PROJECT_DETAIL_TAB_KEY:
      //This prop is only used when new window is opened from wizard.
      //Therefore on project detail landing this is set to store and localstorage is removed.
      store.remove('activeProjectDetailTabKey');
      return { ...state, activeProjectDetailTabKey: activeProjectDetailTabKeyInitialState };

    case GET_PROJECT_TABLES_INVESTMENT_DETAILS_SUCCESS:
      let tablesInvestmentDetails = action.payload;
      if (!!tablesInvestmentDetails?.response?.data) {
        tablesInvestmentDetails.response.data = tablesInvestmentDetails.response.data.map(
          (item) => {
            return { ...item, key: item.id };
          }
        );
      }
      return { ...state, tablesInvestmentDetails };
    case GET_PROJECT_TABLES_INVESTMENT_DETAILS_ERROR:
      return { ...state, tablesInvestmentDetails: action.payload };
    case RESET_PROJECT_TABLES_INVESTMENT_DETAILS:
      return { ...state, tablesInvestmentDetails: tablesInvestmentDetailsInitialState };

    case GET_PROJECT_TABLES_INVESTOR_DETAILS_SUCCESS:
    case GET_PROJECT_TABLES_INVESTOR_DETAILS_ERROR:
      return { ...state, tablesInvestorDetails: action.payload };
    case RESET_PROJECT_TABLES_INVESTOR_DETAILS:
      return { ...state, tablesInvestorDetails: tablesInvestorDetailsInitialState };

    case GET_PROJECT_FUNDING_INFO_SUCCESS:
    case GET_PROJECT_FUNDING_INFO_ERROR:
      return { ...state, fundingInfo: action.payload };
    case RESET_PROJECT_FUNDING_INFO:
      return { ...state, fundingInfo: fundingInfoInitialState };

    case GET_PROJECT_DAILY_SPREAD_SUCCESS:
    case GET_PROJECT_DAILY_SPREAD_ERROR:
      return { ...state, dailySpread: action.payload };
    case RESET_PROJECT_DAILY_SPREAD:
      return { ...state, dailySpread: dailySpreadInitialState };

    case GET_PROJECT_COMPLETION_SUMMARY_SUCCESS:
    case GET_PROJECT_COMPLETION_SUMMARY_ERROR:
      return { ...state, completionSummary: action.payload };
    case RESET_PROJECT_COMPLETION_SUMMARY:
      return { ...state, completionSummary: completionSummaryInitialState };

    case GET_PROJECT_INFO_SUCCESS:
    case GET_PROJECT_INFO_ERROR:
      return { ...state, info: action.payload };
    case RESET_PROJECT_INFO:
      return { ...state, info: infoInitialState };

    case GET_PROJECT_DETAILS_SUCCESS:
    case GET_PROJECT_DETAILS_ERROR:
      return { ...state, projectDetails: action.payload };
    case RESET_PROJECT_DETAILS:
      return { ...state, projectDetails: projectDetailsInitialState };

    case GET_PROJECT_COMPANY_SUCCESS:
    case GET_PROJECT_COMPANY_ERROR:
      return { ...state, projectCompany: action.payload };
    case RESET_PROJECT_COMPANY:
      return { ...state, projectCompany: projectCompanyInitialState };

    case GET_PROJECT_DOCUMENTS_SUCCESS:
    case GET_PROJECT_DOCUMENTS_ERROR:
      return { ...state, documents: action.payload };
    case RESET_PROJECT_DOCUMENTS:
      return { ...state, documents: projectCompanyInitialState };

    case GET_PROJECT_IMAGES_SUCCESS:
      let images = {
        ...action.payload,
        cumulativeData: [...(state.images?.cumulativeData ?? []), ...action.payload.response.data]
      };
      return { ...state, images };
    case GET_PROJECT_IMAGES_ERROR:
      return { ...state, images: action.payload };
    case RESET_PROJECT_IMAGES:
      return { ...state, images: projectImagesInitialState };

    case GET_PROJECT_FAQS_SUCCESS:
    case GET_PROJECT_FAQS_ERROR:
      return { ...state, faqs: action.payload };
    case RESET_PROJECT_FAQS:
      return { ...state, faqs: faqsInitialState };

    case GET_PROJECT_UPDATES_SUCCESS:
      let updates = {
        ...action.payload,
        cumulativeData: [...(state.updates?.cumulativeData ?? []), ...action.payload.response.data]
      };
      return { ...state, updates };
    case GET_PROJECT_UPDATES_ERROR:
      return state;
    case RESET_PROJECT_UPDATES:
      return { ...state, updates: updatesInitialState };

    case GET_PROJECT_PARENT_COMMENTS_SUCCESS:
      let comments = {
        ...action.payload,
        cumulativeData: [...action.payload.response.data]
      };
      return { ...state, parentComments: comments };
    case GET_PROJECT_PARENT_COMMENTS_ERROR:
      return state;
    case RESET_PROJECT_PARENT_COMMENTS:
      return { ...state, parentComments: parentCommentsInitialState };
    case GET_PROJECT_CHILD_COMMENTS_SUCCESS:
      const parentCommentsCumulativeData = state.parentComments?.cumulativeData.map(
        (parentComment) => {
          let newParentComment = parentComment;
          if (newParentComment.id === action.payload.request.parentCommentId) {
            newParentComment.childComments = {
              ...action.payload,
              cumulativeData: [...action.payload.response.data]
            };
          }
          return newParentComment;
        }
      );
      const parentComments = {
        ...state.parentComments,
        cumulativeData: parentCommentsCumulativeData
      };
      return { ...state, parentComments: parentComments };
    case GET_PROJECT_CHILD_COMMENTS_ERROR:
      return state;
    case RESET_PROJECT_CHILD_COMMENTS:
      //Is not necessary but when this is triggered, we can clear child comments of the parent comment.
      return { ...state };

    case GET_PROJECT_TEAM_MEMBERS_SUCCESS:
    case GET_PROJECT_TEAM_MEMBERS_ERROR:
      return { ...state, teamMembers: action.payload };
    case RESET_PROJECT_TEAM_MEMBERS:
      return { ...state, teamMembers: teamMembersInitialState };

    case GET_PROJECT_PARTICIPANTS_SUCCESS:
    case GET_PROJECT_PARTICIPANTS_ERROR:
      return { ...state, participants: action.payload };
    case RESET_PROJECT_PARTICIPANTS:
      return { ...state, participants: participantsInitialState };

    case SET_IS_PREVIEW:
      return { ...state, isPreview: action.payload };
    case SET_IS_WIZARD_MESSAGE_RECEIVED:
      return { ...state, isWizardMessageReceived: action.payload };
    case SET_IS_FAVORED:
      return { ...state, isFavored: action.payload };
    default:
      return state;
  }
};
