import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row, BackTop } from 'antd';
import Footer from '../../../components/footer/index';
import Header from '../../../components/header/header/index';
import { initGA, logPageView as gaLogPageView } from '../../../shared/googlAnalytics/analytics';

import { useLocation } from 'react-router-dom';
import { GetClientParameters, ResetClientParameters } from '../../../redux/Parameters/actions';
import { initPixel, logPageView as pixelLogPageView } from '../../../shared/facebookPixel/pixel';

const Common = (props) => {
  const location = useLocation();

  useEffect(() => {
    props.GetClientParameters();

    return () => {
      props.ResetClientParameters();
    };
  }, []);

  useEffect(() => {
    initGA();
    gaLogPageView();

    if (process.env.REACT_APP_IS_FACEBOOK_PIXEL_INTEGRATION_ACTIVE.toLowerCase() === 'true') {
      initPixel();
      pixelLogPageView();
    }
  }, [location]);

  return (
    <Fragment>
      <Row
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexFlow: 'column'
        }}
      >
        <Col
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            flexFlow: 'column'
          }}
        >
          <Row style={{ flex: '0 1 auto' }}>
            <Header />
          </Row>
          <Row style={{ flex: '1 1 auto' }}>{props.children}</Row>
          <Row style={{ flex: '0 1 0' }}>
            <BackTop />
            <Footer />
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    parameters: state.parameters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetClientParameters: () => dispatch(GetClientParameters()),
    ResetClientParameters: () => dispatch(ResetClientParameters())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Common);
