import { Button, Form, Input, Tooltip, Icon } from 'antd';
import { Col, Row } from 'react-bootstrap';
import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { notify } from '../../../shared/notify';
import styles from './styles.module.scss';
import { userServiceModule } from '../../../services/modules/userServiceModule';
import PasswordPolicyHelper from '../../../helpers/passwordPolicyHelper';
import { OneClickButton } from '../../custom';
import { commonServiceModule } from '../../../services/modules/commonServiceModule';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      previousPassword: '',
      password: '',
      confirmPassword: '',
      currentPasswordPolicy: null,
      passwordPolicyInfoMessages: []
    };

    this.updatePassword = this.updatePassword.bind(this);
  }

  componentDidMount = async () => {
    commonServiceModule
      .getCurrentPasswordPolicy()
      .then((response) => {
        const passwordPolicyInfoMessages = new PasswordPolicyHelper().getPasswordPolicyInfoMessages(
          response,
          this.props.passwordPolicy
        );
        this.setState({
          currentPasswordPolicy: response,
          passwordPolicyInfoMessages
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  onOldPasswordChange = (e) => {
    const previousPassword = e.target.value;
    this.setState(() => ({ previousPassword }));
  };
  onPasswordChange = (e) => {
    const password = e.target.value;
    this.setState(() => ({ password }));
  };
  onconfirmChange = (e) => {
    const confirmPassword = e.target.value;
    this.setState(() => ({ confirmPassword }));
  };

  compareToFirstPassword = (rule, value, callback) => {
    if (!value) {
      callback(this.props.common.requiredField);
      return;
    }

    if (value.length > 32) {
      callback(this.props.common.errorMessageMax32);
      return;
    }

    if (value !== this.props.form.getFieldValue('password')) {
      callback(this.props.data.confirmPasswordCheck);
    }

    callback();
  };

  updatePassword = async (e) => {
    let promise;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let payload = {
          previousPassword: this.state.previousPassword,
          password: this.state.password
        };
        promise = userServiceModule.changePassword(payload);
        if (promise && Promise.resolve(promise)) {
          promise.then((response) => {
            this.setState(() => ({
              previousPassword: '',
              password: '',
              confirmPassword: ''
            }));
            notify('Success', this.props.data.changePasswordNotifySucces);
            this.resetForm();
          });
        }
      }
    });
    return promise;
  };

  resetForm = () => {
    this.setState({
      previousPassword: '',
      password: '',
      confirmPassword: ''
    });
    this.props.form.resetFields();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className={classnames(styles.container, 'p-5')}>
        <Row>
          <Col>
            <h1>{this.props.data.title}</h1>
            <Form>
              {!!this.state.currentPasswordPolicy && (
                <div>
                  <Row>
                    <Col>
                      <Form.Item label={this.props.data.oldPassword} hasFeedback={true}>
                        {getFieldDecorator('oldPassword', {
                          rules: [
                            {
                              required: true,
                              message: this.props.common.requiredField
                            },
                            {
                              max: 32,
                              message: this.props.common.errorMessageMax32
                            },
                            {
                              pattern: RegExp(this.state.currentPasswordPolicy.acceptanceRegex),
                              message: this.props.passwordPolicy.passwordPolicyError
                            }
                          ]
                        })(
                          <Input.Password
                            onChange={this.onOldPasswordChange}
                            placeholder={this.props.data.passwordConfirm}
                            className={styles.input}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item
                        label={
                          <span>
                            <Tooltip
                              placement="topLeft"
                              title={
                                <div>
                                  <ul>
                                    {this.state.passwordPolicyInfoMessages.map((element, id) => {
                                      return <li key={id}>{element}</li>;
                                    })}
                                  </ul>
                                </div>
                              }
                            >
                              {this.props.data.newPassword}
                              <Icon type="question-circle-o" />
                            </Tooltip>
                          </span>
                        }
                        hasFeedback={true}
                      >
                        {getFieldDecorator('password', {
                          rules: [
                            {
                              required: true,
                              message: this.props.common.requiredField
                            },
                            {
                              max: 32,
                              message: this.props.common.errorMessageMax32
                            },
                            {
                              pattern: RegExp(this.state.currentPasswordPolicy.acceptanceRegex),
                              message: this.props.passwordPolicy.passwordPolicyError
                            }
                          ]
                        })(
                          <Input.Password
                            placeholder={this.props.data.password}
                            className={styles.input}
                            onChange={this.onPasswordChange}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item label={this.props.data.newPasswordConfirm} hasFeedback={true}>
                        {getFieldDecorator('confirm', {
                          rules: [
                            {
                              required: true,
                              validator: this.compareToFirstPassword
                            }
                          ]
                        })(
                          <Input.Password
                            onChange={this.onconfirmChange}
                            placeholder={this.props.data.passwordConfirm}
                            className={styles.input}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col className={classnames(styles.buttonContainer, styles.customStyle1)}>
                      <OneClickButton
                        onClick={this.updatePassword}
                        className={styles.button}
                        htmlType="submit"
                      >
                        {this.props.data.btnChange}
                      </OneClickButton>
                    </Col>
                  </Row>
                </div>
              )}
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.language.dashboard.profile.password,
    passwordPolicy: state.language.passwordPolicy,
    common: state.language.common
  };
};

export default connect(mapStateToProps)(Form.create({ name: 'ChangePassword' })(ChangePassword));
