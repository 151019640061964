import {
  SET_ACTIVE_PROJECT_DETAIL_TAB_KEY,
  RESET_ACTIVE_PROJECT_DETAIL_TAB_KEY,
  GET_PROJECT_TABLES_INVESTMENT_DETAILS,
  GET_PROJECT_TABLES_INVESTMENT_DETAILS_SUCCESS,
  GET_PROJECT_TABLES_INVESTMENT_DETAILS_ERROR,
  RESET_PROJECT_TABLES_INVESTMENT_DETAILS,
  GET_PROJECT_TABLES_INVESTOR_DETAILS,
  GET_PROJECT_TABLES_INVESTOR_DETAILS_SUCCESS,
  GET_PROJECT_TABLES_INVESTOR_DETAILS_ERROR,
  RESET_PROJECT_TABLES_INVESTOR_DETAILS,
  GET_PROJECT_FUNDING_INFO,
  GET_PROJECT_FUNDING_INFO_SUCCESS,
  GET_PROJECT_FUNDING_INFO_ERROR,
  RESET_PROJECT_FUNDING_INFO,
  GET_PROJECT_DAILY_SPREAD,
  GET_PROJECT_DAILY_SPREAD_SUCCESS,
  GET_PROJECT_DAILY_SPREAD_ERROR,
  RESET_PROJECT_DAILY_SPREAD,
  GET_PROJECT_COMPLETION_SUMMARY,
  GET_PROJECT_COMPLETION_SUMMARY_SUCCESS,
  GET_PROJECT_COMPLETION_SUMMARY_ERROR,
  RESET_PROJECT_COMPLETION_SUMMARY,
  GET_PROJECT_INFO,
  GET_PROJECT_INFO_SUCCESS,
  GET_PROJECT_INFO_ERROR,
  RESET_PROJECT_INFO,
  GET_PROJECT_DETAILS,
  GET_PROJECT_DETAILS_SUCCESS,
  GET_PROJECT_DETAILS_ERROR,
  RESET_PROJECT_DETAILS,
  GET_PROJECT_COMPANY,
  GET_PROJECT_COMPANY_SUCCESS,
  GET_PROJECT_COMPANY_ERROR,
  RESET_PROJECT_COMPANY,
  GET_PROJECT_DOCUMENTS,
  GET_PROJECT_DOCUMENTS_SUCCESS,
  GET_PROJECT_DOCUMENTS_ERROR,
  RESET_PROJECT_DOCUMENTS,
  GET_PROJECT_IMAGES,
  GET_PROJECT_IMAGES_SUCCESS,
  GET_PROJECT_IMAGES_ERROR,
  RESET_PROJECT_IMAGES,
  GET_PROJECT_FAQS,
  GET_PROJECT_FAQS_SUCCESS,
  GET_PROJECT_FAQS_ERROR,
  RESET_PROJECT_FAQS,
  GET_PROJECT_UPDATES,
  GET_PROJECT_UPDATES_SUCCESS,
  GET_PROJECT_UPDATES_ERROR,
  RESET_PROJECT_UPDATES,
  GET_PROJECT_PARENT_COMMENTS,
  GET_PROJECT_PARENT_COMMENTS_SUCCESS,
  GET_PROJECT_PARENT_COMMENTS_ERROR,
  RESET_PROJECT_PARENT_COMMENTS,
  GET_PROJECT_CHILD_COMMENTS,
  GET_PROJECT_CHILD_COMMENTS_SUCCESS,
  GET_PROJECT_CHILD_COMMENTS_ERROR,
  RESET_PROJECT_CHILD_COMMENTS,
  GET_PROJECT_TEAM_MEMBERS,
  GET_PROJECT_TEAM_MEMBERS_SUCCESS,
  GET_PROJECT_TEAM_MEMBERS_ERROR,
  RESET_PROJECT_TEAM_MEMBERS,
  GET_PROJECT_PARTICIPANTS,
  GET_PROJECT_PARTICIPANTS_SUCCESS,
  GET_PROJECT_PARTICIPANTS_ERROR,
  RESET_PROJECT_PARTICIPANTS,
  SET_IS_PREVIEW,
  SET_IS_WIZARD_MESSAGE_RECEIVED,
  SET_IS_FAVORED
} from '../actions';

export const SetActiveProjectDetailTabKey = (payload) => {
  return {
    type: SET_ACTIVE_PROJECT_DETAIL_TAB_KEY,
    payload
  };
};

export const ResetActiveProjectDetailTabKey = () => {
  return {
    type: RESET_ACTIVE_PROJECT_DETAIL_TAB_KEY
  };
};

export const GetProjectTablesInvestmentDetails = (payload) => {
  return {
    type: GET_PROJECT_TABLES_INVESTMENT_DETAILS,
    payload
  };
};

export const GetProjectTablesInvestmentDetailsSuccess = (payload) => {
  return {
    type: GET_PROJECT_TABLES_INVESTMENT_DETAILS_SUCCESS,
    payload
  };
};

export const GetProjectTablesInvestmentDetailsError = (payload) => {
  return {
    type: GET_PROJECT_TABLES_INVESTMENT_DETAILS_ERROR,
    payload
  };
};

export const ResetProjectTablesInvestmentDetails = () => {
  return {
    type: RESET_PROJECT_TABLES_INVESTMENT_DETAILS
  };
};

export const GetProjectTablesInvestorDetails = (payload) => {
  return {
    type: GET_PROJECT_TABLES_INVESTOR_DETAILS,
    payload
  };
};

export const GetProjectTablesInvestorDetailsSuccess = (payload) => {
  return {
    type: GET_PROJECT_TABLES_INVESTOR_DETAILS_SUCCESS,
    payload
  };
};

export const GetProjectTablesInvestorDetailsError = (payload) => {
  return {
    type: GET_PROJECT_TABLES_INVESTOR_DETAILS_ERROR,
    payload
  };
};

export const ResetProjectTablesInvestorDetails = () => {
  return {
    type: RESET_PROJECT_TABLES_INVESTOR_DETAILS
  };
};

export const GetProjectFundingInfo = (payload) => {
  return {
    type: GET_PROJECT_FUNDING_INFO,
    payload
  };
};

export const GetProjectFundingInfoSuccess = (payload) => {
  return {
    type: GET_PROJECT_FUNDING_INFO_SUCCESS,
    payload
  };
};

export const GetProjectFundingInfoError = (payload) => {
  return {
    type: GET_PROJECT_FUNDING_INFO_ERROR,
    payload
  };
};

export const ResetProjectFundingInfo = () => {
  return {
    type: RESET_PROJECT_FUNDING_INFO
  };
};

export const GetProjectDailySpread = (payload) => {
  return {
    type: GET_PROJECT_DAILY_SPREAD,
    payload
  };
};

export const GetProjectDailySpreadSuccess = (payload) => {
  return {
    type: GET_PROJECT_DAILY_SPREAD_SUCCESS,
    payload
  };
};

export const GetProjectDailySpreadError = (payload) => {
  return {
    type: GET_PROJECT_DAILY_SPREAD_ERROR,
    payload
  };
};

export const ResetProjectDailySpread = () => {
  return {
    type: RESET_PROJECT_DAILY_SPREAD
  };
};

export const GetProjectCompletionSummary = (payload) => {
  return {
    type: GET_PROJECT_COMPLETION_SUMMARY,
    payload
  };
};

export const GetProjectCompletionSummarySuccess = (payload) => {
  return {
    type: GET_PROJECT_COMPLETION_SUMMARY_SUCCESS,
    payload
  };
};

export const GetProjectCompletionSummaryError = (payload) => {
  return {
    type: GET_PROJECT_COMPLETION_SUMMARY_ERROR,
    payload
  };
};

export const ResetProjectCompletionSummary = () => {
  return {
    type: RESET_PROJECT_COMPLETION_SUMMARY
  };
};

export const GetProjectInfo = (payload) => {
  return {
    type: GET_PROJECT_INFO,
    payload
  };
};

export const GetProjectInfoSuccess = (payload) => {
  return {
    type: GET_PROJECT_INFO_SUCCESS,
    payload
  };
};

export const GetProjectInfoError = (payload) => {
  return {
    type: GET_PROJECT_INFO_ERROR,
    payload
  };
};

export const ResetProjectInfo = () => {
  return {
    type: RESET_PROJECT_INFO
  };
};

export const GetProjectDetails = (payload) => {
  return {
    type: GET_PROJECT_DETAILS,
    payload
  };
};

export const GetProjectDetailsSuccess = (payload) => {
  return {
    type: GET_PROJECT_DETAILS_SUCCESS,
    payload
  };
};

export const GetProjectDetailsError = (payload) => {
  return {
    type: GET_PROJECT_DETAILS_ERROR,
    payload
  };
};

export const ResetProjectDetails = () => {
  return {
    type: RESET_PROJECT_DETAILS
  };
};

export const GetProjectCompany = (payload) => {
  return {
    type: GET_PROJECT_COMPANY,
    payload
  };
};

export const GetProjectCompanySuccess = (payload) => {
  return {
    type: GET_PROJECT_COMPANY_SUCCESS,
    payload
  };
};

export const GetProjectCompanyError = (payload) => {
  return {
    type: GET_PROJECT_COMPANY_ERROR,
    payload
  };
};

export const ResetProjectCompany = () => {
  return {
    type: RESET_PROJECT_COMPANY
  };
};

export const GetProjectDocuments = (payload) => {
  return {
    type: GET_PROJECT_DOCUMENTS,
    payload
  };
};

export const GetProjectDocumentsSuccess = (payload) => {
  return {
    type: GET_PROJECT_DOCUMENTS_SUCCESS,
    payload
  };
};

export const GetProjectDocumentsError = (payload) => {
  return {
    type: GET_PROJECT_DOCUMENTS_ERROR,
    payload
  };
};

export const ResetProjectDocuments = () => {
  return {
    type: RESET_PROJECT_DOCUMENTS
  };
};

export const GetProjectImages = (payload) => {
  return {
    type: GET_PROJECT_IMAGES,
    payload
  };
};

export const GetProjectImagesSuccess = (payload) => {
  return {
    type: GET_PROJECT_IMAGES_SUCCESS,
    payload
  };
};

export const GetProjectImagesError = (payload) => {
  return {
    type: GET_PROJECT_IMAGES_ERROR,
    payload
  };
};

export const ResetProjectImages = () => {
  return {
    type: RESET_PROJECT_IMAGES
  };
};

export const GetProjectFaqs = (payload) => {
  return {
    type: GET_PROJECT_FAQS,
    payload
  };
};

export const GetProjectFaqsSuccess = (payload) => {
  return {
    type: GET_PROJECT_FAQS_SUCCESS,
    payload
  };
};

export const GetProjectFaqsError = (payload) => {
  return {
    type: GET_PROJECT_FAQS_ERROR,
    payload
  };
};

export const ResetProjectFaqs = () => {
  return {
    type: RESET_PROJECT_FAQS
  };
};

export const GetProjectUpdates = (payload) => {
  return {
    type: GET_PROJECT_UPDATES,
    payload
  };
};

export const GetProjectUpdatesSuccess = (payload) => {
  return {
    type: GET_PROJECT_UPDATES_SUCCESS,
    payload
  };
};

export const GetProjectUpdatesError = (payload) => {
  return {
    type: GET_PROJECT_UPDATES_ERROR,
    payload
  };
};

export const ResetProjectUpdates = () => {
  return {
    type: RESET_PROJECT_UPDATES
  };
};

export const GetProjectParentComments = (payload) => {
  return {
    type: GET_PROJECT_PARENT_COMMENTS,
    payload
  };
};

export const GetProjectParentCommentsSuccess = (payload) => {
  return {
    type: GET_PROJECT_PARENT_COMMENTS_SUCCESS,
    payload
  };
};

export const GetProjectParentCommentsError = (payload) => {
  return {
    type: GET_PROJECT_PARENT_COMMENTS_ERROR,
    payload
  };
};

export const ResetProjectParentComments = () => {
  return {
    type: RESET_PROJECT_PARENT_COMMENTS
  };
};

export const GetProjectChildComments = (payload) => {
  return {
    type: GET_PROJECT_CHILD_COMMENTS,
    payload
  };
};

export const GetProjectChildCommentsSuccess = (payload) => {
  return {
    type: GET_PROJECT_CHILD_COMMENTS_SUCCESS,
    payload
  };
};

export const GetProjectChildCommentsError = (payload) => {
  return {
    type: GET_PROJECT_CHILD_COMMENTS_ERROR,
    payload
  };
};

export const ResetProjectChildComments = () => {
  return {
    type: RESET_PROJECT_CHILD_COMMENTS
  };
};

export const GetProjectTeamMembers = (payload) => {
  return {
    type: GET_PROJECT_TEAM_MEMBERS,
    payload
  };
};

export const GetProjectTeamMembersSuccess = (payload) => {
  return {
    type: GET_PROJECT_TEAM_MEMBERS_SUCCESS,
    payload
  };
};

export const GetProjectTeamMembersError = (payload) => {
  return {
    type: GET_PROJECT_TEAM_MEMBERS_ERROR,
    payload
  };
};

export const ResetProjectTeamMembers = () => {
  return {
    type: RESET_PROJECT_TEAM_MEMBERS
  };
};

export const GetProjectParticipants = (payload) => {
  return {
    type: GET_PROJECT_PARTICIPANTS,
    payload
  };
};

export const GetProjectParticipantsSuccess = (payload) => {
  return {
    type: GET_PROJECT_PARTICIPANTS_SUCCESS,
    payload
  };
};

export const GetProjectParticipantsError = (payload) => {
  return {
    type: GET_PROJECT_PARTICIPANTS_ERROR,
    payload
  };
};

export const ResetProjectParticipants = () => {
  return {
    type: RESET_PROJECT_PARTICIPANTS
  };
};

export const SetIsPreview = (payload) => {
  return {
    type: SET_IS_PREVIEW,
    payload
  };
};

export const SetIsWizardMessageReceived = (payload) => {
  return {
    type: SET_IS_WIZARD_MESSAGE_RECEIVED,
    payload
  };
};

export const SetIsFavored = (payload) => {
  return {
    type: SET_IS_FAVORED,
    payload
  };
};
