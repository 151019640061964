import React from 'react';
import { connect } from 'react-redux';
import Common from '../Common/index';

const Public = (props) => {
  return <Common {...props} />;
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication
  };
};

export default connect(mapStateToProps)(Public);
