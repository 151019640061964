import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Tooltip, Icon } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { notify } from '../../../../shared/notify';
import { PutMkkSclNumber } from '../../../../redux/MkkSicilNo/actions';
import { usePrevious } from '../../../../shared/common/hookHelper';
import styles from '../styles.module.scss';

function MkkVerificationForm(props) {
  const previousProps = usePrevious(props);

  useEffect(() => {
    if (
      !!previousProps &&
      !!props.regularUser?.mkkSclVerified &&
      !previousProps?.regularUser?.mkkSclVerified
    ) {
      notify('Success', props.profileInfo.mkkNotify);
    }
  }, [props.regularUser?.mkkSclNumber]);

  const putMkkSclNumber = () => {
    props.form.validateFieldsAndScroll((err, values) => {
      if (!!values.mkkSclNumber) {
        if (!err) {
          const payload = {
            mkkSicilNo: values.mkkSclNumber
          };
          props.PutMkkSclNumber(payload);
        }
      } else {
        notify('Error', props.common.requiredField);
      }
    });
  };

  return (
    <Form id="MkkVerificationForm" layout={'inline'}>
      <Row>
        <Col>
          <Input.Group compact>
            <Form.Item
              label={
                <>
                  <Tooltip
                    title={
                      <a
                        href="https://eyatirimci.mkk.com.tr/auth/login"
                        target="_blank"
                        style={{ color: 'white' }}
                      >
                        {props.profileInfo.mkkTooltip}
                      </a>
                    }
                  >
                    <Icon type="question-circle-o" />
                  </Tooltip>
                  {props.profileInfo.mkkSclNumber}
                </>
              }
              hasFeedback={false}
            >
              {props.form.getFieldDecorator('mkkSclNumber', {
                validateTrigger: ['onBlur'],
                rules: [
                  {
                    required: props.regularUser?.mkkSclVerificationIsMandatoryWhenInvesting,
                    message: props.common.requiredField
                  },
                  {
                    pattern: RegExp('^[0-9]{8,8}$'),
                    message: props.profileInfo.mkkError
                  }
                ],
                initialValue: props.regularUser?.mkkSclNumber
              })(
                <Input
                  className={styles.formInput}
                  disabled={
                    !!props.regularUser?.mkkSclVerified &&
                    props.regularUser?.mkkSclNumber?.length > 0
                  }
                  maxLength={8}
                />
              )}
            </Form.Item>

            {!props.regularUser?.mkkSclVerified && (
              <Form.Item>
                <Button
                  onClick={putMkkSclNumber}
                  className={styles.button}
                  form="MkkVerificationForm"
                >
                  {props.profileInfo.btnSave}
                </Button>
              </Form.Item>
            )}
          </Input.Group>
        </Col>
      </Row>
    </Form>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    PutMkkSclNumber: (payload) => dispatch(PutMkkSclNumber(payload))
  };
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.language.dashboard.profile.profileInfo,
    regularUser: state.regularUser,
    common: state.language.common
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'MkkVerificationForm' })(MkkVerificationForm));

