import React from 'react';
import { connect } from 'react-redux';
import styles from './styles.module.scss';

const PendingIndividual = (props) => {
  return (
    <div className={styles.pendingContainer}>
      <h3>{props.data.identityPendingVerification}</h3>
      <img src="/images/clock.png" alt="loading" className={styles.sandClock} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.language.dashboard.profile.kyc
  };
};

export default connect(mapStateToProps)(PendingIndividual);

