import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';
import styles from './styles.module.scss';
import PendingCorporate from './pendingcorporate';
import PendingIndividual from './pendingindividual';
import SignalRClient from '../../custom/SignalR/index';
import { GetRegularUser } from '../../../redux/RegularUser/actions';

const PendingUser = (props) => {
  return (
    <div>
      <SignalRClient
        hub="identityVerification"
        onReceive={(data) => {
          // do reload profile info
          if (data?.payloadData === 'Verified' || data?.payloadData === 'Error') {
            props.GetRegularUserProfile();
          }
        }}
      />
      <h1>{props.data.formTitle}</h1>
      <Row>
        <Col>
          <div className={classnames(styles.expContainer)}>
            {props.regularUser?.pendingIdentityType === 'VKN' ? (
              <PendingCorporate />
            ) : (
              <PendingIndividual />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.language.dashboard.profile.kyc,
    regularUser: state.regularUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetRegularUserProfile: (payload) => dispatch(GetRegularUser(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingUser);
