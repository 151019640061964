import {
  SUBSCRIBE_TO_BULLETIN,
  SUBSCRIBE_TO_BULLETIN_SUCCESS,
  SUBSCRIBE_TO_BULLETIN_ERROR
} from '../actions';

export const SubscribeToBulletin = (payload) => {
  return {
    type: SUBSCRIBE_TO_BULLETIN,
    payload: payload
  };
};

//Todo: What to do?
export const SubscribeToBulletinSuccess = (payload) => {
  return {
    type: SUBSCRIBE_TO_BULLETIN_SUCCESS,
    payload: payload
  };
};

//Todo: What to do?
export const SubscribeToBulletinError = (payload) => {
  return {
    type: SUBSCRIBE_TO_BULLETIN_ERROR,
    payload: payload
  };
};
