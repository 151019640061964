import {
  GET_GEO_ADDRESS_COUNTRIES_SUCCESS,
  GET_GEO_ADDRESS_COUNTRIES_ERROR,
  RESET_GEO_ADDRESS_COUNTRIES,
  GET_GEO_ADDRESS_COUNTRY_STATES_SUCCESS,
  GET_GEO_ADDRESS_COUNTRY_STATES_ERROR,
  RESET_GEO_ADDRESS_COUNTRY_STATES,
  GET_GEO_ADDRESS_COUNTRY_STATE_CITIES_SUCCESS,
  GET_GEO_ADDRESS_COUNTRY_STATE_CITIES_ERROR,
  RESET_GEO_ADDRESS_COUNTRY_STATE_CITIES
} from '../actions';

const countriesInitialState = null;
const countryStatesInitialState = null;
const countryStateCitiesInitialState = null;

const initialState = {
  countries: countriesInitialState,
  countryStates: countryStatesInitialState,
  countryStateCities: countryStateCitiesInitialState
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GEO_ADDRESS_COUNTRIES_SUCCESS:
      const sortedCountryList = action.payload.response?.sort((a, b) => {
        return Intl.Collator('tr').compare(a.translations.tr, b.translations.tr);
      });

      const tr = sortedCountryList.filter((item) => item.iso2 === 'TR');
      const trExcluded = sortedCountryList.filter((item) => item.iso2 !== 'TR');
      const trFirstCountryList = [...tr, ...trExcluded];

      return { ...state, countries: { ...action.payload, response: trFirstCountryList } };
    case GET_GEO_ADDRESS_COUNTRIES_ERROR:
      return { ...state, countries: action.payload };
    case RESET_GEO_ADDRESS_COUNTRIES:
      return { ...state, countries: countriesInitialState };
    case GET_GEO_ADDRESS_COUNTRY_STATES_SUCCESS:
      const sortedStateList = action.payload.response?.sort((a, b) => {
        return Intl.Collator('tr').compare(a.name, b.name);
      });

      const istanbul = sortedStateList.filter((item) => item.stateCode === '34');
      const istanbulExcluded = sortedStateList.filter((item) => item.stateCode !== '34');
      const istanbulFirstStateList = [...istanbul, ...istanbulExcluded];

      return { ...state, countryStates: { ...action.payload, response: istanbulFirstStateList } };
    case GET_GEO_ADDRESS_COUNTRY_STATES_ERROR:
      return { ...state, countryStates: action.payload };
    case RESET_GEO_ADDRESS_COUNTRY_STATES:
      return { ...state, countryStates: countryStatesInitialState };
    case GET_GEO_ADDRESS_COUNTRY_STATE_CITIES_SUCCESS:
      const sortedCityList = action.payload.response?.sort((a, b) => {
        return Intl.Collator('tr').compare(a.name, b.name);
      });
      return { ...state, countryStateCities: { ...action.payload, response: sortedCityList } };
    case GET_GEO_ADDRESS_COUNTRY_STATE_CITIES_ERROR:
      return { ...state, countryStateCities: action.payload };
    case RESET_GEO_ADDRESS_COUNTRY_STATE_CITIES:
      return { ...state, countryStateCities: countryStateCitiesInitialState };
    default:
      return state;
  }
};
