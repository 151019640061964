import citiesTR from './city_TR.json';
import citiesEN from './city_EN.json';

import countryTR from './country_TR.json';
import countryEN from './country_EN.json';

export const getAllCities = (locale) => {
  switch (locale) {
    case 'TR':
      return citiesTR;
    case 'EN':
      return citiesEN;
    default:
      return citiesTR;
  }
};

export const getAllCountries = (locale) => {
  switch (locale) {
    case 'TR':
      return countryTR;
    case 'EN':
      return countryEN;
    default:
      return countryTR;
  }
};

export const getCityName = (geonameId) => {
  let cityName = '';
  citiesTR.map((item) => {
    if (item.geonameId.toString() === geonameId) {
      cityName = item.name;
    }
  });

  return cityName;
};

export const getCity = (geonameId) => {
  let cityName = '';
  citiesTR.map((item) => {
    if (item.geonameId === geonameId) {
      cityName = item.name;
    }
  });

  return cityName;
};