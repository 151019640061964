import { Button, Form } from 'antd';
import { Col, Row } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import styles from '../../../styles/common/components/emptyState.module.scss';

class EmptyPortfolio extends React.Component {
  render() {
    const { title, firstParagraph, secondParagraph, btnCreateProject } = this.props.data;

    return (
      <Row className={classnames('justify-content-md-center', styles.container, styles.portfolio)}>
        <Col className={classnames('col-xs-11 col-sm-10 col-md-7 col-lg-5 col-xl-4', styles.box)}>
          <Row>
            <Col>
              <p className={styles.header}>{title}</p>
              <p className={styles.subheader}>{firstParagraph}</p>
              <p className={styles.subheader}>{secondParagraph}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item>
                <Button className={styles.button} type="primary">
                  <Link to="/projects">{btnCreateProject}</Link>
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.language.dashboard.portfolio.emptyPortfolio
  };
};

export default connect(mapStateToProps)(EmptyPortfolio);
