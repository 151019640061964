import { UPDATE_ACTIVETAB, RESET_ACTIVETAB } from '../actions/dashboardMessagesActions';

const initState = {
  activeTab: '1'
};

export default (state = initState, action) => {
  switch (action.type) {
    case UPDATE_ACTIVETAB:
      return { ...state, activeTab: action.payload.activeTab };
    case RESET_ACTIVETAB:
      return { ...state, activeTab: initState.activeTab };
    default:
      return { ...state };
  }
};
