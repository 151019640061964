import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import { loadResponse } from '../../helpers/http/response';
import { userServiceModule } from '../../services/modules/userServiceModule';

import { AUTHENTICATE_USER, LOGOUT_USER } from '../actions';
import { GetRegularUser } from '../RegularUser/actions';
import {
  AuthenticateUserSuccess,
  AuthenticateUserError,
  LogoutUserSuccess,
  LogoutUserError
} from './actions';

export default function* rootSaga() {
  yield all([fork(watchAuthenticateUser), fork(watchLogoutUser)]);
}

function* watchAuthenticateUser() {
  yield takeLeading(AUTHENTICATE_USER, AuthenticateUser);
}

function* watchLogoutUser() {
  yield takeLeading(LOGOUT_USER, LogoutUser);
}

function* AuthenticateUser(action) {
  try {
    const response = yield call(AuthenticateUserAsync, action);
    const responsePayload = loadResponse(action.payload, response, true);
    yield put(GetRegularUser());
    yield put(AuthenticateUserSuccess(responsePayload));
  } catch (error) {
    const errorPayload = loadResponse(action.payload, error, true);
    yield put(AuthenticateUserError(errorPayload));
  }
}

function* LogoutUser(action) {
  try {
    const response = yield call(LogoutUserAsync, action);
    yield put(LogoutUserSuccess(response));
  } catch (error) {
    const errorPayload = {
      payload: action.payload,
      error
    };
    yield put(LogoutUserError(errorPayload));
  }
}

const AuthenticateUserAsync = (action) => {
  return userServiceModule.authenticateUser(action.payload);
};

const LogoutUserAsync = (action) => {
  return userServiceModule.logoutUser(action.payload);
};
