import {
  GET_ALL_PROJECTS,
  GET_ACTIVE_PROJECTS,
  GET_PRERELEASE_PROJECTS,
  GET_CATEGORIES,
  GET_FINISHED_PROJECTS
} from '../actions';
import { all, call, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { projectServiceModule } from '../../services/modules/projectServiceModule';
import {
  GetAllProjectsSuccess,
  GetAllProjectsError,
  GetActiveProjectsError,
  GetActiveProjectsSuccess,
  GetPreReleaseProjectsError,
  GetPreReleaseProjectsSuccess,
  GetFinishedProjectsSuccess,
  GetFinishedProjectsError,
  GetCategoriesError,
  GetCategoriesSuccess
} from './actions';
import { loadError, loadSpfResponse } from '../../helpers/http/response';

export default function* rootSaga() {
  yield all([
    fork(watchGetAllProjects),
    fork(watchGetActiveProjects),
    fork(watchGetPreReleaseProjects),
    fork(watchGetFinishedProjects),
    fork(watchGetCategories)
  ]);
}

function* watchGetAllProjects() {
  yield takeLeading(GET_ALL_PROJECTS, getAllProjects);
}

function* watchGetActiveProjects() {
  yield takeEvery(GET_ACTIVE_PROJECTS, getActiveProjects);
}

function* watchGetPreReleaseProjects() {
  yield takeEvery(GET_PRERELEASE_PROJECTS, GetPreReleaseProjects);
}

function* watchGetFinishedProjects() {
  yield takeEvery(GET_FINISHED_PROJECTS, getFinishedProjects);
}

function* watchGetCategories() {
  yield takeLeading(GET_CATEGORIES, getCategories);
}

function* getAllProjects(action) {
  try {
    const response = yield call(getAllProjectsAsync, action);
    const payload = loadSpfResponse(action.payload, response);
    yield put(GetAllProjectsSuccess(payload));
  } catch (error) {
    yield put(GetAllProjectsError(error));
  }
}

function* getActiveProjects(action) {
  try {
    const response = yield call(getAllProjectsAsync, action);
    const payload = loadSpfResponse(action.payload, response);
    yield put(GetActiveProjectsSuccess(payload));
  } catch (error) {
    yield put(GetActiveProjectsError(error));
  }
}

function* GetPreReleaseProjects(action) {
  try {
    const response = yield call(getAllProjectsAsync, action);
    const payload = loadSpfResponse(action.payload, response);
    yield put(GetPreReleaseProjectsSuccess(payload));
  } catch (error) {
    yield put(GetPreReleaseProjectsError(error));
  }
}

function* getFinishedProjects(action) {
  try {
    const response = yield call(getAllProjectsAsync, action);
    const payload = loadSpfResponse(action.payload, response);
    yield put(GetFinishedProjectsSuccess(payload));
  } catch (error) {
    yield put(GetFinishedProjectsError(error));
  }
}

function* getCategories(action) {
  try {
    const response = yield call(getCategoriesAsync, action);
    const responsePayload = loadSpfResponse(action.payload, response);
    yield put(GetCategoriesSuccess(responsePayload));
  } catch (error) {
    const errorPayload = loadError(action.payload, error);
    yield put(GetCategoriesError(errorPayload));
  }
}

const getAllProjectsAsync = async (action) => {
  return projectServiceModule.getAllProjects(action.payload.queryString);
};

const getCategoriesAsync = async (action) => {
  return projectServiceModule.getProjectCategories(action.payload.queryString);
};
