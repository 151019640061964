import {
  GET_USER_DEMOGRAPHICS,
  GET_USER_DEMOGRAPHICS_SUCCESS,
  GET_USER_DEMOGRAPHICS_ERROR,
  RESET_GET_USER_DEMOGRAPHICS,
  PATCH_USER_DEMOGRAPHICS,
  PATCH_USER_DEMOGRAPHICS_SUCCESS,
  PATCH_USER_DEMOGRAPHICS_ERROR,
  RESET_PATCH_USER_DEMOGRAPHICS,
  GET_USER_DEMOGRAPHICS_ADDRESS_TYPE,
  GET_USER_DEMOGRAPHICS_ADDRESS_TYPE_SUCCESS,
  GET_USER_DEMOGRAPHICS_ADDRESS_TYPE_ERROR,
  RESET_USER_DEMOGRAPHICS_ADDRESS_TYPE
} from '../actions';

export const GetUserDemographics = (payload) => {
  return {
    type: GET_USER_DEMOGRAPHICS,
    payload
  };
};

export const GetUserDemographicsSuccess = (payload) => {
  return {
    type: GET_USER_DEMOGRAPHICS_SUCCESS,
    payload
  };
};

export const GetUserDemographicsError = (payload) => {
  return {
    type: GET_USER_DEMOGRAPHICS_ERROR,
    payload
  };
};

export const ResetGetUserDemographics = () => {
  return {
    type: RESET_GET_USER_DEMOGRAPHICS
  };
};

export const PatchUserDemographics = (payload) => {
  return {
    type: PATCH_USER_DEMOGRAPHICS,
    payload
  };
};

export const PatchUserDemographicsSuccess = (payload) => {
  return {
    type: PATCH_USER_DEMOGRAPHICS_SUCCESS,
    payload
  };
};

export const PatchUserDemographicsError = (payload) => {
  return {
    type: PATCH_USER_DEMOGRAPHICS_ERROR,
    payload
  };
};

export const ResetPatchUserDemographics = () => {
  return {
    type: RESET_PATCH_USER_DEMOGRAPHICS
  };
};

export const GetUserDemographicsAddressType = (payload) => {
  return {
    type: GET_USER_DEMOGRAPHICS_ADDRESS_TYPE,
    payload
  };
};

export const GetUserDemographicsAddressTypeSuccess = (payload) => {
  return {
    type: GET_USER_DEMOGRAPHICS_ADDRESS_TYPE_SUCCESS,
    payload
  };
};

export const GetUserDemographicsAddressTypeError = (payload) => {
  return {
    type: GET_USER_DEMOGRAPHICS_ADDRESS_TYPE_ERROR,
    payload
  };
};

export const ResetUserDemographicsAddressType = () => {
  return {
    type: RESET_USER_DEMOGRAPHICS_ADDRESS_TYPE
  };
};
