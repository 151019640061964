import { combineReducers } from 'redux';
import authenticationReducer from './Authentication/reducer';
import userReducer from '../reducers/userReducer';
import languageReducer from '../reducers/languageReducer';
import dashboardMessagesReducer from '../reducers/dashboardMessagesReducer';
import regularUserReducer from './RegularUser/reducer';
import projectsReducer from './Projects/reducer';
import projectDetailReducer from './ProjectDetail/reducer';
import dashboardReducer from './Dashboard/reducer';
import projectWizardReducer from './ProjectWizard/reducer';
import geoAddressReducer from './GeoAddress/reducer';
import userDemographicsReducer from './UserDemographics/reducer';
import parametersReducer from './Parameters/reducer';

const reducers = combineReducers({
  authentication: authenticationReducer,
  user: userReducer,
  language: languageReducer,
  dashboardMessages: dashboardMessagesReducer,
  regularUser: regularUserReducer,
  projects: projectsReducer,
  projectDetail: projectDetailReducer,
  dashboard: dashboardReducer,
  projectWizard: projectWizardReducer,
  geoAddress: geoAddressReducer,
  userDemographics: userDemographicsReducer,
  parameters: parametersReducer
});

export default reducers;
