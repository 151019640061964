import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Tooltip, Icon } from 'antd';
import { Col, Row } from 'react-bootstrap';
import styles from '../styles.module.scss';
import PhoneInput from 'react-phone-number-input/input';
import classnames from 'classnames';

import { GetUserDemographics } from '../../../../redux/UserDemographics/actions';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  GetGeoAddressCountries,
  GetGeoAddressCountryStateCities,
  GetGeoAddressCountryStates
} from '../../../../redux/GeoAddress/actions';
import { usePrevious } from '../../../../shared/common/hookHelper';
import OpenAddressTextArea from './OpenAddressTextArea';

const Info = (props) => {
  const addressInitialState = {
    country: null,
    state: null,
    city: null,
    addressLine: null
  };
  const [address, SetAddress] = useState(addressInitialState);
  const previousProps = usePrevious(props);

  useEffect(() => {
    props.GetUserDemographics();
  }, []);

  useEffect(() => {
    if (
      props.regularUser?.identityType === 'YKN' ||
      !!props.parameters?.clientParameters?.response
        ?.userAddressInfoIsRequiredWhenNotForeignIdentity
    ) {
      const userDemographics = props.userDemographics?.getUserDemographics?.response;
      const previousUserDemographics =
        previousProps?.userDemographics?.getUserDemographics?.response;
      if (userDemographics?.addresses[0] !== previousUserDemographics?.addresses[0]) {
        props.GetGeoAddressCountries();

        if (!!userDemographics?.addresses[0]?.countryCode) {
          const getStatesPayload = {
            countryCode: userDemographics?.addresses[0]?.countryCode
          };
          props.GetGeoAddressCountryStates(getStatesPayload);
        }
        if (
          !!userDemographics?.addresses[0]?.countryCode &&
          !!userDemographics?.addresses[0]?.stateOrProvinceCode
        ) {
          const getCitiesPayload = {
            countryCode: userDemographics?.addresses[0]?.countryCode,
            stateOrProvinceCode: userDemographics?.addresses[0]?.stateOrProvinceCode
          };
          props.GetGeoAddressCountryStateCities(getCitiesPayload);
        }

        SetAddress({
          country: userDemographics?.addresses[0].countryCode,
          state: userDemographics?.addresses[0].stateOrProvinceCode,
          city: userDemographics?.addresses[0].cityName,
          addressLine: userDemographics?.addresses[0].addressLine
        });
      }
    }
  }, [props.userDemographics?.getUserDemographics?.response]);

  return (
    <Form id="Info" layout={'inline'} colon={false}>
      <Row>
        <Col xs={12} md={7} lg={8}>
          <Row>
            <Col xs={12} className={styles.infoColumn}>
              <Form.Item label={props.profileInfo.eMailAddress} hasFeedback={false}>
                <Input
                  className={styles.formInput}
                  value={props.regularUser?.eMailAddress}
                  disabled
                />
              </Form.Item>
            </Col>

            <Col xs={12} className={styles.infoColumn}>
              <Form.Item label={props.profileInfo.phone} hasFeedback={false}>
                <PhoneInput
                  className={classnames(styles.formInput, 'ant-input')}
                  country="TR"
                  withCountryCallingCode
                  international
                  onChange={() => 0}
                  value={props.regularUser?.phoneNumber}
                  disabled
                />
              </Form.Item>
            </Col>

            {!!props.regularUser?.identityVerified && (
              <Col xs={12} className={styles.infoColumn}>
                <Form.Item
                  label={
                    <>
                      <Tooltip
                        title={
                          <a
                            href="https://eyatirimci.mkk.com.tr/auth/login"
                            target="_blank"
                            style={{ color: 'white' }}
                          >
                            {props.profileInfo.mkkTooltip}
                          </a>
                        }
                      >
                        <Icon type="question-circle-o" />
                      </Tooltip>
                      {props.profileInfo.mkkSclNumber}
                    </>
                  }
                  hasFeedback={false}
                >
                  <Input
                    className={styles.formInput}
                    value={props.regularUser?.mkkSclNumber}
                    disabled
                  />
                </Form.Item>
              </Col>
            )}

            {!!props.regularUser?.identityVerified && !props.regularUser?.birthDateVerified && (
              <Col xs={12} className={styles.infoColumn}>
                <Form.Item label={props.profileInfo.birthDate} hasFeedback={false}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      onChange={() => 0}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: 'gg/aa/yyyy'
                          }}
                        />
                      )}
                      disabled
                      value={null}
                    />
                  </LocalizationProvider>
                </Form.Item>
              </Col>
            )}

            {(props.regularUser?.identityType === 'YKN' ||
              !!props.parameters?.clientParameters?.response
                ?.userAddressInfoIsRequiredWhenNotForeignIdentity) && (
              <Col xs={12} className={styles.infoColumn}>
                <Form.Item label={props.profileInfo.openAddress} hasFeedback={false}>
                  <OpenAddressTextArea address={address} />
                  {/* <Input.TextArea
                  className={styles.formInput}
                  value={
                    props.userDemographics?.getUserDemographics?.response?.addresses[0]?.openAddress
                  }
                  disabled
                  rows={3}
                  style={{ resize: 'none' }}
                /> */}
                </Form.Item>
              </Col>
            )}
          </Row>
        </Col>

        <Col xs={12} md={5} lg={4} className={styles.imageContainer}></Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.language.dashboard.profile.profileInfo,
    regularUser: state.regularUser,
    common: state.language.common,
    userDemographics: state.userDemographics,
    parameters: state.parameters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetUserDemographics: () => dispatch(GetUserDemographics()),
    GetGeoAddressCountries: () => dispatch(GetGeoAddressCountries()),
    GetGeoAddressCountryStates: (payload) => dispatch(GetGeoAddressCountryStates(payload)),
    GetGeoAddressCountryStateCities: (payload) => dispatch(GetGeoAddressCountryStateCities(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Info);
