import validator from 'validator';
import socialUrl from 'social-url';

class CommonHelper {
  format(fmt, ...args) {
    if (!fmt.match(/^(?:(?:(?:[^{}]|(?:\{\{)|(?:\}\}))+)|(?:\{[0-9]+\}))+$/)) {
      throw new Error('invalid format string.');
    }
    return fmt.replace(/((?:[^{}]|(?:\{\{)|(?:\}\}))+)|(?:\{([0-9]+)\})/g, (m, str, index) => {
      if (str) {
        return str.replace(/(?:{{)|(?:}})/g, (m) => m[0]);
      } else {
        if (index >= args.length) {
          throw new Error('argument index is out of range in format');
        }
        return args[index];
      }
    });
  }

  formatMoney = (amount) => {
    return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  };

  validateUrl = ({ value, callback, validateFor, rule, errorMessage }) => {
    if (rule === 'ValidOrEmpty') {
      if (!value) {
        callback();
        return true;
      }
    }

    if (
      !validator.isURL(value.toLowerCase(), {
        protocols: ['http', 'https'],
        require_protocol: true
      })
    ) {
      callback(errorMessage);
      return false;
    }

    if (validateFor !== 'Website') {
      const social = socialUrl.parse(value.toLowerCase());
      if (!social || !social.network || !social.url || social.network !== validateFor) {
        callback(errorMessage);
        return false;
      }

      callback();
      return true;
    }

    callback();
    return true;
  };
}

export const commonHelper = new CommonHelper();
