import {
  GET_ALL_PROJECTS_SUCCESS,
  GET_ALL_PROJECTS_ERROR,
  RESET_ALL_PROJECTS,
  GET_ACTIVE_PROJECTS_SUCCESS,
  GET_ACTIVE_PROJECTS_ERROR,
  RESET_ACTIVE_PROJECTS,
  GET_PRERELEASE_PROJECTS_SUCCESS,
  GET_PRERELEASE_PROJECTS_ERROR,
  RESET_PRERELEASE_PROJECTS,
  GET_FINISHED_PROJECTS_SUCCESS,
  GET_FINISHED_PROJECTS_ERROR,
  RESET_FINISHED_PROJECTS,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERROR,
  RESET_CATEGORIES
} from '../actions';

const allProjectsInitialState = [];
const activeInitialState = [];
const preReleaseInitialState = [];
const finishedInitialState = [];
const categoriesInitialState = [];

const initialState = {
  allProjects: allProjectsInitialState,
  active: activeInitialState,
  preRelease: preReleaseInitialState,
  finished: finishedInitialState,
  categories: categoriesInitialState
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PROJECTS_SUCCESS:
      let projects = {
        ...action.payload,
        cumulativeData: [
          ...(state.allProjects?.cumulativeData ?? []),
          ...action.payload.response.data
        ]
      };
      return { ...state, allProjects: projects };
    case GET_ALL_PROJECTS_ERROR:
      return { ...state, allProjects: action.payload };
    case RESET_ALL_PROJECTS:
      return { ...state, allProjects: allProjectsInitialState };
    case GET_ACTIVE_PROJECTS_SUCCESS:
      return { ...state, active: action.payload };
    //TODO: What to do with error
    case GET_ACTIVE_PROJECTS_ERROR:
      return { ...state };
    case RESET_ACTIVE_PROJECTS:
      return { ...state, active: activeInitialState };
    case GET_PRERELEASE_PROJECTS_SUCCESS:
      return { ...state, preRelease: action.payload };
    //TODO: What to do with error
    case GET_PRERELEASE_PROJECTS_ERROR:
      return { ...state };
    case RESET_PRERELEASE_PROJECTS:
      return { ...state, preRelease: preReleaseInitialState };
    case GET_FINISHED_PROJECTS_SUCCESS:
      return { ...state, finished: action.payload };
    //TODO: What to do with error
    case GET_FINISHED_PROJECTS_ERROR:
      return { ...state };
    case RESET_FINISHED_PROJECTS:
      return { ...state, finished: finishedInitialState };
    case GET_CATEGORIES_SUCCESS:
      return { ...state, categories: action.payload };
    case GET_CATEGORIES_ERROR:
      return { ...state, categories: action.payload };
    case RESET_CATEGORIES:
      return { ...state, categories: categoriesInitialState };
    default:
      return { ...state };
  }
};

