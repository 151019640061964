import Axios from 'axios';
import store from 'store2';
import { getFileNameFromContentDisposition } from '../../helpers/responseHeaderHelper';
import {
  makeGetRequest,
  makePostRequest,
  makePutRequest,
  makeGetBlobRequest,
  makeUploadRequest,
  makeLogRequest
} from '../request';

class CommonServiceModule {
  bulletinSubscribe = (payload) => {
    return makePostRequest('common/bulletin', payload);
  };

  getTeamSizeEnums = () => {
    return makeGetRequest('enum/project/company/teamsizes');
  };

  getFaqEnums = () => {
    return makeGetRequest('enum/project/faq/predefinedtypes');
  };

  getUserDemographicsAddressType = () => {
    return makeGetRequest('enum/user/demographics/addressTypes');
  };

  uploadEx = (formData, config = null) => {
    return makeUploadRequest('upload/ex', formData, true, config);
  };

  getCurrentTerms = (termGroup) => {
    return makeGetRequest(`term/currentterms/${termGroup}`);
  };

  downloadTerm = (termType) => {
    return makeGetBlobRequest('term/download/' + termType);
  };

  getCurrentPasswordPolicy = () => {
    return makeGetRequest('user/currentPasswordPolicy');
  };

  getStaticDocuments = (documentType, showNotifications = false) => {
    return makeGetRequest(`staticdocument/${documentType}`, showNotifications);
  };

  getAnnouncementUrl = () => {
    return makeGetRequest('common/announcement/current');
  };

  downloadFile = (url, isConfidential = true) => {
    let willItOpen = false;
    let service = Axios.create({
      cancelToken: Axios.CancelToken.source().token
    });
    service.interceptors.request.use((config) => {
      const authenticationToken = store.get('authentication')?.token;
      if (!!authenticationToken && isConfidential) {
        config.headers = {
          Authorization: `Bearer ${authenticationToken}`,
          'X-ApiVersion': '1'
        };
      }

      return config;
    });

    return service
      .request({
        method: 'GET',
        url: url,
        responseType: 'blob'
      })
      .then((response) => {
        const fileName = getFileNameFromContentDisposition(response.headers['content-disposition']);
        const fileType = response.headers['content-type'];

        //If the downloaded document is one of the following open in a new tab
        if (
          fileType === 'application/pdf' ||
          fileType === 'image/gif' ||
          fileType === 'image/jpeg' ||
          fileType === 'image/png'
        ) {
          const urlData = window.URL.createObjectURL(new Blob([response.data], { type: fileType }));
          willItOpen = true;

          return { willItOpen, data: urlData };
        }

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([response.data], { type: fileType }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: fileType }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          link.click();

          setTimeout(function () {
            window.URL.revokeObjectURL(url);
          }, 0);
        }

        return response.data;
      });
  };

  getClientParameters = () => {
    return makeGetRequest('parameter/client');
  };

  getGeoAddressCountries = () => {
    return makeGetRequest('geoaddress/countries');
  };

  getGeoAddressCountryStates = (payload) => {
    return makeGetRequest(`geoaddress/countries/${payload.countryCode}/states`);
  };

  getGeoAddressCountryStateCities = (payload) => {
    return makeGetRequest(
      `geoaddress/countries/${payload.countryCode}/states/${payload.stateOrProvinceCode}/cities`
    );
  };

  log = (payload) => {
    return makeLogRequest('log', payload);
  };
}

export const commonServiceModule = new CommonServiceModule();
