import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Input } from 'antd';
import styles from '../styles.module.scss';

const OpenAddressTextArea = (props) => {
  const [openAddress, SetOpenAddress] = useState('');
  useEffect(() => {
    const addressLine = props.address?.addressLine;
    const country = props.geoAddress?.countries?.response?.filter(
      (filter) => filter.iso2 === props.address?.country
    )[0]?.translations?.tr;
    const state = props.geoAddress?.countryStates?.response?.filter(
      (filter) => filter.stateCode === props.address?.state
    )[0]?.name;
    const city = props.geoAddress?.countryStateCities?.response?.filter(
      (filter) => filter.name === props.address?.city
    )[0]?.name;
    const formedOpenAddress = `${addressLine ?? ''} ${!!city && city !== 'none' ? city + '/' : ''}${
      !!state && state !== 'none' ? state + '/' : ''
    }${!!country ? country : ''}`;

    if (!!formedOpenAddress.trim()) {
      SetOpenAddress(formedOpenAddress);
    }
  });
  return (
    <Input.TextArea
      value={openAddress}
      className={styles.formInput}
      placeholder={props.profileInfo.openAddressPlaceholder}
      rows={4}
      disabled
    />
  );
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.language.dashboard.profile.profileInfo,
    userDemographics: state.userDemographics,
    geoAddress: state.geoAddress
  };
};

export default connect(mapStateToProps)(OpenAddressTextArea);
