import {
  GET_ALL_PROJECTS,
  GET_ALL_PROJECTS_SUCCESS,
  GET_ALL_PROJECTS_ERROR,
  RESET_ALL_PROJECTS,
  GET_ACTIVE_PROJECTS,
  GET_ACTIVE_PROJECTS_SUCCESS,
  GET_ACTIVE_PROJECTS_ERROR,
  GET_PRERELEASE_PROJECTS,
  GET_PRERELEASE_PROJECTS_SUCCESS,
  GET_PRERELEASE_PROJECTS_ERROR,
  GET_FINISHED_PROJECTS,
  GET_FINISHED_PROJECTS_SUCCESS,
  GET_FINISHED_PROJECTS_ERROR,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERROR,
  RESET_CATEGORIES
} from '../actions';

export const GetAllProjects = (payload) => {
  return {
    type: GET_ALL_PROJECTS,
    payload
  };
};

export const GetAllProjectsSuccess = (payload) => {
  return {
    type: GET_ALL_PROJECTS_SUCCESS,
    payload
  };
};

export const GetAllProjectsError = (payload) => {
  return {
    type: GET_ALL_PROJECTS_ERROR,
    payload
  };
};

export const ResetAllProjects = () => {
  return {
    type: RESET_ALL_PROJECTS
  };
};

export const GetActiveProjects = (payload) => {
  return {
    type: GET_ACTIVE_PROJECTS,
    payload
  };
};

export const GetActiveProjectsSuccess = (payload) => {
  return {
    type: GET_ACTIVE_PROJECTS_SUCCESS,
    payload
  };
};

export const GetActiveProjectsError = (payload) => {
  return {
    type: GET_ACTIVE_PROJECTS_ERROR,
    payload
  };
};

export const GetPreReleaseProjects = (payload) => {
  return {
    type: GET_PRERELEASE_PROJECTS,
    payload
  };
};

export const GetPreReleaseProjectsSuccess = (payload) => {
  return {
    type: GET_PRERELEASE_PROJECTS_SUCCESS,
    payload
  };
};

export const GetPreReleaseProjectsError = (payload) => {
  return {
    type: GET_PRERELEASE_PROJECTS_ERROR,
    payload
  };
};

export const GetFinishedProjects = (payload) => {
  return {
    type: GET_FINISHED_PROJECTS,
    payload
  };
};

export const GetFinishedProjectsSuccess = (payload) => {
  return {
    type: GET_FINISHED_PROJECTS_SUCCESS,
    payload
  };
};

export const GetFinishedProjectsError = (payload) => {
  return {
    type: GET_FINISHED_PROJECTS_ERROR,
    payload
  };
};

export const GetCategories = (payload) => {
  return {
    type: GET_CATEGORIES,
    payload
  };
};

export const GetCategoriesSuccess = (payload) => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload
  };
};

export const GetCategoriesError = (payload) => {
  return {
    type: GET_CATEGORIES_ERROR,
    payload
  };
};

export const ResetCategories = (payload) => {
  return {
    type: RESET_CATEGORIES,
    payload
  };
};
