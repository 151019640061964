import { getLanguage } from '../shared/language/language';

const defaultLanguage = 'TR';
let langDefault = localStorage.getItem('langDefault');

if (langDefault === null || langDefault === undefined) {
  localStorage.setItem('langDefault', defaultLanguage);

  langDefault = defaultLanguage;
}

const initialState = {
  ...(!!langDefault && langDefault.length !== 0 ? getLanguage(langDefault) : getLanguage()),
  currentLanguage: langDefault
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SWITCH_LANGUAGE':
      return {
        ...state,
        ...getLanguage(action.language),
        currentLanguage: action.language
      };
    default:
      return state;
  }
};
