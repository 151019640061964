import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'antd';
import { Col, Row } from 'react-bootstrap';
import store from 'store2';
import * as apiServices from './services/baseService';

function getCookie(key) {
  var b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';
}

const newVersionReminderStoreKey = 'newVersionReminder';
const keepAliveIntervalInSeconds = 60;
const remindMeLaterIntervalInSeconds = 5 * 60;

const CheckVersion = (props) => {
  const [remindModalVisible, SetRemindModalVisible] = useState(false);
  const [serverVersion, SetServerVersion] = useState(null);

  useEffect(() => {
    const unsubscribe = addEventListeners();

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, []);

  const addEventListeners = () => {
    keepAlive();

    return document.addEventListener('mousedown', (e) => {
      if (checkVersion()) e.preventDefault();
    });
  };

  const keepAlive = (callback) => {
    var request = new XMLHttpRequest();
    request.onreadystatechange = function () {
      if (request.readyState === XMLHttpRequest.DONE) {
        if (callback && typeof callback === 'function') {
          callback(request.getAllResponseHeaders());
        }
      }
    };

    request.open('HEAD', `${document.location.origin}/keepAlive`);
    request.send(null);

    setTimeout(keepAlive, keepAliveIntervalInSeconds * 1000);
  };

  const checkVersion = () => {
    let blockEvents = false;
    const localVersion = store.get('localVersion');
    const serverVersion = getCookie('applicationVersion');

    if (!!serverVersion) {
      if (localVersion !== serverVersion) {
        console.warn('New application version detected.');
        SetServerVersion(serverVersion);

        blockEvents = handleRemindMeLater();
      }
    }

    return blockEvents;
  };

  const handleRemindMeLater = () => {
    let blockEvents = false;
    const now = Date.now();
    const targetRemindMeLaterValue = store.get(newVersionReminderStoreKey);

    if (now > (targetRemindMeLaterValue ?? 0)) {
      blockEvents = true;

      apiServices.killGlobalCancellationToken();
      SetRemindModalVisible(true);
    }

    return blockEvents;
  };

  const doRemindMeLater = () => {
    const now = Date.now() + remindMeLaterIntervalInSeconds * 1000;

    store.set(newVersionReminderStoreKey, now, true);

    SetRemindModalVisible(false);
    apiServices.renewGlobalCancellationToken();
  };

  const handleOk = () => {
    store.set('localVersion', serverVersion, true);
    store.remove(newVersionReminderStoreKey);

    window.location.reload(true);
  };

  return (
    <div>
      <Modal
        visible={remindModalVisible}
        onCancel={doRemindMeLater}
        title={props.lang.newVersion.title}
        footer={null}
        centered
        maskClosable={false}
        zIndex={5000}
      >
        <Row>
          <Col>
            <Row>
              <span>{props.lang.newVersion.message}</span>
            </Row>
            <Row className="mt-2">
              <span style={{ fontWeight: 'bold', color: 'red' }}>
                {props.lang.newVersion.inputWarning}
              </span>
            </Row>
            <div className="text-right mt-4">
              <Button className="button mr-2" type="primary" onClick={handleOk}>
                {props.lang.common.btnApprove}
              </Button>
              <Button className="button" onClick={doRemindMeLater}>
                {props.lang.common.btnRemindMeLater}
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: {
      common: state.language.common,
      newVersion: state.language.newVersion
    }
  };
};

export default connect(mapStateToProps)(CheckVersion);
