import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Form, Input, Button, notification } from 'antd';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { projectServiceModule } from '../../../services/modules/projectServiceModule';

class ChangeRequest extends Component {
  constructor(props) {
    super(props);
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          title: values.title,
          description: values.description
        };

        projectServiceModule
          .postChangeRequest(this.props.location.state.projectId, payload)
          .then((response) => {
            notification.success({
              description: this.props.lang.successMessage,
              placement: 'bottomRight'
            });
          });
      }
    });
  };

  render() {
    const { lang, common } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Fragment>
        <Row>
          <div className={classnames('col-md-12 col-sm-12 col-xs-12', styles.block)}>
            <Row>
              <Col md={12}>
                <h4>{lang.formTitle}</h4>
                <div className={styles.container}>
                  <Form onSubmit={this.handleSubmit}>
                    <Form.Item label={lang.title}>
                      {getFieldDecorator('title', {
                        rules: [
                          {
                            required: true,
                            message: common.requiredField
                          },
                          {
                            max: 255,
                            message: common.errorMessageMax255
                          }
                        ]
                      })(<Input className="w-100" />)}
                    </Form.Item>
                    <Form.Item label={lang.description}>
                      {getFieldDecorator('description', {
                        rules: [
                          {
                            required: true,
                            message: common.requiredField
                          },
                          {
                            max: 500,
                            message: common.errorMessageMax500
                          }
                        ]
                      })(<Input.TextArea rows={4} className="w-100" />)}
                    </Form.Item>
                    <Form.Item className="text-right">
                      <Button type="primary" htmlType="submit">
                        {common.btnSend}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.language.dashboard.changeRequest,
    common: state.language.common
  };
};

export default connect(mapStateToProps)(Form.create({ name: 'change-request' })(ChangeRequest));
