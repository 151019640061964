import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { userServiceModule } from '../../../services/modules/userServiceModule';
import { commonServiceModule } from '../../../services/modules/commonServiceModule';
import styles from './styles.module.scss';

const LegalInfo = (props) => {
  const [terms, setTerms] = useState([]);

  useEffect(() => {
    userServiceModule
      .getAcceptedTerms()
      .then((response) => {
        setTerms(Object.values(response));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const downloadTerm = (e, termId) => {
    e.preventDefault();

    commonServiceModule
      .downloadTerm(termId)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  };

  return (
    <Row className={classnames(styles.container, 'p-5 mr-md-2')}>
      <Col>
        <h5>{props.data.title}</h5>
        <ul>
          {terms.map((term) => {
            const langPropAccessor = term.termType.charAt(0).toLowerCase() + term.termType.slice(1);

            return (
              <li key={`li_${term.id}`}>
                <span>
                  <a
                    href="/"
                    target="_blank"
                    onClick={(e) => {
                      downloadTerm(e, term.id);
                    }}
                  >
                    {props.data[langPropAccessor]}
                  </a>
                </span>
              </li>
            );
          })}
        </ul>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.language.dashboard.profile.legalInfo
  };
};

export default connect(mapStateToProps)(LegalInfo);
