import React, { Fragment, useEffect } from 'react';
import { Col, Row, BackTop } from 'antd';
import Footer from '../../../components/footer/index';
import Header from '../../../components/header/header/index';
import { initGA, logPageView } from '../../../shared/googlAnalytics/analytics';
import { useLocation } from 'react-router-dom';

const Forbidden = (props) => {
  const location = useLocation();
  useEffect(() => {
    initGA();
    logPageView();
  }, [location]);

  return (
    <Fragment>
      <Row
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexFlow: 'column'
        }}
      >
        <Col
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            flexFlow: 'column'
          }}
        >
          <Row style={{ flex: '0 1 auto' }}>
            <Header />
          </Row>
          <Row style={{ flex: '1 1 auto' }}>{props.children}</Row>
          <Row style={{ flex: '0 1 0' }}>
            <BackTop />
            <Footer />
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Forbidden;
