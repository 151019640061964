import { Button, Checkbox, Form, Input, notification } from 'antd';
import { Col, Row } from 'react-bootstrap';

import ProjectUpdate from '../../project/update/ProjectUpdate';
import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import styles from './styles.module.scss';
import { GetProjectUpdates, ResetProjectUpdates } from '../../../redux/ProjectDetail/actions';
import { buildQueryString, defaultSpfParameters } from '../../../helpers/http/request';
import { projectServiceModule } from '../../../services/modules/projectServiceModule';
import { Uploader } from '../../custom/index';

class ProjectUpdates extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projectUpdates: [],
      documentList: []
    };

    this.baseState = this.state;
  }

  componentDidMount() {
    this.GetProjectUpdates();
  }

  componentWillUnmount() {
    this.props.ResetProjectUpdates();
  }

  GetProjectUpdates = (page = null) => {
    const queryString = buildQueryString({
      ...defaultSpfParameters,
      page
    });

    const payload = {
      projectId: this.props.location?.state?.projectId,
      queryString
    };

    this.props.GetProjectUpdates(payload);
  };

  uploadDocumentSuccess = (uploadDocument) => {
    let documentObj = {
      key: uploadDocument.storageKey,
      uid: '-1',
      name: `${uploadDocument.documentTitle} - ${uploadDocument.name}`,
      status: 'done',
      url: uploadDocument.url,
      documentTitle: uploadDocument.documentTitle
    };

    let documentList = [...this.state.documentList, documentObj];

    this.setState(() => ({ documentList }));

    notification.success({
      description: this.props.data.uploadMessage,
      placement: 'bottomRight'
    });
  };

  removeDocument = (objectToDelete) => {
    this.setState((state) => ({
      documentList: state.documentList.filter((document) => document !== objectToDelete)
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let updateObj = {
          title: values.title,
          text: values.text,
          forInvestorsOnly: values.forInvestorsOnly,
          documents: this.state.documentList.map((item) => {
            return {
              storageKey: item.key,
              documentTitle: item.documentTitle
            };
          })
        };

        let projectId = this.props.location.state.projectId;

        projectServiceModule.createProjectUpdate(projectId, updateObj).then((response) => {
          notification.success({
            message: this.props.data.updateNotifyTitle,
            description: this.props.data.updateNotify,
            placement: 'bottomRight'
          });

          this.setState(this.baseState);

          const formValues = {
            title: '',
            text: '',
            forInvestorsOnly: false
          };

          this.props.form.setFieldsValue(formValues);
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        <Row>
          <div className={classnames('col-md-12 col-sm-12 col-xs-12', styles.block)}>
            <Row>
              <Col md={12}>
                <h4>{this.props.data.header}</h4>
                <div className={styles.container}>
                  <Form onSubmit={this.handleSubmit}>
                    <Form.Item label={this.props.data.title}>
                      {getFieldDecorator('title', {
                        rules: [
                          {
                            required: true,
                            message: this.props.common.requiredField
                          },
                          {
                            max: 255,
                            message: this.props.common.errorMessageMax255
                          }
                        ]
                      })(<Input className="w-100" />)}
                    </Form.Item>
                    <Form.Item label={this.props.data.description}>
                      {getFieldDecorator('text', {
                        rules: [
                          {
                            required: true,
                            message: this.props.common.requiredField
                          },
                          {
                            max: 500,
                            message: this.props.common.errorMessageMax500
                          }
                        ]
                      })(<Input.TextArea rows={4} className="w-100" />)}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator('forInvestorsOnly', {
                        valuePropName: 'checked'
                      })(<Checkbox>{this.props.data.forInvestorsOnlyCheckboxText}</Checkbox>)}
                    </Form.Item>
                    <Form.Item label={this.props.data.documents} hasFeedback={false}>
                      {getFieldDecorator('UpdateDocuments', {
                        validateTrigger: ['onSuccess', 'onRemove'],
                        rules: [{ required: false }],
                        initialValue: this.state.documentList
                      })(
                        <Uploader
                          key="UpdateDocuments"
                          name="Documents"
                          documentUpload={true}
                          accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                          fileList={this.state.documentList}
                          onSuccess={this.uploadDocumentSuccess}
                          onRemove={this.removeDocument}
                          text={this.props.data.btnUpload}
                        />
                      )}
                    </Form.Item>
                    <Form.Item className="text-right">
                      <Button type="primary" htmlType="submit">
                        {this.props.data.share}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Col>
            </Row>
            <Row>
              {this.props?.updates?.response?.data !== undefined &&
              Object.keys(this.props?.updates?.response?.data).length > 0 ? (
                <Col md={12}>
                  <h4>{this.props.data.prevUpdates}</h4>
                  <Form>
                    <ProjectUpdate projectUpdates={this.props?.updates?.response?.data} />
                  </Form>
                </Col>
              ) : (
                <Col md={12}>
                  <h4>{this.props.data.noUpdates}</h4>
                </Col>
              )}
            </Row>
          </div>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.language.dashboard.updates,
    common: state.language.common,
    updates: state.projectDetail.updates
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetProjectUpdates: (payload) => dispatch(GetProjectUpdates(payload)),
    ResetProjectUpdates: () => dispatch(ResetProjectUpdates())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'project-update' })(ProjectUpdates));
