import {
  GET_CLIENT_PARAMETERS,
  GET_CLIENT_PARAMETERS_SUCCESS,
  GET_CLIENT_PARAMETERS_ERROR,
  RESET_CLIENT_PARAMETERS
} from '../actions';

export const GetClientParameters = () => {
  return {
    type: GET_CLIENT_PARAMETERS
  };
};

export const GetClientParametersSuccess = (payload) => {
  return {
    type: GET_CLIENT_PARAMETERS_SUCCESS,
    payload
  };
};

export const GetClientParametersError = (payload) => {
  return {
    type: GET_CLIENT_PARAMETERS_ERROR,
    payload
  };
};

export const ResetClientParameters = () => {
  return {
    type: RESET_CLIENT_PARAMETERS
  };
};
