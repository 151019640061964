import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Avatar, Dropdown, Icon, Select, Affix } from 'antd';
import { Button, Col, Nav, NavDropdown, Navbar, Row } from 'react-bootstrap';
import store from 'store2';
import classnames from 'classnames';
import { GetRegularUser } from '../../../redux/RegularUser/actions';
import { switchLanguage } from '../../../actions/languageActions';
import { userServiceModule } from '../../../services/modules/userServiceModule';
import Brand from '../brand';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userDetails: {},
      lang: localStorage.getItem('langDefault') ?? 'TR',
      navExpanded: false
    };

    this.navbarDropdownItems = [
      {
        href: '/about',
        text: this.props.data.about
      },
      {
        href: '/investment-committee',
        text: this.props.data.investmentCommittee
      },
      {
        href: '/faq',
        text: this.props.data.faq
      },
      {
        href: '/announcements',
        text: this.props.data.announcements
      },
      {
        href:'http://fontrgsyf.com.tr/',
        text: 'FonTR GSYF',
        external:true
      },
      
      {
        href: '/contact',
        text: this.props.data.communication
      },
      {
        href: '/scholarship',
        text: this.props.data.scholarship
      },
      {
        href: '/blog',
        text: this.props.data.blog
      },   
      {
        href: '/press',
        text: this.props.data.press
      }
    ];
  }
  
  componentDidMount = () => {
    if (!!store.get('authentication') && !!!this.props.regularUser?.isLoaded) {
      this.props.GetRegularUser();
    }
  };

  componentWillUnmount() {
    this.setState({ navExpanded: false });
  }

  handleExternalLinkClick = (href, e) => {
    e.preventDefault();
    window.open(href, '_blank');
    e.currentTarget.style.outline = 'none';
    e.currentTarget.style.backgroundColor = 'transparent';
    e.currentTarget.blur();
  };
  
  getNavbarDropdownItems = () => {
    return this.navbarDropdownItems.map((item, index) => {
      if (item.external) {
        return (
          <NavDropdown.Item
            key={`dropdown_item_${index}`}
            href={item.href}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => this.handleExternalLinkClick(item.href, e)}
            style={{ cursor: 'pointer' }}
          >
            {item.text}
          </NavDropdown.Item>
        );
      } else {
        return (
          <NavDropdown.Item
            key={`dropdown_item_${index}`}
            as={Link}
            to={item.href}
            onClick={(e) => {
              e.currentTarget.style.outline = 'none';
              e.currentTarget.style.backgroundColor = 'transparent';
              e.currentTarget.blur();
            }}
          >
            {item.text}
          </NavDropdown.Item>
        );
      }
    });
  };

  langChange = (value) => {
    this.props.SwitchLanguage(value);
    this.setState({ lang: value });
    localStorage.setItem('langDefault', value);
  };

  navSelected = () => {
    this.setState({ navExpanded: !this.state.navExpanded });
  };

  closeNav = () => {
    this.setState({ navExpanded: false });
  };

  userNavigation = ({ myAccount, logout }) => {
    return (
      <React.Fragment>
        <div>
          <Link onClick={this.closeNav} to="/dashboard/profile">
            <Icon type="book" />
            {myAccount}
          </Link>
        </div>
        <div onClick={() => userServiceModule.logoutUser()} className={styles.logout}>
          <Icon type="logout" />
          {logout}
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { Option } = Select;
    const isAuthenticated = !!store.get('authentication');

    let { pathname } = !!this.props.location && this.props.location;
    pathname = pathname.replace(/\//gi, '');

    return pathname !== 'mkklanding' ? (
      <Affix offsetTop={0}>
  <Navbar
    expand="lg"
    className={classnames(styles.nav, 'ml-0 pl-0 mr-0 pr-0')}
    onToggle={this.navSelected}
    expanded={this.state.navExpanded}
  >
    <Brand closeNav={this.closeNav} />
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Col className={styles.navigation}>
      <Navbar.Collapse id="basic-navbar-nav" className={styles.homeItem}>
        <Nav className={classnames('mr-auto', styles.navbarBar)}>
          <Link
            onClick={this.closeNav}
            to="/createProject"
            className="nav-link"
            role="button"
          >
            {this.props.data.createProject}
          </Link>

          <NavDropdown className="ml-5" title={this.props.data.more} id="basic-nav-dropdown">
            {this.getNavbarDropdownItems()}
          </NavDropdown>
        </Nav>
        <Nav>
          <Link
            onClick={this.closeNav}
            className={classnames(
              'btn btn-primary',
              styles.createProject,
              styles.headerNavLink
            )}
            to={{ state: { clearFilters: true }, pathname: '/projects' }}
          >
            {this.props.data.projects}
          </Link>
          {!!!isAuthenticated && (
            <Link
              onClick={this.closeNav}
              className={classnames(styles.headerNavLink, styles.custom, 'd-none d-lg-block')}
              to={'/login'}
            >
              <Button variant="outline-secondary" className={styles.homeButtonSign}>
                {this.props.data.login}
              </Button>
            </Link>
          )}
          {!!isAuthenticated && (
            <div
              onClick={this.closeNav}
              className={classnames(styles.headerNavLink, styles.custom, 'd-none d-lg-flex')}
            >
              {!!this.props.regularUser?.isLoaded && (
                <Row className={classnames(styles.homeButtonSigned)}>
                  {!!this.props.regularUser?.identityVerified ? (
                    <Col className="pl-0 pr-0 d-flex justify-content-center">
                      <Link to={'/dashboard'} className={styles.username}>
                        {this.props.regularUser?.fullName}
                      </Link>
                    </Col>
                  ) : (
                    <Col
                      className={classnames(styles.usernameContainer, styles.headerNavLink)}
                    >
                      <p className={classnames(styles.bkyContainer, styles.headerBky)}>
                        {this.props.regularUser?.hasBkyLicence ? (
                          <Link className={styles.headerBky} to="/dashboard/profile">
                            BKY Lisanslı
                          </Link>
                        ) : (
                          <Link className={styles.headerBky} to="/dashboard/profile">
                            {this.props.data.completeAccount}
                          </Link>
                        )}
                      </p>
                    </Col>
                  )}
                </Row>
              )}
            </div>
          )}
          {!!isAuthenticated && (
            <Row className={classnames(styles.homeButtonSigned, styles.headerNavLink)}>
              <Col className={classnames(styles.dropdownUsermenu, 'pl-0 pr-0 ')}>
                <Dropdown
                  overlay={
                    isAuthenticated && (
                      <div className={styles.headerDropdown}>
                        {this.userNavigation(this.props.data)}
                      </div>
                    )
                  }
                  trigger={['click']}
                >
                  <div>
                    <Avatar className={styles.avatar}>
                      <FontAwesomeIcon icon={faUser} />
                    </Avatar>
                  </div>
                </Dropdown>
              </Col>
              <Col className={styles.mobileUsermenu}>
                {this.userNavigation(this.props.data)}
              </Col>
            </Row>
          )}
          <Select
            value={this.state.lang}
            onChange={this.langChange}
            className={classnames(styles.languageSelector, styles.headerNavLink)}
          >
            <Option value="TR">{this.props.data.tr}</Option>
            <Option value="EN">{this.props.data.en}</Option>
          </Select>
        </Nav>
      </Navbar.Collapse>
      <Nav className={classnames('d-lg-none', 'mt-3', 'justify-content-end', 'ml-0 pl-0 mr-0 pr-0')}>
        {!!!isAuthenticated && (
          <Link
            onClick={this.closeNav}
            className={classnames(styles.headerNavLink, styles.custom)}
            style={{ background: 'white' }}
            to={'/login'}
          >
            <Button
        variant="outline-secondary"
        className={classnames(styles.homeButtonSign, 'ml-auto')}
      >
        {this.props.data.login}
      </Button>
          </Link>
        )}
      </Nav>
    </Col>
  </Navbar>
</Affix>
    ) : (
      <Col xs="auto" className="d-flex justify-content-center align-items-center">
        <Navbar
          expand="lg"
          className={classnames(styles.nav, 'ml-0 pl-0 mr-0 pr-0')}
          onToggle={this.navSelected}
          expanded={this.state.navExpanded}
        >
          <Brand closeNav={this.closeNav} />
        </Navbar>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.language.header,
    regularUser: state.regularUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetRegularUser: () => dispatch(GetRegularUser()),
    SwitchLanguage: (value) => dispatch(switchLanguage(value))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
