import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import { loadResponse } from '../../helpers/http/response';
import { commonServiceModule } from '../../services/modules/commonServiceModule';

import { GET_CLIENT_PARAMETERS } from '../actions';
import { GetClientParametersSuccess, GetClientParametersError } from './actions';

export default function* rootSaga() {
  yield all([fork(watchGetClientParameters)]);
}

function* watchGetClientParameters() {
  yield takeLeading(GET_CLIENT_PARAMETERS, GetClientParameters);
}

function* GetClientParameters(action) {
  try {
    const response = yield call(GetClientParametersAsync, action);
    const payload = loadResponse(action.payload, response);
    yield put(GetClientParametersSuccess(payload));
  } catch (error) {
    const errorPayload = {
      payload: action.payload,
      error
    };
    yield put(GetClientParametersError(errorPayload));
  }
}

const GetClientParametersAsync = () => {
  return commonServiceModule.getClientParameters();
};
