/* AUTHENTICATION */
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS';
export const AUTHENTICATE_USER_ERROR = 'AUTHENTICATE_USER_ERROR';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_ERROR = 'LOGOUT_USER_ERROR';

/* REGULAR USER */
export const GET_REGULAR_USER = 'GET_REGULAR_USER';
export const GET_REGULAR_USER_SUCCESS = 'GET_REGULAR_USER_SUCCESS';
export const GET_REGULAR_USER_ERROR = 'GET_REGULAR_USER_ERROR';
export const RESET_REGULAR_USER = 'RESET_REGULAR_USER';

export const PUT_MKK_SCL_NUMBER = 'PUT_MKK_SCL_NUMBER';
export const PUT_MKK_SCL_NUMBER_SUCCESS = 'PUT_MKK_SCL_NUMBER_SUCCESS';
export const PUT_MKK_SCL_NUMBER_ERROR = 'PUT_MKK_SCL_NUMBER_ERROR';

/* BULLETIN */
export const SUBSCRIBE_TO_BULLETIN = 'SUBSCRIBE_TO_BULLETIN';
export const SUBSCRIBE_TO_BULLETIN_SUCCESS = 'SUBSCRIBE_TO_BULLETIN_SUCCESS';
export const SUBSCRIBE_TO_BULLETIN_ERROR = 'SUBSCRIBE_TO_BULLETIN_ERROR';

/* NOTIFICATION */
export const NOTIFY = 'NOTIFY';

/* PROJECTS */
export const GET_ALL_PROJECTS = 'GET_ALL_PROJECTS';
export const GET_ALL_PROJECTS_SUCCESS = 'GET_ALL_PROJECTS_SUCCESS';
export const GET_ALL_PROJECTS_ERROR = 'GET_ALL_PROJECTS_ERROR';
export const RESET_ALL_PROJECTS = 'RESET_ALL_PROJECTS';

export const GET_ACTIVE_PROJECTS = 'GET_ACTIVE_PROJECTS';
export const GET_ACTIVE_PROJECTS_SUCCESS = 'GET_ACTIVE_PROJECTS_SUCCESS';
export const GET_ACTIVE_PROJECTS_ERROR = 'GET_ACTIVE_PROJECTS_ERROR';
export const RESET_ACTIVE_PROJECTS = 'RESET_ACTIVE_PROJECTS';

export const GET_PRERELEASE_PROJECTS = 'GET_PRERELEASE_PROJECTS';
export const GET_PRERELEASE_PROJECTS_SUCCESS = 'GET_PRERELEASE_PROJECTS_SUCCESS';
export const GET_PRERELEASE_PROJECTS_ERROR = 'GET_PRERELEASE_PROJECTS_ERROR';
export const RESET_PRERELEASE_PROJECTS = 'RESET_PRERELEASE_PROJECTS';

export const GET_FINISHED_PROJECTS = 'GET_FINISHED_PROJECTS';
export const GET_FINISHED_PROJECTS_SUCCESS = 'GET_FINISHED_PROJECTS_SUCCESS';
export const GET_FINISHED_PROJECTS_ERROR = 'GET_FINISHED_PROJECTS_ERROR';
export const RESET_FINISHED_PROJECTS = 'RESET_FINISHED_PROJECTS';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';
export const RESET_CATEGORIES = 'RESET_CATEGORIES';

/* PROJECTDETAIL */

export const SET_ACTIVE_PROJECT_DETAIL_TAB_KEY = 'SET_ACTIVE_PROJECT_DETAIL_TAB_KEY';
export const RESET_ACTIVE_PROJECT_DETAIL_TAB_KEY = 'RESET_ACTIVE_PROJECT_DETAIL_TAB_KEY';

export const GET_PROJECT_TABLES_INVESTMENT_DETAILS = 'GET_PROJECT_TABLES_INVESTMENT_DETAILS';
export const GET_PROJECT_TABLES_INVESTMENT_DETAILS_SUCCESS =
  'GET_PROJECT_TABLES_INVESTMENT_DETAILS_SUCCESS';
export const GET_PROJECT_TABLES_INVESTMENT_DETAILS_ERROR =
  'GET_PROJECT_TABLES_INVESTMENT_DETAILS_ERROR';
export const RESET_PROJECT_TABLES_INVESTMENT_DETAILS = 'RESET_PROJECT_TABLES_INVESTMENT_DETAILS';

export const GET_PROJECT_TABLES_INVESTOR_DETAILS = 'GET_PROJECT_TABLES_INVESTOR_DETAILS';
export const GET_PROJECT_TABLES_INVESTOR_DETAILS_SUCCESS =
  'GET_PROJECT_TABLES_INVESTOR_DETAILS_SUCCESS';
export const GET_PROJECT_TABLES_INVESTOR_DETAILS_ERROR =
  'GET_PROJECT_TABLES_INVESTOR_DETAILS_ERROR';
export const RESET_PROJECT_TABLES_INVESTOR_DETAILS = 'RESET_PROJECT_TABLES_INVESTOR_DETAILS';

export const GET_PROJECT_FUNDING_INFO = 'GET_PROJECT_FUNDING_INFO';
export const GET_PROJECT_FUNDING_INFO_SUCCESS = 'GET_PROJECT_FUNDING_INFO_SUCCESS';
export const GET_PROJECT_FUNDING_INFO_ERROR = 'GET_PROJECT_FUNDING_INFO_ERROR';
export const RESET_PROJECT_FUNDING_INFO = 'RESET_PROJECT_FUNDING_INFO';

export const GET_PROJECT_DAILY_SPREAD = 'GET_PROJECT_DAILY_SPREAD';
export const GET_PROJECT_DAILY_SPREAD_SUCCESS = 'GET_PROJECT_DAILY_SPREAD_SUCCESS';
export const GET_PROJECT_DAILY_SPREAD_ERROR = 'GET_PROJECT_DAILY_SPREAD_ERROR';
export const RESET_PROJECT_DAILY_SPREAD = 'RESET_PROJECT_DAILY_SPREAD';

export const GET_PROJECT_COMPLETION_SUMMARY = 'GET_PROJECT_COMPLETION_SUMMARY';
export const GET_PROJECT_COMPLETION_SUMMARY_SUCCESS = 'GET_PROJECT_COMPLETION_SUMMARY_SUCCESS';
export const GET_PROJECT_COMPLETION_SUMMARY_ERROR = 'GET_PROJECT_COMPLETION_SUMMARY_ERROR';
export const RESET_PROJECT_COMPLETION_SUMMARY = 'RESET_PROJECT_COMPLETION_SUMMARY';

export const GET_PROJECT_INFO = 'GET_PROJECT_INFO';
export const GET_PROJECT_INFO_SUCCESS = 'GET_PROJECT_INFO_SUCCESS';
export const GET_PROJECT_INFO_ERROR = 'GET_PROJECT_INFO_ERROR';
export const RESET_PROJECT_INFO = 'RESET_PROJECT_INFO';

export const GET_PROJECT_DETAILS = 'GET_PROJECT_DETAILS';
export const GET_PROJECT_DETAILS_SUCCESS = 'GET_PROJECT_DETAILS_SUCCESS';
export const GET_PROJECT_DETAILS_ERROR = 'GET_PROJECT_DETAILS_ERROR';
export const RESET_PROJECT_DETAILS = 'RESET_PROJECT_DETAILS';

export const GET_PROJECT_COMPANY = 'GET_PROJECT_COMPANY';
export const GET_PROJECT_COMPANY_SUCCESS = 'GET_PROJECT_COMPANY_SUCCESS';
export const GET_PROJECT_COMPANY_ERROR = 'GET_PROJECT_COMPANY_ERROR';
export const RESET_PROJECT_COMPANY = 'RESET_PROJECT_COMPANY';

export const GET_PROJECT_DOCUMENTS = 'GET_PROJECT_DOCUMENTS';
export const GET_PROJECT_DOCUMENTS_SUCCESS = 'GET_PROJECT_DOCUMENTS_SUCCESS';
export const GET_PROJECT_DOCUMENTS_ERROR = 'GET_PROJECT_DOCUMENTS_ERROR';
export const RESET_PROJECT_DOCUMENTS = 'RESET_PROJECT_DOCUMENTS';

export const GET_PROJECT_IMAGES = 'GET_PROJECT_IMAGES';
export const GET_PROJECT_IMAGES_SUCCESS = 'GET_PROJECT_IMAGES_SUCCESS';
export const GET_PROJECT_IMAGES_ERROR = 'GET_PROJECT_IMAGES_ERROR';
export const RESET_PROJECT_IMAGES = 'RESET_PROJECT_IMAGES';

export const GET_PROJECT_FAQS = 'GET_PROJECT_FAQS';
export const GET_PROJECT_FAQS_SUCCESS = 'GET_PROJECT_FAQS_SUCCESS';
export const GET_PROJECT_FAQS_ERROR = 'GET_PROJECT_FAQS_ERROR';
export const RESET_PROJECT_FAQS = 'RESET_PROJECT_FAQS';

export const GET_PROJECT_UPDATES = 'GET_PROJECT_UPDATES';
export const GET_PROJECT_UPDATES_SUCCESS = 'GET_PROJECT_UPDATES_SUCCESS';
export const GET_PROJECT_UPDATES_ERROR = 'GET_PROJECT_UPDATES_ERROR';
export const RESET_PROJECT_UPDATES = 'RESET_PROJECT_UPDATES';

export const GET_PROJECT_PARENT_COMMENTS = 'GET_PROJECT_PARENT_COMMENTS';
export const GET_PROJECT_PARENT_COMMENTS_SUCCESS = 'GET_PROJECT_PARENT_COMMENTS_SUCCESS';
export const GET_PROJECT_PARENT_COMMENTS_ERROR = 'GET_PROJECT_PARENT_COMMENTS_ERROR';
export const RESET_PROJECT_PARENT_COMMENTS = 'RESET_PROJECT_PARENT_COMMENTS';

export const GET_PROJECT_CHILD_COMMENTS = 'GET_PROJECT_CHILD_COMMENTS';
export const GET_PROJECT_CHILD_COMMENTS_SUCCESS = 'GET_PROJECT_CHILD_COMMENTS_SUCCESS';
export const GET_PROJECT_CHILD_COMMENTS_ERROR = 'GET_PROJECT_CHILD_COMMENTS_ERROR';
export const RESET_PROJECT_CHILD_COMMENTS = 'RESET_PROJECT_CHILD_COMMENTS';

export const GET_PROJECT_TEAM_MEMBERS = 'GET_PROJECT_TEAM_MEMBERS';
export const GET_PROJECT_TEAM_MEMBERS_SUCCESS = 'GET_PROJECT_TEAM_MEMBERS_SUCCESS';
export const GET_PROJECT_TEAM_MEMBERS_ERROR = 'GET_PROJECT_TEAM_MEMBERS_ERROR';
export const RESET_PROJECT_TEAM_MEMBERS = 'RESET_PROJECT_TEAM_MEMBERS';

export const GET_PROJECT_PARTICIPANTS = 'GET_PROJECT_PARTICIPANTS';
export const GET_PROJECT_PARTICIPANTS_SUCCESS = 'GET_PROJECT_PARTICIPANTS_SUCCESS';
export const GET_PROJECT_PARTICIPANTS_ERROR = 'GET_PROJECT_PARTICIPANTS_ERROR';
export const RESET_PROJECT_PARTICIPANTS = 'RESET_PROJECT_PARTICIPANTS';

export const SET_IS_PREVIEW = 'SET_IS_PREVIEW';

export const SET_IS_WIZARD_MESSAGE_RECEIVED = 'SET_IS_WIZARD_MESSAGE_RECEIVED';

export const SET_IS_FAVORED = 'SET_IS_FAVORED';

/* DASHBOARD */

export const GET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE =
  'GET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE';
export const GET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE_SUCCESS =
  'GET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE_SUCCESS';
export const GET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE_ERROR =
  'GET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE_ERROR';
export const RESET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE =
  'RESET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE';

export const GET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT =
  'GET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT';
export const GET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT_SUCCESS =
  'GET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT_SUCCESS';
export const GET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT_ERROR =
  'GET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT_ERROR';
export const RESET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT =
  'RESET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT';

export const GET_USER_PROJECTS = 'GET_USER_PROJECTS';
export const GET_USER_PROJECTS_SUCCESS = 'GET_USER_PROJECTS_SUCCESS';
export const GET_USER_PROJECTS_ERROR = 'GET_USER_PROJECTS_ERROR';

export const GET_USER_FAVORITE_PROJECTS = 'GET_USER_FAVORITE_PROJECTS';
export const GET_USER_FAVORITE_PROJECTS_SUCCESS = 'GET_USER_FAVORITE_PROJECTS_SUCCESS';
export const GET_USER_FAVORITE_PROJECTS_ERROR = 'GET_USER_FAVORITE_PROJECTS_ERROR';

export const USER_FAVORITE_PROJECT = 'USER_FAVORITE_PROJECT';
export const USER_FAVORITE_PROJECT_SUCCESS = 'USER_FAVORITE_PROJECT_SUCCESS';
export const USER_FAVORITE_PROJECT_ERROR = 'USER_FAVORITE_PROJECT_ERROR';
export const RESET_USER_FAVORITE_PROJECT = 'RESET_USER_FAVORITE_PROJECT';

export const GET_GEO_ADDRESS_COUNTRIES = 'GET_GEO_ADDRESS_COUNTRIES';
export const GET_GEO_ADDRESS_COUNTRIES_SUCCESS = 'GET_GEO_ADDRESS_COUNTRIES_SUCCESS';
export const GET_GEO_ADDRESS_COUNTRIES_ERROR = 'GET_GEO_ADDRESS_COUNTRIES_ERROR';
export const RESET_GEO_ADDRESS_COUNTRIES = 'RESET_GEO_ADDRESS_COUNTRIES';

export const GET_GEO_ADDRESS_COUNTRY_STATES = 'GET_GEO_ADDRESS_COUNTRY_STATES';
export const GET_GEO_ADDRESS_COUNTRY_STATES_SUCCESS = 'GET_GEO_ADDRESS_COUNTRY_STATES_SUCCESS';
export const GET_GEO_ADDRESS_COUNTRY_STATES_ERROR = 'GET_GEO_ADDRESS_COUNTRY_STATES_ERROR';
export const RESET_GEO_ADDRESS_COUNTRY_STATES = 'RESET_GEO_ADDRESS_COUNTRY_STATES';

export const GET_GEO_ADDRESS_COUNTRY_STATE_CITIES = 'GET_GEO_ADDRESS_COUNTRY_STATE_CITIES';
export const GET_GEO_ADDRESS_COUNTRY_STATE_CITIES_SUCCESS =
  'GET_GEO_ADDRESS_COUNTRY_STATE_CITIES_SUCCESS';
export const GET_GEO_ADDRESS_COUNTRY_STATE_CITIES_ERROR =
  'GET_GEO_ADDRESS_COUNTRY_STATE_CITIES_ERROR';
export const RESET_GEO_ADDRESS_COUNTRY_STATE_CITIES = 'RESET_GEO_ADDRESS_COUNTRY_STATE_CITIES';

export const GET_USER_DEMOGRAPHICS = 'GET_USER_DEMOGRAPHICS';
export const GET_USER_DEMOGRAPHICS_SUCCESS = 'GET_USER_DEMOGRAPHICS_SUCCESS';
export const GET_USER_DEMOGRAPHICS_ERROR = 'GET_USER_DEMOGRAPHICS_ERROR';
export const RESET_GET_USER_DEMOGRAPHICS = 'RESET_GET_USER_DEMOGRAPHICS';

export const GET_USER_DEMOGRAPHICS_ADDRESS_TYPE = 'GET_USER_DEMOGRAPHICS_ADDRESS_TYPE';
export const GET_USER_DEMOGRAPHICS_ADDRESS_TYPE_SUCCESS =
  'GET_USER_DEMOGRAPHICS_ADDRESS_TYPE_SUCCESS';
export const GET_USER_DEMOGRAPHICS_ADDRESS_TYPE_ERROR = 'GET_USER_DEMOGRAPHICS_ADDRESS_TYPE_ERROR';
export const RESET_USER_DEMOGRAPHICS_ADDRESS_TYPE = 'RESET_USER_DEMOGRAPHICS_ADDRESS_TYPE';

export const PATCH_USER_DEMOGRAPHICS = 'PATCH_USER_DEMOGRAPHICS';
export const PATCH_USER_DEMOGRAPHICS_SUCCESS = 'PATCH_USER_DEMOGRAPHICS_SUCCESS';
export const PATCH_USER_DEMOGRAPHICS_ERROR = 'PATCH_USER_DEMOGRAPHICS_ERROR';
export const RESET_PATCH_USER_DEMOGRAPHICS = 'RESET_PATCH_USER_DEMOGRAPHICS';

/* PROJECT WIZARD */

export const SET_PROJECT_WIZARD_REFERENCE_CODE = 'SET_PROJECT_WIZARD_REFERENCE_CODE';
export const RESET_PROJECT_WIZARD_REFERENCE_CODE = 'RESET_PROJECT_WIZARD_REFERENCE_CODE';

export const SET_IS_API_CALL_COMPLETED = 'SET_IS_API_CALL_COMPLETED';
export const RESET_IS_API_CALL_COMPLETED = 'RESET_IS_API_CALL_COMPLETED';

export const UPDATE_PROJECT_WIZARD_FUNDING_INFO = 'UPDATE_PROJECT_WIZARD_FUNDING_INFO';
export const UPDATE_PROJECT_WIZARD_FUNDING_INFO_SUCCESS =
  'UPDATE_PROJECT_WIZARD_FUNDING_INFO_SUCCESS';
export const UPDATE_PROJECT_WIZARD_FUNDING_INFO_FAIL = 'UPDATE_PROJECT_WIZARD_FUNDING_INFO_FAIL';
export const RESET_PROJECT_WIZARD_FUNDING_INFO = 'RESET_PROJECT_WIZARD_FUNDING_INFO';

export const UPSERT_PROJECT_WIZARD_PROJECT_DOCUMENTS = 'UPSERT_PROJECT_WIZARD_PROJECT_DOCUMENTS';
export const UPSERT_PROJECT_WIZARD_DOCUMENTS_PROJECT_SUCCESS =
  'UPSERT_PROJECT_WIZARD_DOCUMENTS_PROJECT_SUCCESS';
export const UPSERT_PROJECT_WIZARD_DOCUMENTS_PROJECT_FAIL =
  'UPSERT_PROJECT_WIZARD_DOCUMENTS_PROJECT_FAIL';

export const CREATE_PROJECT_WIZARD_STATIC_DOCUMENT = 'CREATE_PROJECT_WIZARD_STATIC_DOCUMENT';
export const CREATE_PROJECT_WIZARD_STATIC_DOCUMENT_SUCCESS =
  'CREATE_PROJECT_WIZARD_STATIC_DOCUMENT_SUCCESS';
export const CREATE_PROJECT_WIZARD_STATIC_DOCUMENT_FAIL =
  'CREATE_PROJECT_WIZARD_STATIC_DOCUMENT_FAIL';

export const GET_PROJECT_WIZARD_ANNOUNCEMENT_URL = 'GET_PROJECT_WIZARD_ANNOUNCEMENT_URL';
export const GET_PROJECT_WIZARD_ANNOUNCEMENT_URL_SUCCESS =
  'GET_PROJECT_WIZARD_ANNOUNCEMENT_URL_SUCCESS';
export const GET_PROJECT_WIZARD_ANNOUNCEMENT_URL_FAIL = 'GET_PROJECT_WIZARD_ANNOUNCEMENT_URL_FAIL';

export const GET_PROJECT_WIZARD_IMAGES = 'GET_PROJECT_WIZARD_IMAGES';
export const GET_PROJECT_WIZARD_IMAGES_SUCCESS = 'GET_PROJECT_WIZARD_IMAGES_SUCCESS';
export const GET_PROJECT_WIZARD_IMAGES_ERROR = 'GET_PROJECT_WIZARD_IMAGES_ERROR';
export const RESET_PROJECT_WIZARD_IMAGES = 'RESET_PROJECT_WIZARD_IMAGES';

export const UPSERT_PROJECT_WIZARD_IMAGES = 'UPSERT_PROJECT_WIZARD_IMAGES';
export const UPSERT_PROJECT_WIZARD_IMAGES_SUCCESS = 'UPSERT_PROJECT_WIZARD_IMAGES_SUCCESS';
export const UPSERT_PROJECT_WIZARD_IMAGES_ERROR = 'UPSERT_PROJECT_WIZARD_IMAGES_ERROR';
export const RESET_PROJECT_WIZARD_UPSERTED_IMAGES = 'RESET_PROJECT_WIZARD_UPSERTED_IMAGES';

/* PARAMETERS */

export const GET_CLIENT_PARAMETERS = 'GET_CLIENT_PARAMETERS';
export const GET_CLIENT_PARAMETERS_SUCCESS = 'GET_CLIENT_PARAMETERS_SUCCESS';
export const GET_CLIENT_PARAMETERS_ERROR = 'GET_CLIENT_PARAMETERS_ERROR';
export const RESET_CLIENT_PARAMETERS = 'RESET_CLIENT_PARAMETERS';

/* LOG */

export const LOG = 'LOG';
export const LOG_SUCCESS = 'LOG_SUCCESS';
export const LOG_ERROR = 'LOG_ERROR';

