import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import {
  Chart,
  BarElement,
  PointElement,
  Title,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import {
  GetProjectCompletionSummary,
  ResetProjectCompletionSummary
} from '../../../redux/ProjectDetail/actions';
import { usePrevious } from '../../../shared/common/hookHelper';
import styles from './styles.module.scss';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  Title
);

const CompletionSummary = (props) => {
  const [chartData, SetChartData] = useState();
  const previousProps = usePrevious(props);

  useEffect(() => {
    const payload = {
      projectId: props.projectId
    };

    props.GetProjectCompletionSummary(payload);
  }, []);

  useEffect(() => {
    return () => {
      props.ResetProjectCompletionSummary();
    };
  }, []);

  useEffect(() => {
    if (
      previousProps?.projectDetail?.completionSummary !== props.projectDetail?.completionSummary &&
      !!props.projectDetail?.completionSummary?.response
    ) {
      const completionSummary = props.projectDetail.completionSummary.response.byInvestmentStatus;

      const labels = [true, false];

      let flattenedList = [];
      completionSummary.map((status) => {
        status.investmentQualificationSummaries.map((summary) => {
          let data = summary;
          data.investmentStatus = status.investmentStatus;
          flattenedList.push(data);
        });
      });

      let investmentStatuses = [];
      flattenedList.map((data) => {
        if (investmentStatuses.indexOf(data.investmentStatus) < 0)
          investmentStatuses.push(data.investmentStatus);
      });

      const data = {
        labels: labels.map((label) => {
          return !!label ? props.language.licence : props.language.nonLicence;
        }),
        datasets: investmentStatuses.map((status) => {
          return {
            label: status,
            data: labels.map((label) => {
              return flattenedList.filter(
                (data) => data.investmentStatus === status && data.isQualified === label
              )[0]?.totalAmount;
            }),
            backgroundColor: dynamicColors()
          };
        })
      };

      SetChartData(data);
    }
  }, [props.projectDetail.completionSummary]);

  const dynamicColors = () => {
    var random = Math.random();
    var red = 11 + Math.floor(random * (106 - 11));
    var green = 23 + Math.floor(random * (207 - 23));
    var blue = 48 + Math.floor(random * (255 - 48));

    return 'rgb(' + red + ',' + green + ',' + blue + ')';
  };

  return (
    <section>
      <Row>
        <Col sm={12}>
          <h3 className={styles.text}>{props.language.completedProjectSummary}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          {!!chartData && (
            <Bar
              className={styles.canvas}
              data={chartData}
              options={{ maintainAspectRatio: false }}
            />
          )}
        </Col>
      </Row>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.dashboard.tables,
    projectDetail: state.projectDetail
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetProjectCompletionSummary: (payload) => dispatch(GetProjectCompletionSummary(payload)),
    ResetProjectCompletionSummary: (payload) => dispatch(ResetProjectCompletionSummary(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompletionSummary);
