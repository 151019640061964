import Service from './baseService';
import { notify } from '../shared/notify';
import { getCustomError } from '../shared/errorMesageHelper';
import RequestService from './requestService';

const requestService = new RequestService();

export const makeGetRequest = async (url, showNotifications = true) => {
  const requestParameters = {
    type: 'GET',
    url,
    showNotifications
  };
  return await makeRequest(requestParameters);
};

export const makeGetBlobRequest = async (url) => {
  const requestParameters = {
    type: 'GETBLOB',
    url
  };
  return await makeRequest(requestParameters);
};

export const makePostRequest = async (url, payload, showNotifications = true) => {
  const requestParameters = {
    type: 'POST',
    url,
    payload,
    showNotifications
  };
  return await makeRequest(requestParameters);
};

export const makePutRequest = async (url, payload, showNotifications = true) => {
  const requestParameters = {
    type: 'PUT',
    url,
    payload,
    showNotifications
  };
  return await makeRequest(requestParameters);
};

export const makePatchRequest = async (url, payload, showNotifications = true) => {
  const requestParameters = {
    type: 'PATCH',
    url,
    payload,
    showNotifications
  };
  return await makeRequest(requestParameters);
};

export const makeDeleteRequest = async (url, showNotifications = true) => {
  const requestParameters = {
    type: 'DELETE',
    url,
    showNotifications
  };
  return await makeRequest(requestParameters);
};

export const makeUploadRequest = async (
  url,
  payload,
  showNotifications = true,
  onUploadProgress = null
) => {
  const requestParameters = {
    type: 'UPLOAD',
    url,
    payload,
    onUploadProgress,
    showNotifications
  };
  return await makeRequest(requestParameters);
};

export const makeLoginRequest = async (payload, showNotifications = true) => {
  const requestParameters = {
    type: 'LOGIN',
    payload,
    showNotifications
  };

  const response = await makeRequest(requestParameters);
  const errorRecord = {
    extendedErrorCode: 'Unauthorized_User_Access'
  };
  const newRequestParameters = {
    showNotifications: true
  };

  if (!!!Array.from(['Regular']).find((x) => x === response.userType)) {
    return handleError(errorRecord, newRequestParameters);
  }

  return response;
};

export const makeLogRequest = async (url, payload, showNotifications = false) => {
  const requestParameters = {
    type: 'LOG',
    url,
    payload,
    showNotifications
  };
  return await makeRequest(requestParameters);
};

const makeRequest = async (requestParameters) => {
  return requestService
    .createPromise(requestParameters)
    .then((response) => {
      return handleSuccess(response);
    })
    .catch((error) => {
      return handleError(error, requestParameters);
    });
};

const handleSuccess = async (response) => {
  return Promise.resolve(prepareResponse(response));
};

const handleError = async (error, requestParameters) => {
  if (error?.needsRefreshing) {
    let promise = requestService.refresh();
    if (!!promise && Promise.resolve(promise)) {
      return promise.then(() => {
        return makeRequest(requestParameters);
      });
    }
  } else if (error?.needsLogin) {
    return await requestService.redirectToLogin();
  } else if (error?.timeoutError) {
    showErrorMessage(error);
    return Promise.reject({ errorMessage: error });
  } else {
    if (!!requestParameters?.showNotifications) {
      showErrorMessage(error);
    }
    return Promise.reject({ errorMessage: error });
  }
};

const prepareResponse = (response) => {
  let dataSet = {};
  if (typeof response === 'object') {
    dataSet = { ...response };
  } else {
    dataSet = response;
  }
  return dataSet;
};

const showErrorMessage = async (error) => {
  if (!!error) {
    if (error.extendedErrorCode === 'Domain_SharedKernel_Authenticate_WrongCredentials') {
      return;
    }
    const customError = getCustomError(error);

    if (!!customError?.message) {
      notify(
        'Error',
        !!customError.title ? customError.message : '',
        customError.title ?? customError.message
      );
    }
  }
};
