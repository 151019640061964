import {
  AUTHENTICATE_USER,
  AUTHENTICATE_USER_SUCCESS,
  AUTHENTICATE_USER_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_ERROR
} from '../actions';

export const AuthenticateUser = (payload) => {
  return {
    type: AUTHENTICATE_USER,
    payload
  };
};

export const AuthenticateUserSuccess = (payload) => {
  return {
    type: AUTHENTICATE_USER_SUCCESS,
    payload
  };
};

export const AuthenticateUserError = (payload) => {
  return {
    type: AUTHENTICATE_USER_ERROR,
    payload
  };
};

export const LogoutUser = (payload) => {
  return {
    type: LOGOUT_USER,
    payload
  };
};

export const LogoutUserSuccess = (payload) => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload
  };
};

export const LogoutUserError = (payload) => {
  return {
    type: LOGOUT_USER_ERROR,
    payload
  };
};
