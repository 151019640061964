import {
  GET_CLIENT_PARAMETERS_SUCCESS,
  GET_CLIENT_PARAMETERS_ERROR,
  RESET_CLIENT_PARAMETERS
} from '../actions';

const clientParametersInitialState = null;

const initialState = {
  clientParameters: clientParametersInitialState
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT_PARAMETERS_SUCCESS:
    case GET_CLIENT_PARAMETERS_ERROR:
      return { ...state, clientParameters: action.payload };
    case RESET_CLIENT_PARAMETERS:
      return { ...state, clientParameters: clientParametersInitialState };
    default:
      return state;
  }
};
