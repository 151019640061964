import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import { loadSpfResponse } from '../../helpers/http/response';
import { commonServiceModule } from '../../services/modules/commonServiceModule';
import { userServiceModule } from '../../services/modules/userServiceModule';

import {
  GET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT,
  GET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE,
  GET_USER_PROJECTS,
  GET_USER_FAVORITE_PROJECTS,
  USER_FAVORITE_PROJECT
} from '../actions';
import {
  GetIndividualQualificationStatementDocumentTemplateSuccess,
  GetIndividualQualificationStatementDocumentTemplateError,
  GetRegularUserInvestmentLimitSuccess,
  GetRegularUserInvestmentLimitError,
  GetUserProjectsSuccess,
  GetUserProjectsError,
  GetUserFavoriteProjectsSuccess,
  GetUserFavoriteProjectsError,
  UserFavoriteProjectSuccess,
  UserFavoriteProjectError
} from './actions';

export default function* rootSaga() {
  yield all([fork(watchGetIndividualQualificationStatementDocumentTemplate)]);
  yield all([fork(watchGetRegularUserInvestmentLimit)]);
  yield all([fork(watchGetUserProjects)]);
  yield all([fork(watchGetUserFavoriteProjects)]);
  yield all([fork(watchUserFavoriteProject)]);
}

function* watchGetIndividualQualificationStatementDocumentTemplate() {
  yield takeLeading(
    GET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE,
    GetIndividualQualificationStatementDocumentTemplate
  );
}

function* watchGetRegularUserInvestmentLimit() {
  yield takeLeading(
    GET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT,
    GetRegularUserInvestmentLimit
  );
}

function* watchGetUserProjects() {
  yield takeLeading(GET_USER_PROJECTS, GetUserProjects);
}

function* watchGetUserFavoriteProjects() {
  yield takeLeading(GET_USER_FAVORITE_PROJECTS, GetUserFavoriteProjects);
}

function* watchUserFavoriteProject() {
  yield takeLeading(USER_FAVORITE_PROJECT, UserFavoriteProject);
}

function* GetIndividualQualificationStatementDocumentTemplate(action) {
  try {
    const response = yield call(GetIndividualQualificationStatementDocumentTemplateAsync, action);
    const payload = {
      url: response.url
    };
    yield put(GetIndividualQualificationStatementDocumentTemplateSuccess(payload));
  } catch (error) {
    const errorPayload = {
      payload: action.payload,
      error
    };
    yield put(GetIndividualQualificationStatementDocumentTemplateError(errorPayload));
  }
}

function* GetRegularUserInvestmentLimit(action) {
  try {
    const response = yield call(GetRegularUserInvestmentLimitAsync, action);
    const payload = {
      limitInfo: response.limitInfo,
      isQualified: response.isQualified
    };
    yield put(GetRegularUserInvestmentLimitSuccess(payload));
  } catch (error) {
    const errorPayload = {
      payload: action.payload,
      error
    };
    yield put(GetRegularUserInvestmentLimitError(errorPayload));
  }
}

function* GetUserProjects(action) {
  try {
    const response = yield call(GetUserProjectsAsync, action);
    const payload = loadSpfResponse(action.payload, response);

    yield put(GetUserProjectsSuccess(payload));
  } catch (error) {
    const errorPayload = {
      payload: action.payload,
      error
    };
    yield put(GetUserProjectsError(errorPayload));
  }
}

function* GetUserFavoriteProjects(action) {
  try {
    const response = yield call(GetUserFavoriteProjectsAsync, action);
    const payload = loadSpfResponse(action.payload, response);

    yield put(GetUserFavoriteProjectsSuccess(payload));
  } catch (error) {
    const errorPayload = {
      payload: action.payload,
      error
    };
    yield put(GetUserFavoriteProjectsError(errorPayload));
  }
}

function* UserFavoriteProject(action) {
  try {
    const response = yield call(UserFavoriteProjectAsync, action);
    const payload = loadSpfResponse(action.payload, response);
    const newPayload = { id: payload.request.id, isSuccess: true, like: payload.request.fav.like };

    yield put(UserFavoriteProjectSuccess(newPayload));
  } catch (error) {
    const errorPayload = {
      payload: action.payload,
      error
    };
    yield put(UserFavoriteProjectError(errorPayload));
  }
}

const GetIndividualQualificationStatementDocumentTemplateAsync = (action) => {
  return commonServiceModule.getStaticDocuments('IndividualQualificationStatementDocument');
};

const GetRegularUserInvestmentLimitAsync = (action) => {
  return userServiceModule.getUserLimit();
};

const GetUserProjectsAsync = (action) => {
  return userServiceModule.getUserProjects(action.payload);
};

const GetUserFavoriteProjectsAsync = (action) => {
  return userServiceModule.getUserFavoriteProjects(action.payload);
};

const UserFavoriteProjectAsync = (action) => {
  return userServiceModule.userFavoriteProject(action.payload.id, action.payload.fav);
};
