import React from 'react';
import classnames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import styles from './styles.module.scss';

const Important = (props) => {
  const content =
    props.subscriptionType === 'Individual' ? props.data.content : props.data.corporateContent;
  return (
    <div>
      <Row>
        <Col>
          <div className={classnames(styles.expContainer)}>
            <h3>{props.data.important}</h3>
            {content.map((item, id) => (
              <p key={id}>{item}</p>
            ))}
            {!!props.regularUser?.hasIdentityVerificationError && (
              <p
                key={'hasIdentityVerificationError'}
                className={classnames(styles.identityVerificationError)}
              >
                {props.data.identityVerificationErrorText}
              </p>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.language.dashboard.profile.kyc,
    regularUser: state.regularUser
  };
};

export default connect(mapStateToProps)(Important);
