import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { OneClickButton } from '../../../custom';
import { notify } from '../../../../shared/notify';
import PhoneInput from 'react-phone-number-input/input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { userServiceModule } from '../../../../services/modules/userServiceModule';
import styles from '../styles.module.scss';
import { GetRegularUser } from '../../../../redux/RegularUser/actions';
import classNames from '../../../../../node_modules/classnames/index';

function PhoneNumberForm(props) {
  const [phoneNumber, SetPhoneNumber] = useState(null);
  const changePhoneNumber = async () => {
    let promise;
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (props.regularUser?.phoneNumber === values.phoneNumber) {
          notify('Error', props.profileInfo.phoneNumberSameNumberErrorNotify);
          return promise;
        }

        const payload = {
          phoneNumber: phoneNumber
        };

        promise = userServiceModule.changePhoneNumber(payload).then((response) => {
          props.GetRegularUser();
          notify('Success', props.profileInfo.phoneNumberChangeNotify);
        });
      }
    });

    return promise;
  };

  const validatePhoneNumber = (rule, value, callback) => {
    if (!value) {
      callback(props.common.requiredField);
      return;
    }

    if (value.length > 255) {
      callback(props.common.errorMessageMax255);
      return;
    }

    if (!!value && isValidPhoneNumber(value)) {
      callback();

      return;
    }
    callback(props.profileInfo.phoneNumberValidateError);
  };

  const changePhone = (e) => {
    SetPhoneNumber(e);
  };

  return (
    <Form id="phoneNumberForm" layout={'inline'} colon={false}>
      <Row>
        <Col>
          <Input.Group compact>
            <Form.Item label={props.profileInfo.phone} hasFeedback={false}>
              {props.form.getFieldDecorator('phoneNumber', {
                validateTrigger: ['onBlur'],
                rules: [
                  {
                    required: true,
                    validator: validatePhoneNumber
                  }
                ],
                initialValue: props.regularUser?.phoneNumber
              })(
                <PhoneInput
                  className={classNames(styles.formInput, 'ant-input')}
                  country="TR"
                  withCountryCallingCode
                  international
                  value={phoneNumber}
                  onChange={(e) => changePhone(e)}
                />
              )}
            </Form.Item>
            <Form.Item>
              <OneClickButton
                onClick={changePhoneNumber}
                className={styles.button}
                form="phoneNumberForm"
              >
                {!!props.regularUser?.phoneNumber
                  ? props.profileInfo.btnChange
                  : props.profileInfo.btnSave}
              </OneClickButton>
            </Form.Item>
          </Input.Group>
        </Col>
      </Row>
    </Form>
  );
}

const mapStateToProps = (state) => {
  return {
    profileInfo: state.language.dashboard.profile.profileInfo,
    regularUser: state.regularUser,
    common: state.language.common
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetRegularUser: () => dispatch(GetRegularUser())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'PhoneNumberForm' })(PhoneNumberForm));

