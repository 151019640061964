export default {
  en: {
    ZerotoOne: '0 - 1',
    TwotoTen: '2 - 10',
    EleventoFifty: '11 - 50',
    FiftyOnetoTwoHundred: '51 - 200',
    TwoHundredOnetoFiveHundred: '201 - 500',
    FiveHundredOnetoOneThousand: '501 - 1000',
    OneThousandOnetoFiveThousand: '1001 - 5000',
    FiveThousandOnetoTenThousand: '5001 - 10000',
    TenThousandPlus: '10001+'
  }
};
