import { GET_REGULAR_USER_SUCCESS, GET_REGULAR_USER_ERROR, RESET_REGULAR_USER } from '../actions';

const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REGULAR_USER_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    //TODO: What to do with error
    case GET_REGULAR_USER_ERROR:
      return {
        ...state
      };
    case RESET_REGULAR_USER:
      return {
        initialState
      };
    default:
      return state;
  }
};
