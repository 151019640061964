import { PUT_MKK_SCL_NUMBER } from '../actions';
import { PutMkkSclNumberSuccess, PutMkkSclNumberError } from './actions';
import { GetRegularUser } from '../RegularUser/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import { userServiceModule } from '../../services/modules/userServiceModule';
import { loadError, loadResponse } from '../../helpers/http/response';

export default function* rootSaga() {
  yield all([fork(watchPutMkkSclNumber)]);
}

function* watchPutMkkSclNumber() {
  yield takeLeading(PUT_MKK_SCL_NUMBER, PutMkkSclNumber);
}
function* PutMkkSclNumber(action) {
  try {
    const response = yield call(PutMkkSclNumberAsync, action);
    const responsePayload = loadResponse(action.payload, response);
    yield put(PutMkkSclNumberSuccess(responsePayload));
    yield put(GetRegularUser());
  } catch (error) {
    const errorPayload = loadError(action.payload, error);
    yield put(PutMkkSclNumberError(errorPayload));
  }
}
const PutMkkSclNumberAsync = (action) => {
  return userServiceModule.putMkkSclNumber(action.payload);
};
