import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Progress } from 'antd';
import classnames from 'classnames';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { getCityName } from '../../../shared/location/locationHelper';
import styles from './styles.module.scss';
import NumberFormat from 'react-number-format';

const FundingSummary = (props) => {
  return (
    <section>
      <Row>
        <Col sm={12}>
          <h3 className={styles.text}>{props.language.fundingSummary}</h3>
        </Col>
      </Row>
      {!!props.projectDetail?.info &&
        !!!props.projectDetail?.info?.error &&
        !!props.projectDetail?.fundingInfo &&
        !!!props.projectDetail?.fundingInfo?.error && (
          <Row>
            <Col sm={12}>
              <Row className="p-0 m-0">
                <Col sm={6} md={2} className={styles.logo}>
                  <Row className={styles.customRow}>
                    {/* This provides aspect ratio of 1:1 */}

                    <Col className={styles.customCol}>
                      <a href={'/project/' + props.projectId}>
                        <img
                          className="w-100"
                          src={props.projectDetail.info.projectLogo.url}
                          alt=""
                        />
                      </a>
                    </Col>
                  </Row>
                </Col>
                <Col sm={6} md={3}>
                  <Col
                    className={classnames(
                      styles.flexColumn,
                      'd-flex justify-content-center align-center'
                    )}
                  >
                    <div className={styles.companyTitle}>
                      <Link to={'/project/' + props.projectId}>
                        {props.projectDetail.info.title}
                      </Link>
                      <div>
                        <span>
                          <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.icon} />
                          {getCityName(props.projectDetail.info.location)},{props.language.country}
                        </span>
                      </div>
                    </div>

                    {!!!props.projectDetail?.fundingInfo?.error && (
                      <div className={styles.funding}>
                        <NumberFormat
                          value={props.projectDetail.fundingInfo.targetFunding}
                          displayType="text"
                          thousandSeparator="."
                          prefix="₺"
                          decimalSeparator=","
                          decimalScale={2}
                        />
                        <p>{props.language.targetFunding}</p>
                      </div>
                    )}
                  </Col>
                </Col>
                <Col sm={6} md={3} className={styles.border}>
                  <div>
                    <Progress
                      percent={parseInt(props.projectDetail.fundingInfo.fundedPercent)}
                      type="circle"
                      width={80}
                      status="normal"
                      format={(percent) =>
                        `${
                          Number(props.projectDetail.fundingInfo.fundedPercent ?? 0) +
                          Number(props.projectDetail.fundingInfo.approvedFundingPercent ?? 0)
                        } %`
                      }
                    />
                  </div>
                  <div className={styles.progress}>
                    {moment
                      .utc(props.projectDetail.fundingInfo.startDate)
                      .local()
                      .format('DD.MM.YYYY')}
                    <p>{props.language.startDate}</p>
                  </div>
                </Col>
                <Col sm={6} md={4} className={styles.summary}>
                  <div>
                    <NumberFormat
                      value={
                        Number(props.projectDetail.fundingInfo.collectedFunding ?? 0) +
                        Number(props.projectDetail.fundingInfo.approvedFunding ?? 0)
                      }
                      displayType="text"
                      thousandSeparator="."
                      prefix="₺"
                      decimalSeparator=","
                      decimalScale={2}
                    />
                    <p>{props.language.funded}</p>
                  </div>
                  <div>
                    <NumberFormat
                      value={Number(props.projectDetail.fundingInfo.investorCount ?? 0)}
                      displayType="text"
                      thousandSeparator="."
                      prefix=""
                      decimalSeparator=","
                      decimalScale={2}
                    />
                    <p>{props.language.fundingTotal}</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.dashboard.tables,
    projectDetail: state.projectDetail
  };
};

export default connect(mapStateToProps)(FundingSummary);

