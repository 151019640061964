import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Input, Form } from 'antd';

const VerifiedCorporate = (props) => {
  return (
    <div>
      <h3> {props.data.corporateIdentityVerifiedLabel}</h3>
      <Row>
        <Col sm={12}>
          <Form.Item label={props.data.title} className="mb-0">
            <Input key="title" value={props.regularUser?.title} disabled />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item label={props.data.vknNumberLabel} className="mb-0">
            <Input key="idNumber" value={props.regularUser?.identityNumber} disabled />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.language.dashboard.profile.kyc,
    regularUser: state.regularUser
  };
};

export default connect(mapStateToProps)(VerifiedCorporate);
