import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import { userServiceModule } from '../../services/modules/userServiceModule';
import { commonServiceModule } from '../../services/modules/commonServiceModule';
import {
  GET_USER_DEMOGRAPHICS,
  PATCH_USER_DEMOGRAPHICS,
  GET_USER_DEMOGRAPHICS_ADDRESS_TYPE
} from '../actions';
import {
  GetUserDemographics as Get,
  GetUserDemographicsSuccess,
  GetUserDemographicsError,
  PatchUserDemographicsSuccess,
  PatchUserDemographicsError,
  GetUserDemographicsAddressTypeSuccess,
  GetUserDemographicsAddressTypeError
} from './actions';
import { loadError, loadResponse } from '../../helpers/http/response';

export default function* rootSaga() {
  yield all([fork(watchGetUserDemographics)]);
  yield all([fork(watchPatchUserDemographics)]);
  yield all([fork(watchGetUserDemographicsAddressType)]);
}

function* watchGetUserDemographics() {
  yield takeLeading(GET_USER_DEMOGRAPHICS, GetUserDemographics);
}

function* watchPatchUserDemographics() {
  yield takeLeading(PATCH_USER_DEMOGRAPHICS, PatchUserDemographics);
}

function* watchGetUserDemographicsAddressType() {
  yield takeLeading(GET_USER_DEMOGRAPHICS_ADDRESS_TYPE, GetUserDemographicsAddressType);
}

function* GetUserDemographics(action) {
  try {
    const response = yield call(GetUserDemographicsAsync);
    const responsePayload = loadResponse(action.payload, response);
    yield put(GetUserDemographicsSuccess(responsePayload));
  } catch (error) {
    const errorPayload = loadError(action.payload, error);
    yield put(GetUserDemographicsError(errorPayload));
  }
}

function* PatchUserDemographics(action) {
  try {
    const response = yield call(PatchUserDemographicsAsync, action.payload);
    const responsePayload = loadResponse(action.payload, response);
    yield put(Get());
    yield put(PatchUserDemographicsSuccess(responsePayload));
  } catch (error) {
    const errorPayload = loadError(action.payload, error);
    yield put(PatchUserDemographicsError(errorPayload));
  }
}

function* GetUserDemographicsAddressType(action) {
  try {
    const response = yield call(GetUserDemographicsAddressTypeAsync);
    const responsePayload = loadResponse(action.payload, Object.values(response));
    yield put(GetUserDemographicsAddressTypeSuccess(responsePayload));
  } catch (error) {
    const errorPayload = loadError(action.payload, error);
    yield put(GetUserDemographicsAddressTypeError(errorPayload));
  }
}

const GetUserDemographicsAsync = () => {
  return userServiceModule.getUserDemographics();
};

const PatchUserDemographicsAsync = (action) => {
  return userServiceModule.patchUserDemographics(action);
};

const GetUserDemographicsAddressTypeAsync = () => {
  return commonServiceModule.getUserDemographicsAddressType();
};
