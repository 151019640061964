import {
  UPDATE_PROJECT_WIZARD_FUNDING_INFO,
  UPDATE_PROJECT_WIZARD_FUNDING_INFO_SUCCESS,
  UPDATE_PROJECT_WIZARD_FUNDING_INFO_FAIL,
  UPSERT_PROJECT_WIZARD_PROJECT_DOCUMENTS,
  UPSERT_PROJECT_WIZARD_DOCUMENTS_PROJECT_SUCCESS,
  UPSERT_PROJECT_WIZARD_DOCUMENTS_PROJECT_FAIL,
  CREATE_PROJECT_WIZARD_STATIC_DOCUMENT,
  CREATE_PROJECT_WIZARD_STATIC_DOCUMENT_SUCCESS,
  CREATE_PROJECT_WIZARD_STATIC_DOCUMENT_FAIL,
  GET_PROJECT_WIZARD_ANNOUNCEMENT_URL,
  GET_PROJECT_WIZARD_ANNOUNCEMENT_URL_SUCCESS,
  GET_PROJECT_WIZARD_ANNOUNCEMENT_URL_FAIL,
  SET_PROJECT_WIZARD_REFERENCE_CODE,
  RESET_PROJECT_WIZARD_REFERENCE_CODE,
  SET_IS_API_CALL_COMPLETED,
  RESET_IS_API_CALL_COMPLETED,
  RESET_PROJECT_WIZARD_FUNDING_INFO,
  GET_PROJECT_WIZARD_IMAGES,
  GET_PROJECT_WIZARD_IMAGES_SUCCESS,
  GET_PROJECT_WIZARD_IMAGES_ERROR,
  RESET_PROJECT_WIZARD_IMAGES,
  UPSERT_PROJECT_WIZARD_IMAGES,
  UPSERT_PROJECT_WIZARD_IMAGES_SUCCESS,
  UPSERT_PROJECT_WIZARD_IMAGES_ERROR,
  RESET_PROJECT_WIZARD_UPSERTED_IMAGES
} from '../actions';

export const UpdateFundingInfo = (payload) => {
  return {
    type: UPDATE_PROJECT_WIZARD_FUNDING_INFO,
    payload
  };
};

export const UpdateFundingInfoSuccess = (payload) => {
  return {
    type: UPDATE_PROJECT_WIZARD_FUNDING_INFO_SUCCESS,
    payload
  };
};

export const UpdateFundingInfoFail = (payload) => {
  return {
    type: UPDATE_PROJECT_WIZARD_FUNDING_INFO_FAIL,
    payload
  };
};

export const ResetFundingInfo = () => {
  return {
    type: RESET_PROJECT_WIZARD_FUNDING_INFO
  };
};

export const UpsertProjectDocuments = (payload) => {
  return {
    type: UPSERT_PROJECT_WIZARD_PROJECT_DOCUMENTS,
    payload
  };
};

export const UpsertProjectDocumentsSuccess = (payload) => {
  return {
    type: UPSERT_PROJECT_WIZARD_DOCUMENTS_PROJECT_SUCCESS,
    payload
  };
};

export const UpsertProjectDocumentsFail = (payload) => {
  return {
    type: UPSERT_PROJECT_WIZARD_DOCUMENTS_PROJECT_FAIL,
    payload
  };
};

export const CreateProjectStaticDocument = (payload) => {
  return {
    type: CREATE_PROJECT_WIZARD_STATIC_DOCUMENT,
    payload
  };
};

export const CreateProjectStaticDocumentSuccess = (payload) => {
  return {
    type: CREATE_PROJECT_WIZARD_STATIC_DOCUMENT_SUCCESS,
    payload
  };
};

export const CreateProjectStaticDocumentFail = (payload) => {
  return {
    type: CREATE_PROJECT_WIZARD_STATIC_DOCUMENT_FAIL,
    payload
  };
};

export const GetAnnouncementUrl = (payload) => {
  return {
    type: GET_PROJECT_WIZARD_ANNOUNCEMENT_URL,
    payload
  };
};

export const GetAnnouncementUrlSuccess = (payload) => {
  return {
    type: GET_PROJECT_WIZARD_ANNOUNCEMENT_URL_SUCCESS,
    payload
  };
};

export const GetAnnouncementUrlFail = (payload) => {
  return {
    type: GET_PROJECT_WIZARD_ANNOUNCEMENT_URL_FAIL,
    payload
  };
};

export const SetProjectWizardReferenceCode = (payload) => {
  return {
    type: SET_PROJECT_WIZARD_REFERENCE_CODE,
    payload
  };
};

export const ResetProjectWizardReferenceCode = (payload) => {
  return {
    type: RESET_PROJECT_WIZARD_REFERENCE_CODE,
    payload
  };
};

export const SetIsApiCallCompleted = (payload) => {
  return {
    type: SET_IS_API_CALL_COMPLETED,
    payload
  };
};

export const ResetIsApiCallCompleted = (payload) => {
  return {
    type: RESET_IS_API_CALL_COMPLETED,
    payload
  };
};

export const GetProjectImages = (payload) => {
  return {
    type: GET_PROJECT_WIZARD_IMAGES,
    payload
  };
};

export const GetProjectImagesSuccess = (payload) => {
  return {
    type: GET_PROJECT_WIZARD_IMAGES_SUCCESS,
    payload
  };
};

export const GetProjectImagesError = (payload) => {
  return {
    type: GET_PROJECT_WIZARD_IMAGES_ERROR,
    payload
  };
};

export const ResetProjectImages = () => {
  return {
    type: RESET_PROJECT_WIZARD_IMAGES
  };
};

export const UpsertProjectImages = (payload) => {
  return {
    type: UPSERT_PROJECT_WIZARD_IMAGES,
    payload
  };
};

export const UpsertProjectImagesSuccess = (payload) => {
  return {
    type: UPSERT_PROJECT_WIZARD_IMAGES_SUCCESS,
    payload
  };
};

export const UpsertProjectImagesError = (payload) => {
  return {
    type: UPSERT_PROJECT_WIZARD_IMAGES_ERROR,
    payload
  };
};

export const ResetProjectUpsertedImages = () => {
  return {
    type: RESET_PROJECT_WIZARD_UPSERTED_IMAGES
  };
};

