import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import { loadError, loadResponse } from '../../helpers/http/response';
import { commonServiceModule } from '../../services/modules/commonServiceModule';
import {
  GET_GEO_ADDRESS_COUNTRIES,
  GET_GEO_ADDRESS_COUNTRY_STATES,
  GET_GEO_ADDRESS_COUNTRY_STATE_CITIES
} from '../actions';
import {
  GetGeoAddressCountriesSuccess,
  GetGeoAddressCountriesError,
  GetGeoAddressCountryStatesSuccess,
  GetGeoAddressCountryStatesError,
  GetGeoAddressCountryStateCitiesSuccess,
  GetGeoAddressCountryStateCitiesError
} from './actions';

export default function* rootSaga() {
  yield all([fork(watchGetGeoAddressCountries)]);
  yield all([fork(watchGetGeoAddressCountryStates)]);
  yield all([fork(watchGetGeoAddressCountryStateCities)]);
}

function* watchGetGeoAddressCountries() {
  yield takeLeading(GET_GEO_ADDRESS_COUNTRIES, GetGeoAddressCountries);
}

function* watchGetGeoAddressCountryStates() {
  yield takeLeading(GET_GEO_ADDRESS_COUNTRY_STATES, GetGeoAddressCountryStates);
}

function* watchGetGeoAddressCountryStateCities() {
  yield takeLeading(GET_GEO_ADDRESS_COUNTRY_STATE_CITIES, GetGeoAddressCountryStateCities);
}

function* GetGeoAddressCountries(action) {
  try {
    const response = yield call(GetGeoAddressCountriesAsync);
    const responsePayload = loadResponse(action.payload, Object.values(response));
    yield put(GetGeoAddressCountriesSuccess(responsePayload));
  } catch (error) {
    const errorPayload = loadError(action.payload, error);
    yield put(GetGeoAddressCountriesError(errorPayload));
  }
}

function* GetGeoAddressCountryStates(action) {
  try {
    const response = yield call(GetGeoAddressCountryStatesAsync, action.payload);
    const responsePayload = loadResponse(action.payload, Object.values(response));
    yield put(GetGeoAddressCountryStatesSuccess(responsePayload));
  } catch (error) {
    const errorPayload = loadError(action.payload, error);
    yield put(GetGeoAddressCountryStatesError(errorPayload));
  }
}

function* GetGeoAddressCountryStateCities(action) {
  try {
    const response = yield call(GetGeoAddressCountryStateCitiesAsync, action.payload);
    const responsePayload = loadResponse(action.payload, Object.values(response));
    yield put(GetGeoAddressCountryStateCitiesSuccess(responsePayload));
  } catch (error) {
    const errorPayload = loadError(action.payload, error);
    yield put(GetGeoAddressCountryStateCitiesError(errorPayload));
  }
}

const GetGeoAddressCountriesAsync = () => {
  return commonServiceModule.getGeoAddressCountries();
};

const GetGeoAddressCountryStatesAsync = (payload) => {
  return commonServiceModule.getGeoAddressCountryStates(payload);
};

const GetGeoAddressCountryStateCitiesAsync = (payload) => {
  return commonServiceModule.getGeoAddressCountryStateCities(payload);
};
