import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';
import styles from './styles.module.scss';
import VerifyUser from './verifyuser';
import VerifiedUser from './verifieduser';
import PendingUser from './pendinguser';

const Identity = (props) => {
  return (
    <div
      className={classnames(
        styles.container,
        !!props.regularUser?.identityVerified
          ? styles.verified
          : props.regularUser?.pendingIdentityType !== 'None'
          ? styles.pending
          : styles.verify,
        'row pt-5 mb-4'
      )}
    >
      <div className="w-100">
        <Row>
          {/* do not change below '!!props.regularUser' check. otherwise it causes PendingUser to render even before profile call completion */}
          {!!props.regularUser &&
            (!!props.regularUser.identityVerified ? (
              <Col>
                <div className="pr-5 pb-5 pl-5">
                  <VerifiedUser />
                </div>
              </Col>
            ) : props.regularUser.pendingIdentityType !== 'None' ? (
              <Col>
                <div className="pr-5 pb-5 pl-5">
                  <PendingUser />
                </div>
              </Col>
            ) : (
              <Col>
                <div className="pr-5 pb-5 pl-5">
                  <VerifyUser />
                </div>
              </Col>
            ))}
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.language.dashboard.profile.kyc,
    commonData: state.language.common,
    regularUser: state.regularUser
  };
};

export default connect(mapStateToProps)(Identity);
