import { Col, Container, Row } from 'react-bootstrap';

import { DashboardSideBar } from '../../components/dashboard';
import React from 'react';
import { connect } from 'react-redux';
import styles from './styles.module.scss';
import { GetRegularUser } from '../../redux/RegularUser/actions';
import store from 'store2';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(previousProps) {
    if (previousProps !== this.props) {
      if (!!store.get('authentication')?.token && !!!this.props.regularUser?.isLoaded) {
        this.props.GetRegularUser();
      }
    }
  }

  trimPathname = (str) => {
    return str.replace('/dashboard/', '');
  };

  render() {
    const { children } = this.props;
    const pathname =
      !!children &&
      !!children.props &&
      !!children.props.location &&
      !!children.props.location.pathname &&
      children.props.location.pathname;

    const sendPathname = pathname === '/dashboard' ? '/dashboard/profile' : pathname;

    return (
      <Container fluid={true} className="h-100 mt-0 pt-0 pr-0">
        <Row className={styles.wrapper}>
          <DashboardSideBar
            active={!!sendPathname && this.trimPathname(sendPathname)}
            pathname={!!pathname && pathname}
          />
          <Col className={styles.container}>
            <Row>
              <Col className="ml-4 mr-4">{!!children && children}</Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    regularUser: state.regularUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetRegularUser: () => dispatch(GetRegularUser())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
