import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import store from 'store2';
import Common from '../Common/index';

const LoginLayout = (props) => {
  useEffect(() => {
    if (!!store.get('authentication')?.token) {
      props.history.push('/dashboard');
    }
  });

  return <Common {...props} />;
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication
  };
};

export default withRouter(connect(mapStateToProps)(LoginLayout));
