import { Col, Row } from 'react-bootstrap';

import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import styles from './styles.module.scss';

class SupportInfo extends React.Component {
  render() {
    return (
      <Row className={classnames(styles.container, 'p-3 p-xl-1')}>
        <div className="col-xs-12 col-xl-6 text-left d-flex justify-content-start align-items-center">
          <p dangerouslySetInnerHTML={{ __html: this.props.data.text }}></p>
        </div>
        <div
          className={classnames(
            'col-xs-12 col-xl-6 d-flex justify-content-center align-baseline',
            styles.mobile
          )}
        >
          <img src="/images/group-3.png" className={styles.image} />
        </div>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.language.dashboard.profile.supportInfo
  };
};

export default connect(mapStateToProps)(SupportInfo);

