import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { ConfigProvider, Empty, Form, Input, Select, Table, notification, Button } from 'antd';
import { Uploader } from '../../custom';
import classnames from 'classnames';
import moment from 'moment';
import styles from './styles.module.scss';
import { userServiceModule } from '../../../services/modules/userServiceModule';
import NumberFormat from 'react-number-format';
import { buildQueryString, defaultSpfParameters } from '../../../helpers/http/request';

class UserIncomeStatement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      annualincomeamount: '',
      documentList: [],
      documents: [],
      incomeStatements: [],
      pagination: {
        pageSize: 25,
        total: 1,
        current: 1
      }
    };

    this.uploadDocumentSuccess = this.uploadDocumentSuccess.bind(this);
    this.removeDocument = this.removeDocument.bind(this);
    this.baseState = this.state;
  }
  componentDidMount() {
    this.getIncomeStatements(this.state.pagination);
  }

  getIncomeStatements = (pagination) => {
    const queryString = buildQueryString({
      ...defaultSpfParameters,
      page: pagination.current,
      pageSize: pagination.pageSize
    });

    userServiceModule
      .getIncomeStatements(queryString)
      .then((response) => {
        this.setState((state) => ({
          incomeStatements: response.results.map((result) => ({ ...result, key: result.id })),
          pagination: {
            pageSize: response.pageSize,
            total: response.filteredRecordCount,
            current: response.currentPage
          }
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  onAnnualIncomeAmountChange = (e) => {
    if (!!e && !!e.value && !isNaN(e.value)) {
      this.setState({
        annualincomeamount: e.value
      });
    }
  };

  uploadDocumentSuccess = (uploadDocument) => {
    let documentObj = {
      key: uploadDocument.storageKey,
      uid: '-1',
      name: uploadDocument.name,
      status: 'done',
      url: uploadDocument.url
    };

    let documentList = [...this.state.documentList, documentObj].slice(-3);
    let documents = [...this.state.documents, uploadDocument].slice(-3);

    this.setState(() => ({
      documentList,
      documents
    }));

    notification.success({
      description: this.props.lang.uploadMessage,
      placement: 'bottomRight'
    });
  };

  removeDocument = (objectToDelete) => {
    this.setState((state) => ({
      documentList: state.documentList.filter((document) => document !== objectToDelete),
      documents: state.documents.filter(
        (document) => document.storageKey !== objectToDelete.storageKey
      )
    }));
  };

  formSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let payload = {
          amount: this.state.annualincomeamount,
          documents: this.state.documents.map((document) => ({
            documentStorageKey: document.storageKey
          }))
        };

        userServiceModule
          .postStateIncome(payload)
          .then(() => {
            notification.success({
              description: this.props.lang.sendSuccess,
              placement: 'bottomRight'
            });

            this.setState(this.baseState);

            const formValues = {
              StatementDocuments: [],
              annualincomeamount: ''
            };

            this.props.form.setFieldsValue(formValues);
            this.getIncomeStatements(this.state.pagination);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  };

  incomeStatementTableColumns = [
    {
      title: this.props.lang.tableColumnsStatementAmount,
      key: 'statementAmount',
      dataIndex: 'statementAmount',
      render: (statementAmount) => {
        return (
          <span>
            {
              <NumberFormat
                value={statementAmount}
                displayType="text"
                thousandSeparator="."
                prefix="₺"
                decimalSeparator=","
                decimalScale={2}
              />
            }
          </span>
        );
      }
    },
    {
      title: this.props.lang.tableColumnsStatementDate,
      key: 'requestDate',
      dataIndex: 'requestDate',
      render: (requestDate) => {
        return <span>{moment.utc(requestDate).local().format('DD.MM.YYYY')}</span>;
      }
    },
    {
      title: this.props.lang.tableColumnsStatementStatus,
      key: 'requestStatus',
      dataIndex: 'requestStatus',
      render: (requestStatus) => {
        return <span>{this.props.lang[requestStatus] ?? ' '}</span>;
      }
    },
    {
      title: this.props.lang.tableColumnsStatementResult,
      key: 'isRequestApproved',
      dataIndex: 'isRequestApproved',
      render: (isRequestApproved) => {
        return (
          <span>
            {isRequestApproved !== !!isRequestApproved
              ? this.props.lang.statementResultNull
              : !!isRequestApproved
              ? this.props.lang.statementResultOk
              : this.props.lang.statementResultFail}
          </span>
        );
      }
    }
  ];

  getEmptyTable = () => {
    return <Empty description={<p>{this.props.lang.emptyTable}</p>} />;
  };

  validateAnnualIncomeAmount = (rule, value, callback) => {
    const newValue =
      value.charAt(0) === '₺' ? value.substr(1).replace(/\./g, '') : value.replace(/\./g, '');

    if (!newValue) {
      callback(this.props.common.requiredField);
      return;
    }

    if (newValue > 99999999) {
      callback(this.props.common.errorMessageMax8);
      return;
    }

    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        <Row>
          <Col>
            <h1 className={classnames(styles.header)}>{this.props.lang.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={this.formSubmit}>
              <Row>
                <Col sm={12} md={6}>
                  <Form.Item label={this.props.lang.annualincomeamount} hasFeedback={true}>
                    {getFieldDecorator('annualincomeamount', {
                      rules: [
                        {
                          required: true,
                          validator: this.validateAnnualIncomeAmount
                        }
                      ],
                      initialValue: this.state.annualincomeamount
                    })(
                      <NumberFormat
                        className={styles.investInput}
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="input"
                        prefix="₺"
                        onValueChange={(e) => this.onAnnualIncomeAmountChange(e)}
                        maxLength={12}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm={6} md={3}>
                  <Form.Item label={this.props.lang.statementDocuments} hasFeedback={false}>
                    {getFieldDecorator('StatementDocuments', {
                      validateTrigger: ['onSuccess', 'onRemove'],
                      rules: [
                        {
                          required: true,
                          message: this.props.lang.requiredField
                        }
                      ],
                      initialValue: this.state.documentList
                    })(
                      <Uploader
                        key="StatementDocuments"
                        name="Documents"
                        accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                        fileList={this.state.documentList}
                        onSuccess={this.uploadDocumentSuccess}
                        onRemove={this.removeDocument}
                        text={this.props.lang.btnUpload}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col sm={6} md={3} className="text-right pt-5">
                  <Button type="primary" className={styles.button} htmlType="submit">
                    {this.props.lang.btnSend}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <Row>
          <Col>
            <h1 className={classnames(styles.title, 'mt-5 mb-5')}>
              {this.props.lang.previousStatementsHeader}
            </h1>
          </Col>
        </Row>

        <Row>
          <Col className={styles.activities}>
            <ConfigProvider renderEmpty={this.getEmptyTable}>
              <Table
                className={styles.ongoing}
                pagination={this.state.pagination}
                columns={this.incomeStatementTableColumns}
                dataSource={this.state.incomeStatements}
                onChange={this.getIncomeStatements}
              />
            </ConfigProvider>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.language.dashboard.incomeStatement,
    common: state.language.common
  };
};

export default connect(mapStateToProps)(
  Form.create({ name: 'UserIncomeStatement' })(UserIncomeStatement)
);

