import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Form, Input, Select } from 'antd';
import { Col, Row } from 'react-bootstrap';
import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { BroadcastChannel } from 'broadcast-channel';
import NumericInput from '../../custom/Input/NumericInput';
import Important from './important';
import { userServiceModule } from '../../../services/modules/userServiceModule';
import { GetRegularUser } from '../../../redux/RegularUser/actions';
import styles from './styles.module.scss';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

let identityVerificationBroadcastChannel;
let mkkLandingChannel;
let verificationWindow = null;
const VerifyUser = (props) => {
  const [isRedirectorInitialized, SetIsRedirectorInitialized] = useState(false);
  const [verifyWithEDevletResponse, SetVerifyWithEDevletResponse] = useState(null);
  const [broadcastChannelId] = useState(uuidv4());
  const [isNotTCCitizen, SetIsNotTCCitizen] = useState(false);
  const [hasBlueCard, SetHasBlueCard] = useState(false);

  identityVerificationBroadcastChannel = new BroadcastChannel(
    `IdentityVerification${broadcastChannelId}`
  );

  useEffect(() => {
    identityVerificationBroadcastChannel.onmessage = onMessageReceived;
    postInitializedMessage();
  }, []);

  useEffect(() => {
    if (!!isRedirectorInitialized && !!verifyWithEDevletResponse?.loginUrl) {
      const startMessage = {
        sender: 'verify',
        receiver: 'redirector',
        code: 'processstartpermitted',
        payload: {
          url: verifyWithEDevletResponse.loginUrl
        }
      };
      identityVerificationBroadcastChannel.postMessage(startMessage);
      SetIsRedirectorInitialized(false);
      props.GetRegularUser();
    }
  }, [isRedirectorInitialized, verifyWithEDevletResponse]);

  useEffect(() => {
    SetIsNotTCCitizen(props.form.getFieldValue('isNotTCCitizen'));
  }, [props.form.getFieldValue('isNotTCCitizen')]);

  useEffect(() => {
    SetHasBlueCard(props.form.getFieldValue('hasBlueCard'));
  }, [props.form.getFieldValue('hasBlueCard')]);

  const { getFieldDecorator } = props.form;
  const subscriptionType = Object.values(props.data.subscriptionSelectOptions).filter(
    (subscriptionTypes) => subscriptionTypes.key === 'Individual'
  )[0];

  const openIdentityVerificationRedirectorWindow = () => {
    const windowWidth = 900;
    const windowHeight = 750;
    const windowLeft = parseInt(window.innerWidth / 2) - parseInt(windowWidth / 2);
    const windowTop = parseInt(window.innerHeight / 2) - parseInt(windowHeight / 2);
    verificationWindow = window.open(
      `${window.location.origin}/identityverificationredirector?referenceCode=${broadcastChannelId}`,
      'MKK',
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${windowWidth}, height=${windowHeight}, top=${windowTop}, left=${windowLeft}`
    );
  };

  const onMessageReceived = (message) => {
    if (!!message && message.receiver === 'verify') {
      processBroadcastMessage(message);
    }
  };

  const processBroadcastMessage = (message) => {
    if (message.sender === 'redirector') {
      if (message.code === 'initialized') {
        SetIsRedirectorInitialized(true);
      }
      return;
    }
    if (message.sender === 'mkkLanding') {
      if (message.code === 'finished') {
        verificationWindow.close();
      }
    }
  };

  const validateBirthDate = (rule, value, callback) => {
    // value is a FUCKING MOMENT OBJECT!!
    if (!value) {
      callback(props.commonLanguage.requiredField);
      return;
    }

    if (!!value && moment.isMoment(value) && moment(value).isValid()) {
      callback();
      return;
    }

    callback(props.commonLanguage.requiredFormat);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        openIdentityVerificationRedirectorWindow();
        const trimmedName = values.name.trim();
        const name = trimmedName.replace(/\s+/g, ' ');
        const trimmedSurname = values.surname.trim();
        const surname = trimmedSurname.replace(/\s+/g, ' ');

        const identityType = values.isNotTCCitizen ? (!!hasBlueCard ? 'MKN' : 'YKN') : 'TCKN';
        const targetIdentityType = values.subscriptionType === 'Individual' ? identityType : 'VKN';

        let payload = {
          identityType: identityType,
          targetIdentityType: targetIdentityType,
          name,
          surname,
          identityNumber: values.idNumber
        };

        if (!!values.birthDate) {
          payload = {
            ...payload,
            birthDate: values.birthDate.format('YYYY-MM-DD')
          };
        }

        if (!!isNotTCCitizen && !!props.regularUser?.isForeignerIdentityAdditionalInfoMandatory) {
          const trimmedMotherFullName = values.motherFullName.trim();
          const motherFullName = trimmedMotherFullName.replace(/\s+/g, ' ');

          const trimmedFatherFullName = values.fatherFullName.trim();
          const fatherFullName = trimmedFatherFullName.replace(/\s+/g, ' ');

          if (!hasBlueCard) {
            const trimmedBirthPlace = values.birthPlace.trim();
            const birthPlace = trimmedBirthPlace.replace(/\s+/g, ' ');

            payload = {
              ...payload,
              birthPlace
            };
          }

          payload = {
            ...payload,
            motherFullName,
            fatherFullName,
            identityCardSerialNumber: values.identityCardSerialNumber
          };
        }

        userServiceModule
          .verifyWithEDevlet(payload)
          .then((response) => {
            SetVerifyWithEDevletResponse(response);
            mkkLandingChannel = new BroadcastChannel(`MkkLanding${response.syncRoot}`);
            mkkLandingChannel.onmessage = onMessageReceived;
          })
          .catch((error) => {
            if (!!verificationWindow) {
              verificationWindow.close();
            }
            console.error(error);
          })
          .finally(() => {});
      }
    });
  };

  const postInitializedMessage = () => {
    const initMessage = {
      sender: 'verify',
      receiver: 'redirector',
      code: 'initialized'
    };
    identityVerificationBroadcastChannel.postMessage(initMessage);
  };

  return (
    <div>
      <h1>{props.data.formTitle}</h1>
      <Row>
        <Col>
          <div className={classnames(styles.expContainer)}>
            <Form id="subscriptionVerificationForm" onSubmit={handleSubmit}>
              <Row>
                <Col sm={12} md={6} className={classnames(styles.border, 'pr-md-0')}>
                  <div className="pr-md-5">
                    <h3>{props.data.subscriptionType}</h3>
                    <Form.Item>
                      {getFieldDecorator('subscriptionType', {
                        validateTrigger: ['onChange'],
                        rules: [
                          {
                            required: true,
                            message: props.commonLanguage.requiredField
                          }
                        ],
                        initialValue: subscriptionType.key
                      })(
                        <Select>
                          {Object.values(props.data.subscriptionSelectOptions).map((option) => (
                            <Select.Option key={option.key}>{option.value}</Select.Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                    <h3>
                      {!!props.form.getFieldValue('subscriptionType') &&
                      props.form.getFieldValue('subscriptionType') === 'Individual'
                        ? props.data.identityVerification
                        : props.data.representativeVerification}
                    </h3>
                    <Row>
                      <Col sm={6}>
                        <Form.Item label={props.data.name} className="mb-0">
                          {getFieldDecorator('name', {
                            validateTrigger: ['onChange'],
                            rules: [
                              {
                                required: true,
                                message: props.commonLanguage.requiredField
                              },
                              {
                                max: 50,
                                message: props.commonLanguage.errorMessageMax50
                              }
                            ],
                            initialValue: props.regularUser?.name
                          })(<Input key="name" />)}
                        </Form.Item>
                      </Col>
                      <Col sm={6}>
                        <Form.Item label={props.data.surname} className="mb-0">
                          {getFieldDecorator('surname', {
                            validateTrigger: ['onChange'],
                            rules: [
                              {
                                required: true,
                                message: props.commonLanguage.requiredField
                              },
                              {
                                max: 50,
                                message: props.commonLanguage.errorMessageMax50
                              }
                            ],
                            initialValue: props.regularUser?.surname
                          })(<Input key="surname" />)}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      label={
                        !isNotTCCitizen
                          ? props.data.idNumberLabel
                          : !!hasBlueCard
                          ? props.data.blueCardNumberLabel
                          : props.data.foreignIdentityNumber
                      }
                      className="mb-0"
                    >
                      {getFieldDecorator('idNumber', {
                        validateTrigger: ['onChange'],
                        rules: [
                          {
                            required: true,
                            message: props.commonLanguage.requiredField
                          },
                          {
                            max: 11,
                            message: props.commonLanguage.errorMessageMax11
                          }
                        ],
                        initialValue: props.regularUser?.identityNumber
                      })(<NumericInput key="idNumber" maxLength={12} />)}
                    </Form.Item>
                    {!!isNotTCCitizen &&
                      !!props.regularUser?.isForeignerIdentityAdditionalInfoMandatory && (
                        <div>
                          {!hasBlueCard && (
                            <Form.Item label={props.data.identityCardSerialNumber} className="mb-0">
                              {getFieldDecorator('identityCardSerialNumber', {
                                validateTrigger: ['onChange'],
                                rules: [
                                  {
                                    required: true,
                                    message: props.commonLanguage.requiredField
                                  },
                                  {
                                    max: 255,
                                    message: props.commonLanguage.errorMessageMax255
                                  }
                                ],
                                initialValue: props.regularUser?.identityCardSerialNumber
                              })(<Input key="identityCardSerialNumber" />)}
                            </Form.Item>
                          )}
                          <Form.Item label={props.data.motherFullName} className="mb-0">
                            {getFieldDecorator('motherFullName', {
                              validateTrigger: ['onChange'],
                              rules: [
                                {
                                  required: true,
                                  message: props.commonLanguage.requiredField
                                },
                                {
                                  max: 100,
                                  message: props.commonLanguage.errorMessageMax100
                                }
                              ],
                              initialValue: props.regularUser?.motherFullName
                            })(<Input key="motherFullName" />)}
                          </Form.Item>
                          <Form.Item label={props.data.fatherFullName} className="mb-0">
                            {getFieldDecorator('fatherFullName', {
                              validateTrigger: ['onChange'],
                              rules: [
                                {
                                  required: true,
                                  message: props.commonLanguage.requiredField
                                },
                                {
                                  max: 100,
                                  message: props.commonLanguage.errorMessageMax100
                                }
                              ],
                              initialValue: props.regularUser?.fatherFullName
                            })(<Input key="fatherFullName" />)}
                          </Form.Item>
                          {!hasBlueCard && (
                            <Form.Item label={props.data.birthPlace} className="mb-0">
                              {getFieldDecorator('birthPlace', {
                                validateTrigger: ['onChange'],
                                rules: [
                                  {
                                    required: true,
                                    message: props.commonLanguage.requiredField
                                  },
                                  {
                                    max: 255,
                                    message: props.commonLanguage.errorMessageMax255
                                  }
                                ],
                                initialValue: props.regularUser?.birthPlace
                              })(<Input key="birthPlace" />)}
                            </Form.Item>
                          )}
                        </div>
                      )}
                    <Form.Item label={props.data.birthDate} hasFeedback>
                      {getFieldDecorator('birthDate', {
                        validateTrigger: ['onChange'],
                        rules: [
                          {
                            required:
                              !props.parameters?.clientParameters?.response
                                ?.bypassBirthDateVerification,
                            validator: validateBirthDate
                          }
                        ]
                      })(
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            inputFormat="DD/MM/YYYY"
                            value={props.form.getFieldValue('birthDate') || null}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: 'gg/aa/yyyy'
                                }}
                              />
                            )}
                            onChange={(date) => {
                              props.form.setFieldsValue({
                                birthDate: date
                              });
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    </Form.Item>
                    <Form.Item className="mb-0">
                      {getFieldDecorator('isNotTCCitizen', {
                        valuePropName: 'checked',
                        initialValue: false
                      })(<Checkbox key="isNotTCCitizen">{props.data.isNotTCCitizen}</Checkbox>)}
                    </Form.Item>
                    {!!isNotTCCitizen && (
                      <Form.Item className="mb-0">
                        {getFieldDecorator('hasBlueCard', {
                          valuePropName: 'checked',
                          initialValue: false
                        })(<Checkbox key="hasBlueCard">{props.data.hasBlueCard}</Checkbox>)}
                      </Form.Item>
                    )}
                    <Row
                      className={classnames(
                        'text-center justify-content-center align-items-center pt-sm-0'
                      )}
                    >
                      <Col className={styles.buttonContainer}>
                        <Button
                          htmlType="submit"
                          form="subscriptionVerificationForm"
                          type="primary"
                        >
                          {props.data.verifyWithEDevlet}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col sm={12} md={6} className="pl-md-0">
                  <div className="pl-md-5 mt-sm-5 mt-md-0">
                    <Important subscriptionType="Individual" />
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.language.dashboard.profile.kyc,
    commonLanguage: state.language.common,
    regularUser: state.regularUser,
    parameters: state.parameters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetRegularUser: () => dispatch(GetRegularUser())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(VerifyUser));
