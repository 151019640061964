import store from 'store2';

export const defaultSpfParameters = {
  sorts: null,
  filters: null,
  page: null,
  pageSize: null,
  countOnly: null
};

export const defaultLogParameters = {
  order: null,
  originator: 'Portal',
  category: null,
  data: null, //{token, subdata}
  class: null,
  originatorDateTime: null
};

export const GetTokenUniqueIdentifier = () => {
  const tokenString = store.get('authentication')?.token.split('.')[1];
  const uniqueIdentifier = JSON.parse(
    Buffer.from(tokenString, 'base64').toString('ascii')
  )?.UniqueIdentifier;

  return uniqueIdentifier;
};

export const buildQueryString = ({ sorts, filters, page, pageSize, countOnly }) => {
  let hasSorts = !!sorts && sorts.length > 0;
  let hasFilters = !!filters && filters.length > 0;

  let queryString = '';

  if (!!hasSorts) {
    let sortArray = [];
    sorts.map((sortItem) => {
      let sort = `${!!sortItem.isAscending ? '' : '-'}${sortItem.sortQuery}`;
      sortArray.push(sort);
    });
    queryString = build(queryString, 'sorts', sortArray.join(','));
  }

  if (!!hasFilters) {
    let filterArray = [];
    filters.map((filterItem) => {
      let filter = filterItem.filterColumn + filterItem.operator + filterItem.filterValue.join('|');
      filterArray.push(filter);
    });

    queryString = build(queryString, 'filters', filterArray.join(','));
  }

  if (!!page) {
    queryString = build(queryString, 'page', page);
  }

  if (!!pageSize) {
    queryString = build(queryString, 'pageSize', pageSize);
  }

  if (!!countOnly) {
    queryString = build(queryString, 'countOnly', countOnly);
  }

  return queryString;
};

const build = (queryString, key, value) => {
  queryString += `${
    queryString.indexOf('?') === -1 ? '?' : `${queryString.includes('=') ? '&' : ''}`
  }${key}=${value}`;

  return queryString;
};
