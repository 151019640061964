import {
  GET_USER_DEMOGRAPHICS_SUCCESS,
  GET_USER_DEMOGRAPHICS_ERROR,
  RESET_GET_USER_DEMOGRAPHICS,
  PATCH_USER_DEMOGRAPHICS_SUCCESS,
  PATCH_USER_DEMOGRAPHICS_ERROR,
  RESET_PATCH_USER_DEMOGRAPHICS,
  GET_USER_DEMOGRAPHICS_ADDRESS_TYPE_SUCCESS,
  GET_USER_DEMOGRAPHICS_ADDRESS_TYPE_ERROR,
  RESET_USER_DEMOGRAPHICS_ADDRESS_TYPE
} from '../actions';

const addressTypesInitialState = null;
const getUserDemographicsInitialState = null;
const patchUserDemographicsInitialState = null;

const initialState = {
  addressTypes: addressTypesInitialState,
  getUserDemographics: getUserDemographicsInitialState,
  patchUserDemographics: patchUserDemographicsInitialState
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DEMOGRAPHICS_SUCCESS:
    case GET_USER_DEMOGRAPHICS_ERROR:
      return { ...state, getUserDemographics: action.payload };
    case RESET_GET_USER_DEMOGRAPHICS:
      return { ...state, getUserDemographics: getUserDemographicsInitialState };
    case PATCH_USER_DEMOGRAPHICS_SUCCESS:
    case PATCH_USER_DEMOGRAPHICS_ERROR:
      return { ...state, patchUserDemographics: action.payload };
    case RESET_PATCH_USER_DEMOGRAPHICS:
      return { ...state, patchUserDemographics: patchUserDemographicsInitialState };
    case GET_USER_DEMOGRAPHICS_ADDRESS_TYPE_SUCCESS:
    case GET_USER_DEMOGRAPHICS_ADDRESS_TYPE_ERROR:
      return { ...state, addressTypes: action.payload };
    case RESET_USER_DEMOGRAPHICS_ADDRESS_TYPE:
      return { ...state, addressTypes: addressTypesInitialState };
    default:
      return state;
  }
};
