import { commonServiceModule } from '../services/modules/commonServiceModule';
import { commonHelper } from '../shared/common/commonHelper';

class PasswordPolicyHelper {
  constructor(props) {
    this.staticTexts = props;
  }

  getPasswordPolicyInfoMessages = (passwordPolicy, staticTexts) => {
    let passwordPolicyInfoMessages = [];

    if (!!passwordPolicy.hasMinimumLength)
      passwordPolicyInfoMessages.push(
        commonHelper.format(staticTexts.hasMinimumLength, passwordPolicy.minimumLength)
      );

    if (!!passwordPolicy.hasMaximumLength)
      passwordPolicyInfoMessages.push(
        commonHelper.format(staticTexts.hasMaximumLength, passwordPolicy.maximumLength)
      );

    if (!!passwordPolicy.hasMustBeDifferentFromOldPasswords)
      passwordPolicyInfoMessages.push(
        commonHelper.format(
          staticTexts.hasMustBeDifferentFromOldPasswords,
          passwordPolicy.mustBeDifferentFromOldPasswordsCount
        )
      );

    passwordPolicy.characterRequirements?.map((requirement) => {
      const langPropAccessor = requirement.charAt(0).toLowerCase() + requirement.slice(1);

      passwordPolicyInfoMessages.push(staticTexts[langPropAccessor]);
    });

    return passwordPolicyInfoMessages;
  };
}

export default PasswordPolicyHelper;
