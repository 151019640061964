import {
  GET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE,
  GET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE_SUCCESS,
  GET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE_ERROR,
  RESET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE,
  GET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT,
  GET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT_SUCCESS,
  GET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT_ERROR,
  RESET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT,
  GET_USER_PROJECTS,
  GET_USER_PROJECTS_SUCCESS,
  GET_USER_PROJECTS_ERROR,
  GET_USER_FAVORITE_PROJECTS,
  GET_USER_FAVORITE_PROJECTS_SUCCESS,
  GET_USER_FAVORITE_PROJECTS_ERROR,
  USER_FAVORITE_PROJECT,
  USER_FAVORITE_PROJECT_SUCCESS,
  USER_FAVORITE_PROJECT_ERROR,
  RESET_USER_FAVORITE_PROJECT
} from '../actions';

const individualQualificationStatementDocumentTemplateState = null;
const regularUserInvestmentLimitInitialState = null;
const userProjectsInitialState = null;
const userFavoriteProjectsInitialState = null;
const userFavoriteProjectInitialState = null;

const initialState = {
  individualQualificationStatementDocumentTemplate:
    individualQualificationStatementDocumentTemplateState,
  regularUserInvestmentLimit: regularUserInvestmentLimitInitialState,
  userProjects: userProjectsInitialState,
  userFavoriteProjects: userFavoriteProjectsInitialState,
  userFavoriteProject: userFavoriteProjectInitialState
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE:
      return state;
    case GET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE_SUCCESS:
      return { ...state, individualQualificationStatementDocumentTemplate: action.payload };
    case GET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE_ERROR:
      return state;
    case RESET_DASHBOARD_INVESTMENT_INDIVIDUAL_QUALIFICATION_STATEMENT_DOCUMENT_TEMPLATE:
      return {
        ...state,
        individualQualificationStatementDocumentTemplate:
          individualQualificationStatementDocumentTemplateState
      };
    case GET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT:
      return state;
    case GET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT_SUCCESS:
      return { ...state, regularUserInvestmentLimit: action.payload };
    case GET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT_ERROR:
      return state;
    case RESET_DASHBOARD_INVESTMENT_REGULAR_USER_INVESTMENT_LIMIT:
      return { ...state, regularUserInvestmentLimit: regularUserInvestmentLimitInitialState };

    case GET_USER_PROJECTS:
      return state;
    case GET_USER_PROJECTS_SUCCESS:
      return { ...state, userProjects: action.payload };
    case GET_USER_PROJECTS_ERROR:
      return state;

    case GET_USER_FAVORITE_PROJECTS:
      return state;
    case GET_USER_FAVORITE_PROJECTS_SUCCESS:
      return { ...state, userFavoriteProjects: action.payload };
    case GET_USER_FAVORITE_PROJECTS_ERROR:
      return state;

    case USER_FAVORITE_PROJECT:
      return state;
    case USER_FAVORITE_PROJECT_SUCCESS:
      return { ...state, userFavoriteProject: action.payload };
    case USER_FAVORITE_PROJECT_ERROR:
      return state;
    case RESET_USER_FAVORITE_PROJECT:
      return { ...state, userFavoriteProject: userFavoriteProjectInitialState };

    default:
      return state;
  }
};
