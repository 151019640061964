import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Navbar } from 'react-bootstrap';
import styles from './styles.module.scss';
import classnames from 'classnames';

const Brand = (props) => (
  <Col xs="auto">
    <Navbar.Brand className={styles.navbarBrand}>
      <Link onClick={props.closeNav} to={'/'}>
        <img
          alt=""
          src="/images/logo.png"
          className={classnames('d-inline-block align-top', styles.logo)}
        />
      </Link>
    </Navbar.Brand>
  </Col>
);

const mapStateToProps = (state) => {
  return {
    regularUser: state.regularUser
  };
};

export default connect(mapStateToProps)(Brand);
