import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import {
  Chart,
  LineElement,
  PointElement,
  Title,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import moment from 'moment';
import {
  GetProjectDailySpread,
  ResetProjectDailySpread
} from '../../../redux/ProjectDetail/actions';
import { usePrevious } from '../../../shared/common/hookHelper';
import styles from './styles.module.scss';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip,
  Legend,
  Title
);

const DailySpread = (props) => {
  const [chartData, SetChartData] = useState(null);
  const previousProps = usePrevious(props);

  useEffect(() => {
    const payload = {
      projectId: props.projectId
    };
    props.GetProjectDailySpread(payload);
  }, []);

  useEffect(() => {
    return () => {
      props.ResetProjectDailySpread();
    };
  }, []);

  useEffect(() => {
    if (
      previousProps?.projectDetail?.dailySpread?.response !==
        props.projectDetail?.dailySpread?.response &&
      !!!props.projectDetail?.dailySpread?.error
    ) {
      const data = {
        labels: props.projectDetail.dailySpread?.response?.actualBalance?.map((item) => {
          return moment.utc(item.date).local().format('DD/MM/YYYY');
        }),
        datasets: [
          {
            label: 'Approved',
            data: props.projectDetail.dailySpread?.response?.statusBalances?.Approved?.map(
              (item) => {
                return item.runningTotal;
              }
            ),
            fill: false,
            backgroundColor: 'rgba(124, 203, 159, 0.2)',
            borderColor: 'rgba(124, 203, 159, 1)'
          },
          {
            label: 'Committed',
            data: props.projectDetail.dailySpread?.response?.statusBalances?.Committed?.map(
              (item) => {
                return item.runningTotal;
              }
            ),
            fill: false,
            backgroundColor: 'rgba(163, 203, 124, 0.2)',
            borderColor: 'rgba(163, 203, 124, 1)'
          },
          {
            label: 'RefundPending',
            data: props.projectDetail.dailySpread?.response?.statusBalances?.RefundPending?.map(
              (item) => {
                return item.runningTotal;
              }
            ),
            fill: false,
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)'
          },
          {
            label: 'RefundProcessing',
            data: props.projectDetail.dailySpread?.response?.statusBalances?.RefundProcessing?.map(
              (item) => {
                return item.runningTotal;
              }
            ),
            fill: false,
            backgroundColor: 'rgba(105, 176, 33, 0.2)',
            borderColor: 'rgba(105, 176, 33, 1)'
          },
          {
            label: 'RefundedByDemand',
            data: props.projectDetail.dailySpread?.response?.statusBalances?.RefundedByDemand?.map(
              (item) => {
                return item.runningTotal;
              }
            ),
            fill: false,
            backgroundColor: 'rgba(33, 176, 86, 2)',
            borderColor: 'rgba(33, 176, 86, 1)'
          }
        ]
      };
      SetChartData(data);
    }
  }, [props.projectDetail.dailySpread]);

  return (
    <Row>
      <Col>
        {!!chartData && (
          <Line
            className={styles.canvas}
            data={chartData}
            options={{ maintainAspectRatio: false }}
          />
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    projectDetail: state.projectDetail
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetProjectDailySpread: (payload) => dispatch(GetProjectDailySpread(payload)),
    ResetProjectDailySpread: (payload) => dispatch(ResetProjectDailySpread(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DailySpread);
