import React from 'react';
import { connect } from 'react-redux';
import CompanyInfo from './companyInfo';

const PendingCorporate = (props) => {
  return (
    <div>
      {props.regularUser?.corporateIdentityStatus === 'Processing' ||
      props.regularUser?.corporateIdentityStatus === 'Pending' ? (
        <h3>{props.data.corpotatePendingVerification}</h3>
      ) : props.regularUser?.eDevletIdentityVerificationStatus !== 'Verified' &&
        props.regularUser?.eDevletIdentityVerificationStatus !== 'Cancelled' ? (
        <h3>{props.data.corporateIdentityPendingVerification}</h3>
      ) : (
        <CompanyInfo />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.language.dashboard.profile.kyc,
    regularUser: state.regularUser
  };
};

export default connect(mapStateToProps)(PendingCorporate);
