import ChangePassword from './ChangePassword/index';
import DashboardSideBar from './DashboardSideBar/index';
import DashboardWelcome from './DashboardWelcome/index';
import EmailSubscribe from './EmailSubscribe/index';
import EmptyFavorites from './EmptyFavorites/index';
import EmptyPortfolio from './EmptyPortfolio/index';
import EmptyProject from './EmptyProject/index';
import Identity from './Identity/index';
import InvestmentInfo from './Investment/index';
import LegalInfo from './LegalInfo/index';
import ProfileInfo from './ProfileInfo/index';
import ProjectContainer from './ProjectContainer/index';
import ProjectUpdates from './ProjectUpdates/index';
import SupportInfo from './SupportInfo/index';
import Tables from './Tables/index';
import UserIncomeStatement from './UserIncomeStatement/index';
import UserCorporateQualificationStatement from './UserCorporateQualificationStatement/index';
import ChangeRequest from './ChangeRequest/index';
import DeleteUser from './DeleteUser/index';

export {
  ChangePassword,
  DashboardSideBar,
  DashboardWelcome,
  EmailSubscribe,
  EmptyFavorites,
  EmptyPortfolio,
  EmptyProject,
  Identity,
  InvestmentInfo,
  LegalInfo,
  ProfileInfo,
  ProjectContainer,
  ProjectUpdates,
  SupportInfo,
  Tables,
  UserIncomeStatement,
  UserCorporateQualificationStatement,
  ChangeRequest,
  DeleteUser
};

