import 'antd/dist/antd.css';
import './styles/index.scss';
import './styles/global.scss';

import * as serviceWorker from './serviceWorker';

import AppRoutes from './routers/router';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import { configureStore } from './redux/store';
import { isMobile } from 'react-device-detect';
import CheckVersion from './checkVersion';

addLocaleData(en);
const store = configureStore();
const mainApp = (
  <Provider store={store}>
    {!isMobile && <CheckVersion />}
    <AppRoutes />
  </Provider>
);

ReactDOM.render(mainApp, document.getElementById('app'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
