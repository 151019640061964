import { Modal, Button } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import OneClickButton from '../OneClickButton';
import styles from './styles.module.scss';

const CustomModal = (props) => {
  const handleCancel = () => {
    return props.onCancel();
  };

  const handleOk = () => {
    let promise = Promise.resolve(props.onOk());

    return promise;
  };

  return (
    <Modal
      {...props}
      className={styles.modal}
      visible={props.visible}
      title={props.title ?? props.modal.modalAreYouSure}
      footer={[
        <Button key="cancelButton" onClick={handleCancel}>
          {props.cancelText ?? props.modal.modalCancelButton}
        </Button>,
        <OneClickButton key="okButton" className={styles.okButton} onClick={handleOk}>
          {props.okText ?? props.modal.modalOkButton}
        </OneClickButton>
      ]}
      zIndex={2500}
    ></Modal>
  );
};
const mapStateToProps = (state) => {
  return {
    modal: state.language.customModal
  };
};

export default connect(mapStateToProps)(CustomModal);
