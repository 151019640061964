import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import classnames from 'classnames';
import {
  GetProjectInfo,
  GetProjectFundingInfo,
  ResetProjectFundingInfo,
  ResetProjectInfo
} from '../../../redux/ProjectDetail/actions';
import FundingSummary from './fundingsummary';
import DailySpread from './dailyspread';
import InvestorSummary from './investorsummary';
import InvestmentTable from './investmenttable';
import InvestorTable from './investortable';
import CompletionSummary from './completionsummary';
import styles from './styles.module.scss';

const Tables = (props) => {
  useEffect(() => {
    const projectInfoPayload = {
      projectId: props.location.state.projectId
    };
    props.GetProjectInfo(projectInfoPayload);

    const projectFundingInfoPayload = {
      projectId: props.location.state.projectId,
      isPreview: false
    };
    props.GetProjectFundingInfo(projectFundingInfoPayload);
  }, []);

  useEffect(() => {
    return () => {
      props.ResetProjectInfo();
      props.ResetProjectFundingInfo();
    };
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Col className={classnames(styles.container, 'p-5 mt-0 mr-3 mb-4 ml-3')}>
          <FundingSummary projectId={props.location.state.projectId} />
        </Col>
      </Row>
      {props.projectDetail?.info?.status === 'Finished' && (
        <Row>
          <Col className={classnames(styles.container, 'p-5 mt-0 mr-3 mb-4 ml-3')}>
            <CompletionSummary projectId={props.location.state.projectId} />
          </Col>
        </Row>
      )}
      <Row>
        <Col className={classnames(styles.container, 'p-5 mt-0 mr-3 mb-4 ml-3')}>
          <DailySpread projectId={props.location.state.projectId} />
        </Col>
      </Row>
      <Row>
        <Col className={classnames(styles.container, 'p-5 mt-0 mr-3 mb-4 ml-3')}>
          <InvestorSummary projectId={props.location.state.projectId} />
        </Col>
      </Row>
      <Row>
        <Col className={classnames(styles.detailTable, 'p-5 mt-0 mr-3 mb-4 ml-3')}>
          <InvestmentTable
            projectId={props.location.state.projectId}
            decimalScale={
              !isNaN(
                Math.floor(
                  Number(props.projectDetail?.fundingInfo?.valuation) /
                    Number(props.projectDetail?.fundingInfo?.pricePerShare)
                )
              )
                ? Math.floor(
                    Number(props.projectDetail?.fundingInfo?.valuation) /
                      Number(props.projectDetail?.fundingInfo?.pricePerShare)
                  )
                : 8
            }
          />
        </Col>
      </Row>
      <Row>
        <Col className={classnames(styles.detailTable, 'p-5 mt-0 mr-3 mb-4 ml-3')}>
          <InvestorTable
            projectId={props.location.state.projectId}
            decimalScale={
              !isNaN(
                Math.floor(
                  Number(props.projectDetail?.fundingInfo?.targetFunding) /
                    Number(props.projectDetail?.fundingInfo?.pricePerShare)
                )
              )
                ? Math.floor(
                    Number(props.projectDetail?.fundingInfo?.targetFunding) /
                      Number(props.projectDetail?.fundingInfo?.pricePerShare)
                  )
                : 8
            }
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.language.dashboard.tables,
    projectDetail: state.projectDetail
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetProjectInfo: (payload) => dispatch(GetProjectInfo(payload)),
    GetProjectFundingInfo: (payload) => dispatch(GetProjectFundingInfo(payload)),
    ResetProjectInfo: (payload) => dispatch(ResetProjectInfo(payload)),
    ResetProjectFundingInfo: (payload) => dispatch(ResetProjectFundingInfo(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tables);
