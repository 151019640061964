import { getLanguage } from './language/language';

export const getCustomError = (errorRecord) => {
  let customError = {
    message: null,
    title: null
  };

  if (!!errorRecord.hasInnerErrors) {
    errorRecord = errorRecord.rootErrorRecord;
  }

  if (!!errorRecord.hasCustomErrorMessage && errorRecord.source === 'External') {
    customError.message = errorRecord.customErrorMessage;
    customError.title = errorRecord.originator;
    return customError;
  }

  let errorCodes = getLanguage(localStorage['langDefault']).errorCodes;

  customError.message = errorCodes[errorRecord.extendedErrorCode];
  customError.title = null;
  return customError;
};
