import 'react-image-crop/dist/ReactCrop.css';

import { Button, Icon, Row, Upload, Progress, Input, Checkbox, Tooltip } from 'antd';
import React from 'react';
import ReactCrop from 'react-image-crop';
import { commonServiceModule } from '../../../services/modules/commonServiceModule';
import styles from './styles.module.scss';
import CustomModal from '../CustomModal';
import { notify } from '../../../shared/notify';
import { connect } from 'react-redux';
import { th } from 'date-fns/locale';

class Uploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accept: this.props.accept || '*',
      allowCropping: this.props.allowCropping || false,
      buttonClassName: this.props.buttonClassName || styles.button,
      crop: this.props.crop || {
        unit: 'px',
        width: 350,
        aspect: 16 / 9
      },
      croppedFile: null,
      cropFileSource: null,
      file: null,
      fileList: this.props.fileList || [],
      initialCrop: this.props.crop || {
        unit: 'px',
        width: 350,
        aspect: 16 / 9
      },
      modalPopupVisible: false,
      originalFile: null,
      percent: 0,
      isAllowed: true,
      isSizeOk: true,
      isNameOk: true,
      isEmpty: true,
      documentTitle: '',
      useRealResolution: true
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.useRealResolution !== prevState.useRealResolution &&
      prevState.useRealResolution !== undefined &&
      !!this.state.crop
    ) {
      this.onCropComplete(this.state.crop);
    }
  }

  beforeUpload = (file) => {
    const isNameOk = file.name.length < 255;
    const isSizeOk = file.size < 1024 * 1024 * 32; // presicion fix!
    const isAllowed = this.state.accept.search(file.type) > -1;
    const isEmpty = file.size === 0;

    if (!!!isNameOk) {
      this.setStatetoInitial();
      notify('Error', this.props.lang.fileNameLimit);
    }

    if (!!!isSizeOk) {
      this.setStatetoInitial();
      notify('Error', this.props.lang.fileSizeLimit);
    }

    if (!!!isAllowed) {
      this.setStatetoInitial();
      notify('Error', this.props.lang.fileTypeMismatch);
    }

    if (!!isEmpty) {
      this.setStatetoInitial();
      notify('Error', this.props.lang.emptyFile);
    }

    this.setState({ isSizeOk, isAllowed, isNameOk, isEmpty });

    return isAllowed || isSizeOk || isNameOk || !isEmpty || false;
  };

  onStart = (file) => {
    if (
      !!this.state.allowCropping &&
      this.state.isSizeOk &&
      this.state.isAllowed &&
      this.state.isNameOk &&
      !this.state.isEmpty
    ) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({
          originalFile: file,
          cropFileSource: reader.result,
          modalPopupVisible: true
        })
      );
      reader.readAsDataURL(file);
    }
  };

  onSuccess = (uploadedFile) => {
    if (!!this.props.documentUpload) {
      uploadedFile = {
        ...uploadedFile,
        documentTitle: this.state.documentTitle.trim()
      };
    }

    if (!!this.props.onSuccess) {
      this.props.onSuccess(uploadedFile);
    }

    this.setStatetoInitial();
  };

  onRemove = (objectToDelete) => {
    this.setStatetoInitial();

    if (!!this.props.onRemove) {
      this.props.onRemove(objectToDelete);
    }
  };

  customRequest = async ({ file, filename }) => {
    let customRequestPromise;
    let uploadFilePromise;
    if (
      !!this.state.isAllowed &&
      !!this.state.isSizeOk &&
      !!this.state.isNameOk &&
      !this.state.isEmpty
    ) {
      if (this.props.customRequest) {
        customRequestPromise = this.props.customRequest(file, filename);
      } else {
        if (!!this.state.allowCropping) {
          if (!!this.state.croppedFile) {
            uploadFilePromise = this.uploadFile(file, filename);
          }
        } else {
          uploadFilePromise = this.uploadFile(file, filename);
        }
      }

      let promises = [customRequestPromise, uploadFilePromise];
      return Promise.all(promises);
    }
  };

  uploadFile = async (file, filename) => {
    let promise;
    const formData = new FormData();

    if (file instanceof File) {
      formData.append(file.name, file);
    } else {
      formData.append(filename, file, filename);
    }

    const onUploadProgress = (event) => {
      const percent = Math.floor((event.loaded / event.total) * 100);
      this.setState({ percent });
      if (percent === 100) {
        setTimeout(() => this.setState({ percent: 0 }), 500);
      }
    };

    promise = commonServiceModule.uploadEx(formData, onUploadProgress);
    if (promise && Promise.resolve(promise)) {
      promise
        .then((response) => {
          let obj = {
            storageKey: response.fileKey,
            url: response.publicUrl,
            publicUrl: response.publicUrl,
            name: file.name
          };

          if (!!this.props.exportFile) obj = { ...obj, file };

          this.onSuccess(obj);
        })
        .catch((error) => {
          notify('Error', error.errorMessage);
        });
    }

    return promise;
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop: crop });
  };

  async makeClientCrop(crop) {
    if (!!this.imageRef && crop.width && crop.height) {
      const croppedFile = await this.getCroppedImg(
        this.imageRef,
        crop,
        this.state.originalFile.name
      );

      this.setState({ croppedFile });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = !!this.state.useRealResolution ? crop.width * scaleX : crop.width;
    canvas.height = !!this.state.useRealResolution ? crop.height * scaleY : crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      !!this.state.useRealResolution ? crop.width * scaleX : crop.width,
      !!this.state.useRealResolution ? crop.height * scaleY : crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          resolve(blob);
        },
        'image/jpeg',
        0.95
      );
    });
  }

  confirmCrop = async () => {
    let promise = this.customRequest({
      file: this.state.croppedFile,
      filename: this.state.originalFile.name
    });
    return promise;
  };

  cancelCrop = () => {
    this.setStatetoInitial();
  };

  setStatetoInitial = () => {
    this.setState((state) => ({
      modalPopupVisible: false,
      originalFile: null,
      cropFileSource: null,
      croppedFile: null,
      crop: state.initialCrop,
      documentTitle: ''
    }));
  };

  render() {
    return (
      <Row>
        {!!this.props.documentUpload && (
          <div>
            <label>{this.props.lang.documentTitle}</label>
            <Input
              type="text"
              value={this.state.documentTitle}
              onChange={(e) => {
                this.setState({ documentTitle: e.target.value });
              }}
            />
          </div>
        )}
        <Upload
          {...this.props}
          key={this.props.name + 'Upload'}
          accept={this.state.accept}
          beforeUpload={this.beforeUpload}
          customRequest={this.customRequest}
          fileList={this.props.fileList}
          disabled={this.props.disabled}
          onStart={this.onStart}
          onSuccess={this.onSuccess}
          onRemove={this.onRemove}
        >
          <Button
            key={this.props.name + 'Button'}
            className={this.state.buttonClassName}
            disabled={this.props.documentUpload && !this.state.documentTitle.trim()}
          >
            <Icon key={this.props.name + 'Icon'} type="upload" />
            {' ' + this.props.text}
          </Button>
        </Upload>
        {this.state.percent > 0 ? <Progress percent={this.state.percent} /> : null}

        <CustomModal
          visible={this.state.modalPopupVisible}
          onOk={this.confirmCrop}
          onCancel={this.cancelCrop}
          title={''}
          okText={this.props.confirm}
          cancelText={this.props.close}
          maskClosable={false}
        >
          <Row>
            {!!this.state.cropFileSource && (
              <ReactCrop
                src={this.state.cropFileSource}
                crop={this.state.crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
          </Row>
          <Row>
            <Checkbox
              onChange={() => this.setState({ useRealResolution: !this.state.useRealResolution })}
              checked={this.state.useRealResolution}
            >
              <Tooltip title={this.props.lang.useRealResolutionInfo}>
                <b className="mr-1">{this.props.lang.useRealResolution}</b>
                <Icon type="question-circle-o" />
              </Tooltip>
            </Checkbox>
          </Row>
        </CustomModal>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.language.uploader
  };
};

export default connect(mapStateToProps)(Uploader);

