import { Col, Container, Row } from 'react-bootstrap';
import {
  faFacebook,
  faLinkedinIn,
  faXTwitter,
  faInstagram,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { Button, Input, Form } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import styles from './styles.module.scss';
import { SubscribeToBulletin } from '../../redux/Bulletin/actions';
import validator from 'validator';

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      eMailAddress: ''
    };
  }

  onEmailChange = (e) => {
    const eMailAddress = e.target.value;
    this.setState({ eMailAddress });
  };

  emailSubscribe = (e) => {
    e.preventDefault();
    const payload = {
      eMailAddress: this.state.eMailAddress
    };

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.SubscribeToBulletin(payload);
      }
    });
  };

  validateEMail = (rule, value, callback) => {
    if (!value) {
      callback(this.props.common.requiredField);
      return;
    }

    if (value.length > 128) {
      callback(this.props.common.errorMessageMax128);
      return;
    }

    if (!!value && validator.isEmail(value)) {
      callback();
      return;
    }

    callback(this.props.data.errorMessageEMail);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className={styles.footer}>
        <Row className={styles.wrapper}>
          <Col className={styles.orderReverse} xs={12}>
            <Row>
              <Col lg={3} md={3} sm={12} xs={12} className="d-flex justify-content-center">
                <div className={classnames(styles.logo, styles.column)}>
                  <Row>
                    <Col
                      className="d-flex align-items-center justify-content-center"
                      lg={12}
                      md={12}
                      sm={6}
                      xs={12}
                    >
                      <img src="/images/footer-logo.png" alt="FonTr" className={styles.image} />
                    </Col>
                    <Col lg={12} md={12} sm={6} xs={12}>
                      <Row className="justify-content-center mb-3 mt-3">
                        <Col xs={12}>
                          <a href="mailto:info@fontr.com.tr" className={styles.email}>
                            e-posta: info@fontr.com.tr
                          </a>
                        </Col>
                        <Col xs={12}>
                          <a href="tel:0312 473 4083" className={styles.email}>
                            Telefon: 0312 473 4083
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={2} md={2} sm={4} xs={4} className={'pr-0'}>
                <p className={styles.header}>{this.props.data.institutional}</p>
                <div className={classnames('d-flex flex-column', styles.column)}>
                  <Link to="/about" className={styles.link}>
                    {this.props.data.about}
                  </Link>
                  <Link to="/investment-committee" className={styles.link}>
                    {this.props.data.investmentCommittee}
                  </Link>
                  <Link to="/faq" className={styles.link}>
                    {this.props.data.faq}
                  </Link>
                  <Link to="/announcements" className={styles.link}>
                    {this.props.data.announcements}
                  </Link>
                  <Link to="/contact" className={styles.link}>
                    {this.props.data.communication}
                  </Link>
                </div>
              </Col>
              <Col lg={2} md={2} sm={4} xs={4} className={'pr-0'}>
                <p className={styles.header}>{this.props.data.policies}</p>
                <div className={classnames('d-flex flex-column', styles.column)}>
                  <Link to="/information-security-policy" className={styles.link}>
                    {this.props.data.informationSecurityPolicy}
                  </Link>
                  <Link to="/conflict-of-interest-policy" className={styles.link}>
                    {this.props.data.conflictOfInterestPolicy}
                  </Link>
                  <Link to="/evaluation-policy" className={styles.link}>
                    {this.props.data.evaluationPolicy}
                  </Link>
                  <Link to="/human-resources-policy" className={styles.link}>
                    {this.props.data.humanResourcesPolicy}
                  </Link>
                  <Link to="/antibribery-anticorruption-policy" className={styles.link}>
                    {this.props.data.antiBriberyAntiCorruptionPolicy}
                  </Link>
                  <Link to="/prevention-of-terrorist-financing-policy" className={styles.link}>
                    {this.props.data.preventionOfTerroristFinancingPolicy}
                  </Link>
                  <Link to="/quality-policy" className={styles.link}>
                    {this.props.data.qualityPolicy}
                  </Link>
                </div>
              </Col>
              <Col lg={2} md={2} sm={4} xs={4} className={'pr-0'}>
                <p className={styles.header}>{this.props.data.notifications}</p>
                <div className={classnames('d-flex flex-column', styles.column)}>
                  <Link to="/user-agreement" className={styles.link}>
                    {this.props.data.userAgreement}
                  </Link>
                  <Link to="/entrepreneur-agreement" className={styles.link}>
                    {this.props.data.entrepreneurAgreement}
                  </Link>
                  <Link to="/confidentiality-information" className={styles.link}>
                    {this.props.data.confidentialityPolicy}
                  </Link>
                  <Link to="/risk-notification" className={styles.link}>
                    {this.props.data.riskNotification}
                  </Link>
                  <Link to="/fees-and-deductions" className={styles.link}>
                    {this.props.data.feesAndDeductions}
                  </Link>
                  <Link to="/project-publishing-notification-form" className={styles.link}>
                    {this.props.data.projectPublishingNotificationForm}
                  </Link>
                </div>
              </Col>
              <Col xl={2} lg={3} md={3} sm={12} xs={12} className="d-flex justify-content-center">
                <div className={classnames(styles.subscribe, styles.column)}>
                  <Row className="d-flex justify-content-center">
                    <Col lg={12} md={12} sm={6} xs={12}>
                      <h4 className={styles.email}>{this.props.data.bulletinSave}</h4>
                      <p className={styles.email}>{this.props.data.bulletinText}</p>
                    </Col>
                    <Col lg={12} md={12} sm={6} xs={12}>
                      <Form onSubmit={this.emailSubscribe}>
                        <Form.Item>
                          <Input.Group compact>
                            {getFieldDecorator('eMailAddress', {
                              rules: [
                                {
                                  required: true,
                                  validator: this.validateEMail
                                }
                              ]
                            })(
                              <Input
                                style={{ width: 'calc(100% - 100px)', maxWidth: '200px' }}
                                onChange={this.onEmailChange}
                                placeholder={this.props.data.eMailAddress}
                              />
                            )}
                            <Button
                              onClick={this.emailSubscribe}
                              type="primary"
                              htmlType="submit"
                              className={styles.button}
                            >
                              {this.props.data.subscribe}
                            </Button>
                          </Input.Group>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xl={3} lg={3} md={3} sm={12} xs={12} className="d-flex justify-content-center">
                <div className={styles.icons}>
                    <Link
                      to={{ pathname: 'https://www.facebook.com/fontr.c0mtr/' }}
                      target="_blank"
                      style={{ marginRight: '15px' }}
                    >
                      <FontAwesomeIcon icon={faFacebook} />
                    </Link>
                    <Link
                      to={{ pathname: 'https://twitter.com/fontr_comtr' }}
                      target="_blank"
                      style={{ marginRight: '15px' }}
                    >
                      <FontAwesomeIcon icon={faXTwitter} />
                    </Link>
                    <Link
                      to={{ pathname: 'https://www.instagram.com/fontr_com.tr/' }}
                      target="_blank"
                      style={{ marginRight: '15px' }}
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </Link>
                    <Link
                      to={{
                        pathname: 'https://www.linkedin.com/company/fontr-comtr/?viewAsMember=true'
                      }}
                      target="_blank"
                      style={{ marginRight: '15px' }}
                    >
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </Link>
                    <Link
                      to={{ pathname: 'https://www.youtube.com/@fontr_comtr' }}
                      target="_blank"
                      style={{ marginRight: '15px' }}
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.language.footer,
    common: state.language.common
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SubscribeToBulletin: (payload) => dispatch(SubscribeToBulletin(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'Footer' })(Footer));
