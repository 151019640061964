import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Select } from 'antd';
import { Col, Row } from 'react-bootstrap';
import styles from '../styles.module.scss';
import {
  GetUserDemographics,
  GetUserDemographicsAddressType,
  PatchUserDemographics,
  ResetGetUserDemographics
} from '../../../../redux/UserDemographics/actions';
import { notify } from '../../../../shared/notify';
import { userServiceModule } from '../../../../services/modules/userServiceModule';
import { OneClickButton } from '../../../custom/index';
import classnames from 'classnames';
import {
  GetGeoAddressCountries,
  GetGeoAddressCountryStateCities,
  GetGeoAddressCountryStates,
  ResetGeoAddressCountries,
  ResetGeoAddressCountryStateCities,
  ResetGeoAddressCountryStates
} from '../../../../redux/GeoAddress/actions';
import { usePrevious } from '../../../../shared/common/hookHelper';
import OpenAddressTextArea from './OpenAddressTextArea';

function AddressForm(props) {
  const addressInitialState = {
    country: null,
    state: null,
    city: null,
    addressLine: null
  };
  const [address, SetAddress] = useState(addressInitialState);
  const previousProps = usePrevious(props);

  useEffect(() => {
    props.GetUserDemographicsAddressType();
    props.GetGeoAddressCountries();

    return () => {
      props.ResetGeoAddressCountries();
      props.ResetGeoAddressCountryStates();
      props.ResetGeoAddressCountryStateCities();
      props.ResetGetUserDemographics();
    };
  }, []);

  useEffect(() => {
    const address = props.userDemographics?.getUserDemographics?.response?.addresses[0];
    if (!!address) {
      SetAddress({
        country: address.countryCode,
        state: address.stateOrProvinceCode,
        city: address.cityName,
        addressLine: address.addressLine
      });
    }
  }, [props.userDemographics?.getUserDemographics?.response]);

  useEffect(() => {
    if (props.geoAddress?.countries?.response !== previousProps?.geoAddress?.countries?.response) {
      getCountryStates(address.country);
    }
  }, [props.geoAddress?.countries?.response]);

  useEffect(() => {
    if (
      props.geoAddress?.countryStates?.response !==
      previousProps?.geoAddress?.countryStates?.response
    ) {
      getCountryStateCities(address.country, address.state);
    }
  }, [props.geoAddress?.countryStates?.response]);

  const addressChange = (field, value) => {
    switch (field) {
      case 'addressType':
        SetAddress({
          ...address,
          addressType: value
        });
        break;
      case 'country':
        props.form.setFieldsValue({
          state: props.profileInfo.selectState,
          city: props.profileInfo.selectCity
        });
        SetAddress({
          ...address,
          country: value
        });
        getCountryStates(value);
        break;
      case 'state':
        props.form.setFieldsValue({ city: props.profileInfo.selectCity });
        SetAddress({
          ...address,
          state: value
        });
        getCountryStateCities(address.country, value);
        break;
      case 'city':
        SetAddress({
          ...address,
          city: value
        });
        break;
      case 'addressLine':
        SetAddress({
          ...address,
          addressLine: value
        });
        break;
      default:
        break;
    }
  };

  const getCountryStates = (countryCode) => {
    if (!!countryCode) {
      const getStatesPayload = {
        countryCode
      };
      props.GetGeoAddressCountryStates(getStatesPayload);
    }
    props.ResetGeoAddressCountryStates();
    props.ResetGeoAddressCountryStateCities();
  };

  const getCountryStateCities = (countryCode, stateOrProvinceCode) => {
    if (!!countryCode && !!stateOrProvinceCode) {
      const getCitiesPayload = {
        countryCode,
        stateOrProvinceCode
      };
      props.GetGeoAddressCountryStateCities(getCitiesPayload);
    }
    props.ResetGeoAddressCountryStateCities();
  };

  const validateAddressType = (rule, value, callback) => {
    if (!value || value == props.profileInfo.selectAddressType) {
      callback(props.common.requiredSelect);
      return;
    }

    if (value.length > 128) {
      callback(props.common.errorMessageMax128);
      return;
    }

    callback();
    return;
  };

  const validateCountry = (rule, value, callback) => {
    if (!value || value == props.profileInfo.selectCountry) {
      callback(props.common.requiredSelect);
      return;
    }

    if (value.length > 128) {
      callback(props.common.errorMessageMax128);
      return;
    }

    callback();
    return;
  };

  const validateState = (rule, value, callback) => {
    if (!value || value == props.profileInfo.selectState) {
      callback(props.common.requiredSelect);
      return;
    }

    if (value.length > 128) {
      callback(props.common.errorMessageMax128);
      return;
    }

    callback();
    return;
  };

  const validateCity = (rule, value, callback) => {
    if (!value || value == props.profileInfo.selectCity) {
      callback(props.common.requiredSelect);
      return;
    }

    if (value.length > 128) {
      callback(props.common.errorMessageMax128);
      return;
    }

    callback();
    return;
  };

  const validateAddressLine = (rule, value, callback) => {
    if (!value) {
      callback(props.common.requiredField);
      return;
    }

    if (value.length > 255) {
      callback(props.common.errorMessageMax255);
      return;
    }

    callback();
    return;
  };

  const addAddress = async () => {
    let promise;
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          addresses: [
            {
              type: values.addressType,
              countryCode: values.country,
              stateOrProvinceCode: values.state !== 'none' ? values.state : null,
              cityName: values.city !== 'none' ? values.city : null,
              addressLine: values.addressLine,
              overrideMode: 'Override'
            }
          ]
        };

        promise = userServiceModule.patchUserDemographics(payload);
        if (promise && Promise.resolve(promise)) {
          promise.then(() => {
            notify('Success', props.profileInfo.updateSuccess);
          });
        }
        // props.PatchUserDemographics(payload);
      }
    });
    return promise;
  };

  return (
    <Form id="AddressForm" layout={'inline'} colon={false}>
      <Row>
        <Col xs={12}>
          {props.userDemographics?.addressTypes?.response?.length > 0 && (
            <Form.Item label={props.profileInfo.addressType} hasFeedback={false}>
              {props.form.getFieldDecorator('addressType', {
                rules: [
                  {
                    required: true,
                    validator: validateAddressType
                  }
                ],
                initialValue:
                  props.userDemographics?.getUserDemographics?.response?.addresses[0]?.type ??
                  props.profileInfo.selectAddressType
              })(
                <Select
                  className={styles.formInput}
                  onChange={(value) => addressChange('addressType', value)}
                >
                  {props.userDemographics.addressTypes.response
                    .filter((addressType) => addressType.name !== 'None')
                    .map((addressType) => {
                      return (
                        <Select.Option key={addressType.name} value={addressType.name}>
                          {props.profileInfo[addressType.name]}
                        </Select.Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          )}
        </Col>
        <Col xs={12}>
          <Form.Item label={props.profileInfo.country} hasFeedback={false}>
            {props.form.getFieldDecorator('country', {
              rules: [
                {
                  required: true,
                  validator: validateCountry
                }
              ],
              initialValue:
                props.userDemographics?.getUserDemographics?.response?.addresses[0]?.countryCode ??
                props.profileInfo.selectCountry
            })(
              <Select
                className={styles.formInput}
                onChange={(value) => addressChange('country', value)}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {props.geoAddress?.countries?.response?.map((item) => {
                  return (
                    <Select.Option key={item.id} value={item.iso2}>
                      {localStorage['langDefault'] === 'TR' ? item.translations.tr : item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label={props.profileInfo.state} hasFeedback={false}>
            {props.form.getFieldDecorator('state', {
              rules: [
                {
                  required: true,
                  validator: validateState
                }
              ],
              initialValue:
                props.userDemographics?.getUserDemographics?.response?.addresses[0]
                  ?.stateOrProvinceCode ?? props.profileInfo.selectState
            })(
              <Select
                className={styles.formInput}
                onChange={(value) => addressChange('state', value)}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Select.Option key="none" value="none">
                  {props.profileInfo.none}
                </Select.Option>
                {props.geoAddress?.countryStates?.response?.map((item) => (
                  <Select.Option key={item.id} value={item.stateCode}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label={props.profileInfo.city} hasFeedback={false}>
            {props.form.getFieldDecorator('city', {
              rules: [
                {
                  required: true,
                  validator: validateCity
                }
              ],
              initialValue:
                props.userDemographics?.getUserDemographics?.response?.addresses[0]?.cityName ??
                props.profileInfo.selectCity
            })(
              <Select
                className={styles.formInput}
                onChange={(value) => addressChange('city', value)}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Select.Option key="none" value="none">
                  {props.profileInfo.none}
                </Select.Option>
                {props.geoAddress?.countryStateCities?.response?.map((item) => (
                  <Select.Option key={item.id} value={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label={props.profileInfo.addressLine} hasFeedback={false}>
            {props.form.getFieldDecorator('addressLine', {
              rules: [
                {
                  required: true,
                  validator: validateAddressLine
                }
              ],
              initialValue:
                props.userDemographics?.getUserDemographics?.response?.addresses[0]?.addressLine
            })(
              <Input.TextArea
                onChange={(e) => addressChange('addressLine', e.target.value)}
                className={styles.formInput}
                placeholder={props.profileInfo.addressLinePlaceholder}
                rows={3}
              ></Input.TextArea>
            )}
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label={props.profileInfo.openAddress}>
            <OpenAddressTextArea address={address} />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item>
            <OneClickButton
              onClick={addAddress}
              className={classnames(styles.button, styles.addressButton)}
            >
              {props.profileInfo.btnSave}
            </OneClickButton>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

const mapStateToProps = (state) => {
  return {
    profileInfo: state.language.dashboard.profile.profileInfo,
    regularUser: state.regularUser,
    common: state.language.common,
    geoAddress: state.geoAddress,
    userDemographics: state.userDemographics
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetGeoAddressCountries: () => dispatch(GetGeoAddressCountries()),
    ResetGeoAddressCountries: () => dispatch(ResetGeoAddressCountries()),
    GetGeoAddressCountryStates: (payload) => dispatch(GetGeoAddressCountryStates(payload)),
    ResetGeoAddressCountryStates: () => dispatch(ResetGeoAddressCountryStates()),
    GetGeoAddressCountryStateCities: (payload) =>
      dispatch(GetGeoAddressCountryStateCities(payload)),
    ResetGeoAddressCountryStateCities: () => dispatch(ResetGeoAddressCountryStateCities()),
    GetUserDemographics: () => dispatch(GetUserDemographics()),
    ResetGetUserDemographics: () => dispatch(ResetGetUserDemographics()),
    PatchUserDemographics: (payload) => dispatch(PatchUserDemographics(payload)),
    GetUserDemographicsAddressType: () => dispatch(GetUserDemographicsAddressType())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'AddressForm' })(AddressForm));
