import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { usePrevious } from '../../../shared/common/hookHelper';

/**
 * if props.onClick function will do some async calls
 * then be sure to return a Promise from that function for to debouncing to work properyly
 */
const OneClickButton = (props) => {
  const [disabled, setDisabled] = useState(props.disabled);
  const previousProps = usePrevious(props);

  useEffect(() => {
    if (previousProps?.disabled !== props?.disabled) {
      setDisabled(props.disabled);
    }
  }, [props.disabled]);

  const onOneClickButtonClick = () => {
    setDisabled(true);

    Promise.resolve(props.onClick())
      .catch((error) => {
        // ignore error
      })
      .finally(() => {
        setDisabled(false);
      });
  };
  return <Button {...props} onClick={onOneClickButtonClick} disabled={disabled}></Button>;
};

export default OneClickButton;
