import { GET_REGULAR_USER } from '../actions';
import { GetRegularUserSuccess, GetRegularUserError, GetRegularUser } from './actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import { userServiceModule } from '../../services/modules/userServiceModule';

export default function* rootSaga() {
  yield all([fork(watchGetRegularUser)]);
}

function* watchGetRegularUser() {
  yield takeLeading(GET_REGULAR_USER, getRegularUser);
}

function* getRegularUser(action) {
  try {
    const response = yield call(getRegularUserAsync);
    yield put(GetRegularUserSuccess({ ...response, isLoaded: true }));
  } catch (error) {
    yield put(GetRegularUserError(error));
  }
}

const getRegularUserAsync = async () => {
  return userServiceModule.getRegularUser();
};
