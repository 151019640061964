import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import classnames from 'classnames';
import { Pie } from 'react-chartjs-2';
import {
  Chart,
  LineElement,
  PointElement,
  Title,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { projectServiceModule } from '../../../services/modules/projectServiceModule';
import styles from './styles.module.scss';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip,
  Legend,
  Title
);

const InvestorSummary = (props) => {
  const [investmentPieData, SetInvestmentPieData] = useState();
  const [investorData, SetInvestorData] = useState();

  useEffect(() => {
    getInvestmentPieData();
  }, []);

  const getInvestmentPieData = () => {
    projectServiceModule.getPieData(props.projectId).then((response) => {
      let investmentbyAccountQualify = response.investmentsByAccountQualification;
      let licencePercentage = 0;
      let nonLicencePercentage = 0;

      investmentbyAccountQualify.map((item) => {
        if (item.isQualified) {
          licencePercentage = item.fundingPercent.toFixed(2);
        } else if (!item.isQualified) {
          nonLicencePercentage = item.fundingPercent.toFixed(2);
        }
      });

      let data = {
        labels: [props.language.nonLicence, props.language.licence],
        datasets: [
          {
            data: [nonLicencePercentage, licencePercentage],
            backgroundColor: [dynamicColors(), dynamicColors()]
          }
        ],
        options: {
          legend: {
            display: false
          }
        }
      };
      SetInvestmentPieData(data);

      let investmentbyAccountOwner = response.investmentsByAccountOwner;
      let labelData = [];
      let dataSet = [];
      let colors = [];

      investmentbyAccountOwner.map((item) => {
        labelData.push(item.ownerName);
        dataSet.push(item.fundingPercent.toFixed(2));
        colors.push(dynamicColors());
      });

      let investorData = {
        labels: labelData,
        datasets: [
          {
            data: dataSet,
            backgroundColor: colors
          }
        ]
      };

      SetInvestorData(investorData);
    });
  };

  const dynamicColors = () => {
    var random = Math.random();
    var red = 11 + Math.floor(random * (106 - 11));
    var green = 23 + Math.floor(random * (207 - 23));
    var blue = 48 + Math.floor(random * (255 - 48));

    return 'rgb(' + red + ',' + green + ',' + blue + ')';
  };

  return (
    <section>
      <Row>
        <Col sm={12}>
          <h3 className={styles.tablePie}>{props.language.investingSummary}</h3>
        </Col>
      </Row>
      {!!props.projectDetail?.info &&
        !!!props.projectDetail?.info?.error &&
        !!props.projectDetail?.fundingInfo &&
        !!!props.projectDetail?.fundingInfo?.error &&
        !!investmentPieData &&
        !!investorData && (
          <Row>
            <Col sm={6}>
              <Row>
                <Col sm={4} className={classnames('offset-sm-4', styles.doughnutHeader)}>
                  <p>{props.language.investorStatusChartTitle}</p>
                </Col>
                <Col sm={12}>
                  <Pie
                    data={investmentPieData}
                    height={350}
                    options={{
                      maintainAspectRatio: false,
                      legend: {
                        display: false
                      },
                      tooltips: {
                        callbacks: {
                          label: function (tooltipItem, data) {
                            var investor = data.labels[tooltipItem.index];
                            var percentage =
                              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                            return investor + ': ' + percentage + '%';
                          }
                        }
                      }
                    }}
                  />
                </Col>
                <Col sm={12} className={styles.chartText}>
                  ₺{' '}
                  {Number(props.projectDetail.fundingInfo.collectedFunding ?? 0) +
                    Number(props.projectDetail.fundingInfo.approvedFunding ?? 0)}
                  {' ' + props.language.funded}
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Row>
                <Col sm={4} className={classnames('offset-sm-4', styles.doughnutHeader)}>
                  <p>{props.language.investorOwnershipChartTitle}</p>
                </Col>
                <Col sm={12}>
                  <Pie
                    data={investorData}
                    height={350}
                    options={{
                      maintainAspectRatio: false,
                      legend: {
                        display: false
                      },
                      tooltips: {
                        callbacks: {
                          label: function (tooltipItem, data) {
                            var investor = data.labels[tooltipItem.index];
                            var percentage =
                              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                            return investor + ': ' + percentage + '%';
                          }
                        }
                      }
                    }}
                  />
                </Col>
                <Col sm={12} className={styles.chartText}>
                  {props.projectDetail.fundingInfo.investorCount}{' '}
                  {' ' + props.language.fundingTotal}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.dashboard.tables,
    projectDetail: state.projectDetail
  };
};

export default connect(mapStateToProps)(InvestorSummary);
