import { all, fork, takeEvery } from 'redux-saga/effects';
import { notify } from '../../shared/notify';
import { NOTIFY } from '../actions';

export default function* rootSaga() {
  yield all([fork(watchNotify)]);
}

function* watchNotify() {
  yield takeEvery(NOTIFY, Notify);
}

function Notify(action) {
  notify(action.payload.status, action.payload.message);
}
