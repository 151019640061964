import { Col, Container, Row } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import styles from './styles.module.scss';

class DashboardWelcome extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Row className={styles.header}>
          <Col sm={12}>
            <h1>
              {this.props.data.welcome}{' '}
              {this.props.regularUser?.fullName ?? this.props.regularUser?.username?.toUpperCase()}
            </h1>
          </Col>
          <Col sm={12}>
            {!!this.props.regularUser?.fullName && (
              <p
                dangerouslySetInnerHTML={{
                  __html: this.props.data.regularUserInformation
                }}
              ></p>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Container>
              <Row>
                <Col md={6} className={classnames(styles.columns, styles.first)}>
                  <Link to="/projects">
                    <img src="/images/see-project.png" alt="" />
                    <p>{this.props.data.investOptions}</p>
                  </Link>
                </Col>
                <Col md={6} className={classnames(styles.columns, styles.second)}>
                  <Link to="/createProject">
                    <img src="/images/create-project.png" alt="" />
                    <p>{this.props.data.getInvest}</p>
                  </Link>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    regularUser: state.regularUser,
    data: state.language.dashboard.welcome
  };
};

export default connect(mapStateToProps)(DashboardWelcome);
