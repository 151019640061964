/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { Col, Row } from 'react-bootstrap';
import classnames from 'classnames';
import { commonHelper } from '../../../shared/common/commonHelper';
import styles from './styles.module.scss';
import {
  GetIndividualQualificationStatementDocumentTemplate,
  ResetIndividualQualificationStatementDocumentTemplate,
  GetRegularUserInvestmentLimit,
  ResetRegularUserInvestmentLimit
} from '../../../redux/Dashboard/actions';
import FileDownload from '../../custom/FileDownload/index';

const InvestmentInfo = (props) => {
  const [limitSubText, setLimitSubText] = useState(null);

  useEffect(() => {
    if (props.regularUser?.identityVerified && !props.dashboard?.regularUserInvestmentLimit)
      getRegularUserInvestmentLimit();

    if (
      props.regularUser?.identityVerified &&
      props.regularUser?.identityType !== 'VKN' &&
      !!!props.dashboard?.individualQualificationStatementDocumentTemplate
    ) {
      getIndividualQualificationStatementDocumentTemplate();
    }
  }, [props.regularUser?.identityVerified]);

  useEffect(() => {
    setLimitSubText(
      props.regularUser?.identityVerified
        ? commonHelper.format(
            props.dashboardProfileInvestmentLanguage.limitSubText,
            ...(!!props.dashboard?.regularUserInvestmentLimit?.isQualified ||
            !!props.dashboard?.regularUserInvestmentLimit?.limitInfo?.isUnlimited
              ? ['', props.dashboardProfileInvestmentLanguage.unlimitedLimit]
              : props.dashboard?.regularUserInvestmentLimit?.limitInfo?.amount
              ? ['₺', props.dashboard?.regularUserInvestmentLimit?.limitInfo?.amount]
              : ['', ''])
          )
        : props.dashboardProfileInvestmentLanguage.unidentifiedUserInfoText
    );
  }, [props.dashboard?.regularUserInvestmentLimit]);

  const getRegularUserInvestmentLimit = () => {
    return props.GetRegularUserInvestmentLimit();
  };

  const getIndividualQualificationStatementDocumentTemplate = () => {
    return props.GetIndividualQualificationStatementDocumentTemplate();
  };

  return (
    <Row className={classnames(styles.container, 'p-5')}>
      <Col
        sm={12}
        className={classnames(props.regularUser?.identityVerified ? styles.border : '', 'px-0')}
      >
        <h4>{props.dashboardProfileInvestmentLanguage.limitText}</h4>
        {!!limitSubText && <p>{limitSubText}</p>}
        {!!props.regularUser?.identityVerified && (
          <Col sm={12} className={styles.licenceInfo}>
            <Row>
              <Col sm={12} className={styles.buttonContainer}>
                <Button type="primary" onClick={getRegularUserInvestmentLimit}>
                  {props.dashboardProfileInvestmentLanguage.findOutInvestmentLimit}
                </Button>
              </Col>
            </Row>
            <Row className={styles.more}>
              <Col sm={12}>
                <a href="#">{props.dashboardProfileInvestmentLanguage.moreInfo}</a>
              </Col>
            </Row>
          </Col>
        )}
      </Col>
      {props.regularUser?.identityVerified &&
        (props.regularUser?.identityType === 'VKN' ? (
          <Col sm={12} className={styles.licenceInfo}>
            <Row>
              <Col sm={12} className={styles.buttonContainer}>
                <Button type="primary">
                  <Link to="/dashboard/corporateQualificationStatement">
                    {props.dashboardProfileInvestmentLanguage.corporateQualificationStatement}
                  </Link>
                </Button>
              </Col>
            </Row>
            <Row className={styles.more}>
              <Col sm={12}>
                <a href="#">{props.dashboardProfileInvestmentLanguage.moreInfo}</a>
              </Col>
            </Row>
          </Col>
        ) : (
          <Col sm={12} className={styles.licenceInfo}>
            <Row>
              <Col
                sm={!!props.dashboard?.individualQualificationStatementDocumentTemplate ? 6 : 12}
                className={styles.buttonContainer}
              >
                <Row>
                  <Col sm={12} className={styles.buttonContainer}>
                    <Button type="primary">
                      <Link to="/dashboard/incomestatement">
                        {props.dashboardProfileInvestmentLanguage.incomeStatement}
                      </Link>
                    </Button>
                  </Col>
                </Row>
                <Row className={styles.more}>
                  <Col sm={12}>
                    <a href="#">{props.dashboardProfileInvestmentLanguage.moreInfo}</a>
                  </Col>
                </Row>
              </Col>
              {!!props.dashboard?.individualQualificationStatementDocumentTemplate && (
                <Col sm={6} className={styles.buttonContainer}>
                  <Row>
                    <Col sm={12} className={styles.buttonContainer}>
                      <FileDownload
                        type="button"
                        url={props.dashboard.individualQualificationStatementDocumentTemplate.url}
                        text={
                          props.dashboardProfileInvestmentLanguage.individualQualificationStatement
                        }
                      />
                    </Col>
                  </Row>
                  <Row className={styles.more}>
                    <Col sm={12}>
                      <a href="#">{props.dashboardProfileInvestmentLanguage.moreInfo}</a>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        ))}
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    dashboardProfileInvestmentLanguage: state.language.dashboard.profile.investment,
    regularUser: state.regularUser,
    dashboard: state.dashboard
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetRegularUserInvestmentLimit: () => dispatch(GetRegularUserInvestmentLimit()),
    ResetRegularUserInvestmentLimit: () => dispatch(ResetRegularUserInvestmentLimit()),
    GetIndividualQualificationStatementDocumentTemplate: () =>
      dispatch(GetIndividualQualificationStatementDocumentTemplate()),
    ResetIndividualQualificationStatementDocumentTemplate: () =>
      dispatch(ResetIndividualQualificationStatementDocumentTemplate())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestmentInfo);
