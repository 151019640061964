/* eslint-disable jsx-a11y/label-has-associated-control */

import { Button, Checkbox, Form } from 'antd';
import { Col, Row } from 'react-bootstrap';

import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { notify } from '../../../shared/notify';
import styles from './styles.module.scss';
import { userServiceModule } from '../../../services/modules/userServiceModule';

class Emailsubscribe extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checkedValues: {
        0: false,
        1: false,
        2: false,
        3: false
      }
    };
  }

  componentDidMount() {
    userServiceModule.getEmailSubscription().then((response) => {
      if (Object.keys(response).length > 0) {
        for (var i = 0; i < Object.keys(response).length; ++i) {
          let checkedItems = this.state.checkedValues;
          switch (response[i]) {
            case 1:
              checkedItems[0] = true;
              this.setState({ checkedValues: checkedItems });
              break;
            case 2:
              checkedItems[1] = true;
              this.setState({ checkedValues: checkedItems });
              break;
            case 4:
              checkedItems[2] = true;
              this.setState({ checkedValues: checkedItems });
              break;
            case 8:
              checkedItems[3] = true;
              this.setState({ checkedValues: checkedItems });
              break;

            default:
              break;
          }
        }
      }
    });
  }

  checkedHandler = (e, id) => {
    const checked = e.target.checked;
    let checkedItems = this.state.checkedValues;
    checkedItems[id] = checked;
    this.setState({ checkedValues: checkedItems });
  };

  emailSubscribe = (e) => {
    e.preventDefault();

    let emailArray = [];
    if (this.state.checkedValues[0] === true) {
      emailArray.push(1);
    }
    if (this.state.checkedValues[1] === true) {
      emailArray.push(2);
    }
    if (this.state.checkedValues[2] === true) {
      emailArray.push(4);
    }
    if (this.state.checkedValues[3] === true) {
      emailArray.push(8);
    }

    let obj = {
      eMailSubscription: emailArray
    };

    userServiceModule.updateEmailSubscription(obj).then((response) => {
      notify('Info', this.props.data.emailNotification);
    });
  };

  render() {
    const { form, data } = this.props;
    const { checkedValues } = this.state;
    return (
      <Row className={classnames(styles.container, 'p-5 mb-4')}>
        <Col className="px-0">
          <h4>{this.props.data.title}</h4>
          <Form className="pl-3">
            <Row>
              <div className="col-md-8 col-sm-12 col-xs-12">
                {data.emailList.map((item, i) => (
                  <Row key={i}>
                    <Col>
                      <Form.Item hasFeedback={false} className="mb-0">
                        {form.getFieldDecorator('chkSubscriptions_' + i, {
                          valuePropName: 'checked',
                          initialValue: checkedValues[i]
                        })(
                          <label className={styles.checkbox}>
                            <Checkbox
                              key={i}
                              checked={this.state.checkedValues[i]}
                              onChange={(event) => {
                                this.checkedHandler(event, i);
                              }}
                            />
                            <span>{item.text}</span>
                          </label>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
              </div>
              <Col md={4} sm={12} className="align-items-center d-none d-md-block">
                <div
                  className={classnames(
                    styles.centered,
                    'd-flex align-xs-center justify-xs-center text-center'
                  )}
                >
                  <img src="/images/email-subscribe.png" alt="" />
                </div>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col className={styles.buttonContainer}>
              <Button className={styles.button} type="primary" onClick={this.emailSubscribe}>
                {data.btnSave}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.language.dashboard.profile.eMailSubscribe
  };
};

export default connect(mapStateToProps)(Form.create({ name: 'Emailsubscribe' })(Emailsubscribe));
