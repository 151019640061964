import { Col, Row } from 'react-bootstrap';
import { Button } from 'antd';
import React, { useState } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import styles from './styles.module.scss';
import { userServiceModule } from '../../../services/modules/userServiceModule';
import { CustomModal } from '../../custom/index';
import { notify } from '../../../shared/notify';

const DeleteUser = (props) => {
  const [modalVisible, SetModalVisible] = useState(false);

  const delUser = () => {
    let promise = userServiceModule.deleteUser();
    if (promise && Promise.resolve(promise)) {
      promise
        .then(() => {
          notify('Success', props.data.notify);
          SetModalVisible(false);
          setTimeout(() => {
            userServiceModule.clearAuthenticationInfo();
          }, 500);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    return promise;
  };

  return (
    <React.Fragment>
      <Row className={classnames(styles.container, 'p-5 mr-md-2')}>
        <Col xs={12}>
          <h5>{props.data.header}</h5>
        </Col>
        <Col>
          <p>{props.data.text}</p>
          <p className={styles.error}>
            {
              props.errorCodes[
                props.regularUser?.userDeactivationInfo?.noCanReason?.extendedErrorCode
              ]
            }
          </p>
        </Col>
        <Col xs={12}>
          <Button
            onClick={() => SetModalVisible(true)}
            className={styles.button}
            disabled={
              !props.regularUser?.userDeactivationInfo?.canDelete &&
              !props.regularUser?.userDeactivationInfo?.canDeactivate
            }
          >
            {props.data.button}
          </Button>
        </Col>
      </Row>

      <CustomModal
        visible={modalVisible}
        onOk={delUser}
        onCancel={() => SetModalVisible(false)}
        title={props.data.modalTitle}
        okText={props.data.okText}
        cancelText={props.data.cancelText}
      >
        <p>
          <strong>{props.data.modalText}</strong>
        </p>
      </CustomModal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.language.dashboard.profile.deleteUser,
    errorCodes: state.language.errorCodes,
    regularUser: state.regularUser
  };
};

export default connect(mapStateToProps)(DeleteUser);
