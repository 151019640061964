import { SUBSCRIBE_TO_BULLETIN } from '../actions';
import { SubscribeToBulletinSuccess, SubscribeToBulletinError } from './actions';
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { commonServiceModule } from '../../services/modules/commonServiceModule';
import { Notify } from '../Notification/actions';

export default function* rootSaga() {
  yield all([fork(watchSubscribeToBulletin)]);
}

function* watchSubscribeToBulletin() {
  yield takeEvery(SUBSCRIBE_TO_BULLETIN, subscribeToBulletin);
}

function* subscribeToBulletin(action) {
  try {
    const response = yield call(subscribeToBulletinAsync, action.payload);
    yield put(SubscribeToBulletinSuccess(response));

    const notificationPayload = {
      status: 'Success',
      message: yield select((state) => state.language.footer.bulletinInfo)
    };
    yield put(Notify(notificationPayload));
  } catch (error) {
    yield put(SubscribeToBulletinError(error));
  }
}

const subscribeToBulletinAsync = async (payload) => {
  return commonServiceModule.bulletinSubscribe(payload);
};
