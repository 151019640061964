import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'antd';
import { Col, Row } from 'react-bootstrap';
import styles from './styles.module.scss';

const TermModal = (props) => {
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const [termTextDomElement, setTermTextDomElement] = useState(null);

  const reset = () => {
    setScrolledToBottom(false);
  };

  const handleCancel = () => {
    props.onCancel();
  };

  const handleOk = () => {
    props.onOk();
  };

  const handleScroll = (e) => {
    const heightDelta = 5;
    const atBottom =
      Math.floor(e.target.scrollHeight - e.target.scrollTop) <= e.target.clientHeight + heightDelta;

    if (atBottom) {
      setScrolledToBottom(atBottom);
    }
  };

  useEffect(() => {
    if (!!termTextDomElement) {
      if (
        termTextDomElement.scrollTop === 0 &&
        termTextDomElement.clientHeight === termTextDomElement.scrollHeight
      )
        setScrolledToBottom(true);
    }
  }, [termTextDomElement]);

  return (
    <Modal
      visible={props.visible}
      onCancel={handleCancel}
      afterClose={reset}
      title={props.term.documentTitle}
      footer={null}
      centered
      zIndex={2500}
    >
      <Row>
        <Col>
          <div>
            <p
              className={styles.container}
              onScroll={handleScroll}
              ref={(el) => setTermTextDomElement(el)}
            >
              <span dangerouslySetInnerHTML={{ __html: props.term.documentHtmlData }} />
            </p>
          </div>
        </Col>
      </Row>
      {scrolledToBottom && (
        <Row>
          <Col style={{ marginLeft: 'auto' }}>
            <div className="text-right">
              <Button className={styles.button} type="primary" onClick={handleOk}>
                {props.common.btnApprove}
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    common: state.language.common
  };
};

export default connect(mapStateToProps)(TermModal);
