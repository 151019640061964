import { notification } from 'antd';

export const notify = (type, message, title = '', placement = 'bottomRight') => {
  switch (type) {
    case 'Success':
      notification.success({
        message: title,
        description: message,
        placement
      });
      break;
    case 'Info':
      notification.info({
        message: title,
        description: message,
        placement
      });
      break;
    case 'Error':
      notification.error({
        message: title,
        description: message,
        placement
      });
      break;
    case 'Warning':
      notification.warning({
        message: title,
        description: message,
        placement
      });
      break;
    default:
      notification.info({
        message: title,
        description: message,
        placement
      });
  }
};
