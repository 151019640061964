import store from 'store2';
import {
  makeGetRequest,
  makePostRequest,
  makePutRequest,
  makePatchRequest,
  makeDeleteRequest,
  makeLoginRequest
} from '../request';

class UserServiceModule {
  authenticateUser = (payload) => {
    // return makePostRequest('authenticate', payload);
    return makeLoginRequest(payload);
  };

  refreshToken = (payload) => {
    return makePostRequest('authenticate/refresh', payload);
  };

  forgetPassword = (payload) => {
    return makePostRequest('user/forgotpassword', payload);
  };

  resetPassword = (payload) => {
    return makePostRequest('user/resetpassword', payload);
  };

  changePassword = (payload) => {
    return makePutRequest('user/changepassword', payload);
  };

  signUp = (payload) => {
    return makePostRequest('user', payload);
  };

  ticketResolve = (payload) => {
    return makeGetRequest('ticket/resolve?ticket=' + payload);
  };

  ticketReproduce = (payload) => {
    return makePostRequest('ticket/reproduce', payload);
  };

  userFavoriteProject = (id, payload, showNotifications = true) => {
    return makePutRequest(`user/favor/project/${id}`, payload, showNotifications);
  };

  getUserFavoriteProjects = (queryString) => {
    return makeGetRequest(`user/favoredprojects${queryString}`);
  };

  getRegularUser = () => {
    return makeGetRequest('user/profile/regular');
  };

  logoutUser = () => {
    const authenticationToken = store.get('authentication')?.token;

    if (!!authenticationToken) {
      return makePostRequest('authenticate/logout', authenticationToken)
        .then(() => {
          this.clearAuthenticationInfo();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.clearAuthenticationInfo();
    }
  };

  clearAuthenticationInfo = () => {
    // just clear authentication
    store.remove('authentication');

    window.location.assign('/');
  };

  getSystemMessages = () => {
    return makeGetRequest('user/message/system?PageSize=-1');
  };

  getThreadMessages = (threadUniqueId) => {
    return makeGetRequest(`user/message/thread/${threadUniqueId}/messages?PageSize=-1`);
  };

  getThreadUniqueId = (userUniqueId, showNotifications = false) => {
    return makeGetRequest(`user/message/threadbetween/${userUniqueId}`, showNotifications);
  };

  getThreads = () => {
    return makeGetRequest('user/message/threads');
  };

  sendMessage = (payload) => {
    return makePostRequest('user/message', payload);
  };

  readMessage = (payload) => {
    return makePatchRequest('user/messages', payload);
  };

  getEmailSubscription = () => {
    return makeGetRequest('user/emailsubscription');
  };

  updateEmailSubscription = (payload) => {
    return makePutRequest('user/emailsubscription', payload);
  };

  changeEmail = (payload) => {
    return makePostRequest('user/changeemail', payload);
  };

  getBirthDate = (payload) => {
    return makeGetRequest('user/birthdate', payload);
  };

  updateBirthDate = (payload, showNotifications = true) => {
    return makePutRequest('user/birthdate', payload, showNotifications);
  };

  changePhoneNumber = (payload) => {
    return makePostRequest('user/changephonenumber', payload);
  };

  eDevletPostback = (payload) => {
    return makePostRequest('user/identity/edevletverificationpostback', payload);
  };

  verifyWithEDevlet = (payload) => {
    return makePostRequest('user/identity/edevletverificationrequest', payload);
  };

  getCorporateIdentity = () => {
    return makeGetRequest('user/identity/corporate');
  };

  postCorporateInfo = (payload) => {
    return makePostRequest('user/identity/corporate', payload);
  };

  getPortfolioDigest = (sumsOnly, window, unit, queryString) => {
    return makeGetRequest(
      'user/portfolio/digest' +
        (!!sumsOnly ? '/sums' : '') +
        (!!window ? `/${window}` : '') +
        (!!unit ? `/${unit}` : '') +
        (!!queryString ? queryString : '')
    );
  };

  getPortfolioAllTransactions = (queryString) => {
    return makeGetRequest('user/portfolio/all' + queryString);
  };

  userRefund = (payload) => {
    return makePostRequest('user/invest/requestrefund/' + payload);
  };

  userinvest = (id, payload) => {
    return makePostRequest('user/invest/' + id, payload);
  };

  getUserLimit = () => {
    return makeGetRequest(`user/invest/limit`);
  };

  getUserProjects = (queryString) => {
    return makeGetRequest(`user/projects${queryString}`);
  };

  removeUserInvestment = (id) => makeDeleteRequest('user/invest/' + id);

  postStateIncome = (payload) => {
    return makePostRequest(`user/account/stateincome`, payload);
  };

  getIncomeStatements = (queryString) => {
    return makeGetRequest(`user/account/incomestatements${queryString}`);
  };

  postStateCorporateQualification = (payload) => {
    return makePostRequest(`user/account/stateCorporateQualification`, payload);
  };

  getCorporateQualificationStatements = (queryString) => {
    return makeGetRequest(`user/account/corporateQualificationStatements${queryString}`);
  };

  getAcceptedTerms = () => {
    return makeGetRequest('user/acceptedterms');
  };

  postNotifyError = (paymentId, payload) => {
    return makePostRequest(`user/invest/creditcard/notifyerror/${paymentId}`, payload);
  };

  putMkkSclNumber = (payload) => {
    return makePutRequest('user/mkkinfo', payload);
  };

  getUserDemographics = () => {
    return makeGetRequest('user/demographics');
  };

  patchUserDemographics = (payload) => {
    return makePatchRequest('user/demographics', payload);
  };

  deleteUser = () => {
    return makeDeleteRequest('user');
  };

  removeComment = (commentId) => makeDeleteRequest('user/comments/' + commentId);
}

export const userServiceModule = new UserServiceModule();
