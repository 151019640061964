import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';
import styles from './styles.module.scss';
import VerifiedCorporate from './verifiedcorporate';
import VerifiedIndividual from './verifiedindividual';

const VerifiedUser = (props) => {
  return (
    <div>
      <h1>{props.data.formTitle}</h1>
      <Row>
        <Col>
          <div className={classnames(styles.expContainer)}>
            {props.regularUser?.identityType === 'VKN' ? (
              <VerifiedCorporate />
            ) : (
              <VerifiedIndividual />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.language.dashboard.profile.kyc,
    commonData: state.language.common,
    regularUser: state.regularUser
  };
};

export default connect(mapStateToProps)(VerifiedUser);
