import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { OneClickButton } from '../../../custom';
import { notify } from '../../../../shared/notify';
import { GetRegularUser } from '../../../../redux/RegularUser/actions';
import { userServiceModule } from '../../../../services/modules/userServiceModule';
import styles from '../styles.module.scss';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function BirthDateForm(props) {
  const [birthDate, SetBirthDate] = useState(null);
  const changeBirthDate = async () => {
    let promise;

    if (!!birthDate) {
      const payload = {
        birthDate: birthDate.format('YYYY-MM-DD')
      };

      promise = userServiceModule
        .updateBirthDate(payload, false)
        .then((response) => {
          props.GetRegularUser();
          notify('Success', props.profileInfo.birthDateChangeNotify);
        })
        .catch((error) => {
          if (error?.errorMessage?.extendedErrorCode === 'Integration_Nvi_IdentityNotFound') {
            notify('Error', props.profileInfo.birthDateChangeErrorNotify);
          }
        });
    } else {
      notify('Error', props.profileInfo.birthDateEmptyError);
    }

    return promise;
  };

  return (
    <Form id="BirthDateForm" layout={'inline'} colon={false}>
      <Row>
        <Col>
          <Input.Group compact>
            <Form.Item label={props.profileInfo.birthDate} hasFeedback={false}>
              {props.form.getFieldDecorator('birthDate', {
                initialValue: moment(props.regularUser?.birthDate, 'YYYY-MM-DD'),
                rules: [
                  {
                    required: true
                  }
                ]
              })(
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'gg/aa/yyyy'
                        }}
                      />
                    )}
                    onChange={(newValue) => {
                      SetBirthDate(newValue);
                    }}
                    value={birthDate}
                  />
                </LocalizationProvider>
              )}
            </Form.Item>
            <Form.Item>
              <OneClickButton
                onClick={changeBirthDate}
                className={styles.button}
                form="BirthDateForm"
              >
                {props.profileInfo.btnSave}
              </OneClickButton>
            </Form.Item>
          </Input.Group>
        </Col>
      </Row>
    </Form>
  );
}

const mapStateToProps = (state) => {
  return {
    profileInfo: state.language.dashboard.profile.profileInfo,
    regularUser: state.regularUser,
    common: state.language.common
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetRegularUser: () => dispatch(GetRegularUser())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'BirthDateForm' })(BirthDateForm));

