import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import { loadResponse, loadError, loadSpfResponse } from '../../helpers/http/response';
import { commonServiceModule } from '../../services/modules/commonServiceModule';
import { projectServiceModule } from '../../services/modules/projectServiceModule';

import {
  UPDATE_PROJECT_WIZARD_FUNDING_INFO,
  UPSERT_PROJECT_WIZARD_PROJECT_DOCUMENTS,
  CREATE_PROJECT_WIZARD_STATIC_DOCUMENT,
  GET_PROJECT_WIZARD_ANNOUNCEMENT_URL,
  GET_PROJECT_WIZARD_IMAGES,
  UPSERT_PROJECT_WIZARD_IMAGES
} from '../actions';
import {
  UpdateFundingInfoSuccess,
  UpdateFundingInfoFail,
  UpsertProjectDocumentsSuccess,
  UpsertProjectDocumentsFail,
  CreateProjectStaticDocumentSuccess,
  CreateProjectStaticDocumentFail,
  GetAnnouncementUrlSuccess,
  GetAnnouncementUrlFail,
  GetProjectImagesSuccess,
  GetProjectImagesError,
  UpsertProjectImagesSuccess,
  UpsertProjectImagesError
} from './actions';

export default function* rootSaga() {
  yield all([
    fork(watchUpdateFundingInfo),
    fork(watchUpsertProjectDocuments),
    fork(watchGetAnnouncementUrlSuccess),
    fork(watchCreateProjectStaticDocument),
    fork(watchGetProjectImages),
    fork(watchUpsertProjectImages)
  ]);
}

function* watchUpdateFundingInfo() {
  yield takeLeading(UPDATE_PROJECT_WIZARD_FUNDING_INFO, UpdateFundingInfo);
}

function* watchUpsertProjectDocuments() {
  yield takeLeading(UPSERT_PROJECT_WIZARD_PROJECT_DOCUMENTS, UpsertProjectDocuments);
}

function* watchCreateProjectStaticDocument() {
  yield takeLeading(CREATE_PROJECT_WIZARD_STATIC_DOCUMENT, CreateProjectStaticDocument);
}

function* watchGetAnnouncementUrlSuccess() {
  yield takeLeading(GET_PROJECT_WIZARD_ANNOUNCEMENT_URL, GetAnnouncementUrl);
}

function* watchGetProjectImages() {
  yield takeLeading(GET_PROJECT_WIZARD_IMAGES, GetProjectImages);
}
function* watchUpsertProjectImages() {
  yield takeLeading(UPSERT_PROJECT_WIZARD_IMAGES, UpsertProjectImages);
}

function* UpdateFundingInfo(action) {
  try {
    const response = yield call(UpdateFundingInfoAsync, action);
    const responsePayload = loadResponse(action.payload, response);
    yield put(UpdateFundingInfoSuccess(responsePayload));
  } catch (error) {
    const errorPayload = loadError(action.payload, error);
    yield put(UpdateFundingInfoFail(errorPayload));
  }
}

function* UpsertProjectDocuments(action) {
  try {
    const response = yield call(UpsertProjectDocumentsAsync, action);
    const responsePayload = loadResponse(action.payload, response);
    yield put(UpsertProjectDocumentsSuccess(responsePayload));
  } catch (error) {
    const errorPayload = loadError(action.payload, error);
    yield put(UpsertProjectDocumentsFail(errorPayload));
  }
}

function* CreateProjectStaticDocument(action) {
  try {
    const response = yield call(CreateProjectStaticDocumentAsync, action);
    const responsePayload = loadResponse(action.payload, response);
    yield put(CreateProjectStaticDocumentSuccess(responsePayload));
  } catch (error) {
    const errorPayload = loadError(action.payload, error);
    yield put(CreateProjectStaticDocumentFail(errorPayload));
  }
}

function* GetAnnouncementUrl(action) {
  try {
    const response = yield call(GetAnnouncementUrlAsync, action);
    const responsePayload = loadResponse(action.payload, response);
    yield put(GetAnnouncementUrlSuccess(responsePayload));
  } catch (error) {
    const errorPayload = loadError(action.payload, error);
    yield put(GetAnnouncementUrlFail(errorPayload));
  }
}

function* GetProjectImages(action) {
  try {
    const response = yield call(GetProjectImagesAsync, action);
    const responsePayload = loadSpfResponse(action.payload, response);
    yield put(GetProjectImagesSuccess(responsePayload));
  } catch (error) {
    const errorPayload = loadError(action.payload, error);
    yield put(GetProjectImagesError(errorPayload));
  }
}

function* UpsertProjectImages(action) {
  try {
    const response = yield call(UpsertProjectImagesAsync, action);
    const responsePayload = loadResponse(action.payload, response);
    yield put(UpsertProjectImagesSuccess(responsePayload));
  } catch (error) {
    const errorPayload = loadError(action.payload, error);
    yield put(UpsertProjectImagesError(errorPayload));
  }
}

const UpdateFundingInfoAsync = (action) => {
  return projectServiceModule.updateProjectFundingInfo(
    action.payload.projectId,
    action.payload.payload
  );
};

const UpsertProjectDocumentsAsync = (action) => {
  let promise;
  if (!!action.payload.isUpdate) {
    promise = projectServiceModule.updateProjectDocuments(action.payload.projectId, action.payload);
  } else {
    promise = projectServiceModule.createProjectDocuments(action.payload.projectId, action.payload);
  }

  return promise;
};

const CreateProjectStaticDocumentAsync = (action) => {
  return projectServiceModule.postProjectStaticDocument(
    action.payload.projectId,
    action.payload.payload
  );
};

const GetAnnouncementUrlAsync = (action) => {
  return commonServiceModule.getAnnouncementUrl();
};

const GetProjectImagesAsync = (action) => {
  let promise;
  if (!!action.payload.isPreview) {
    promise = projectServiceModule.getProjectDraftImages(
      action.payload.projectId,
      action.payload.queryString
    );
  } else {
    promise = projectServiceModule.getProjectImages(
      action.payload.projectId,
      action.payload.queryString
    );
  }

  return promise;
};

const UpsertProjectImagesAsync = (action) => {
  let promise;

  if (!!action.payload.isUpdate) {
    promise = projectServiceModule.updateProjectImages(
      action.payload.projectId,
      action.payload.images
    );
  } else {
    promise = projectServiceModule.createProjectImages(
      action.payload.projectId,
      action.payload.images
    );
  }

  return promise;
};
